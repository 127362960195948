import React from 'react';
import { Link } from '@inertiajs/react';
import { NavLink as RouterNavLink } from 'react-router-dom';

export default function NavLink({
    active = false,
    routeName = false,
    is_static = false,
    react_router = false,
    params = false,
    className = '',
    activeClasses = '',
    hash = false,
    children,
    ...props
}) {
    if (!active && routeName)
        active = params
            ? route().current(routeName, { ...params, _query: null })
            : route().current(routeName);

    let classes = (active ? activeClasses + ' is_active ' : ' ') + className;
    let href =
        routeName && params
            ? route(routeName, params)
            : routeName
            ? route(routeName)
            : props.href;

    if (routeName && Config.proxy_excluded_routes.includes(routeName)) {
        href = href.replace(Config.sub_dir, '');
    }

    // if hash, then append it to the href
    if (hash) href += hash;

    if (react_router) {
        return (
            <RouterNavLink {...props} to={href ?? props.to} className={classes}>
                {children}
            </RouterNavLink>
        );
    }

    return is_static ? (
        <a {...props} href={href} className={classes}>
            {children}
        </a>
    ) : (
        <Link {...props} href={href} className={classes}>
            {children}
        </Link>
    );
}
