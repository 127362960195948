import React from 'react';
// import Config from 'Config';
import _ from 'lodash';
import { Info } from '@phosphor-icons/react';

/**
 *
 * @param {Array} props.items
 * @param {String} props.className // useful options: 'simple-breakdown' or '-remove-outer-padding'
 *
 * @example
 * <Breakdown
 *    items={[
 *        {
 *           label: 'Subtotal',
 *           info: 'x2 Guests',
 *           value: 100,
 *           type: 'price',
 *           always_show: true, // Force item to show when value is 0
 *        },
 *        {
 *           type: 'customer-fee', // Shows platform fee - Use 'chef-fee' for chef breakdown
 *        },
 *        {
 *           type: 'new-items', // For jQuery to add new items (usually on applying discounts/credits)
 *        },
 *        {
 *           label: 'Total',
 *           value: 100,
 *           original_value: 120, // When price is discounted
 *           type: 'price',
 *           is_total: true, // For jQuery to update total price after discounts
 *        },
 *    ]}
 * />
 *
 * @returns
 */

export default function Breakdown(props) {
    if (!is_node) {
        $(function () {
            $('[data-toggle="popover"]').popover('enable');
        });
    }

    const { items, className } = props;

    if (!items) return;

    const preservedTypes = [
        'customer-fee',
        'chef-fee',
        'new-items',
        'discount-items',
        'deposit-items',
    ];

    const formatPrice = (num) => {
        if (!num || Number.isNaN(num) || num == 'NaN') num = 0;

        if (typeof num == 'string') {
            num = num.replace(/,/g, '');
        }

        return parseFloat(num).toFixed(props.decimalPlaces ?? 2);
    };

    const renderItems = () => {
        return _.map(items, (item, idx) => {
            if (
                item.always_show ||
                (item.value != 0 && item.value != null) ||
                (item.type && preservedTypes.includes(item.type))
            ) {
                return (
                    <div
                        className={
                            item.type == 'new-items' ||
                            item.type == 'discount-items' ||
                            item.type == 'deposit-items'
                                ? item.type
                                : 'price-item'
                        }
                        key={idx}>
                        {item.type == 'customer-fee' && (
                            <>
                                <span>
                                    <a
                                        href="https://yhangry.com/ycover/"
                                        target="_blank"
                                        className="text-decoration-none">
                                        Insurance, Fees, Taxes
                                    </a>
                                    {item.popover && (
                                        <Info
                                            size={18}
                                            weight="regular"
                                            className="ml-2 vertical-align-middle"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-trigger="hover"
                                            data-html="true"
                                            data-content={item.popover}
                                        />
                                    )}
                                </span>
                                <span>{Config.platform_fee_customer}%</span>
                            </>
                        )}

                        {item.type == 'chef-fee' && (
                            <>
                                <span>
                                    Platform Fee
                                    {item.popover && (
                                        <Info
                                            size={18}
                                            weight="regular"
                                            className="ml-2 vertical-align-middle"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-trigger="hover"
                                            data-html="true"
                                            data-content={item.popover}
                                        />
                                    )}
                                </span>
                                <span>-{Config.platform_fee_chef}%</span>
                            </>
                        )}

                        {(!item.type ||
                            !preservedTypes.includes(item.type)) && (
                            <>
                                {item.label && (
                                    <span>
                                        {item.label}
                                        {item.info && (
                                            <small> {item.info}</small>
                                        )}
                                        {item.popover && (
                                            <Info
                                                size={18}
                                                weight="regular"
                                                className="ml-2 vertical-align-middle"
                                                data-toggle="popover"
                                                data-placement="top"
                                                data-trigger="hover"
                                                data-html="true"
                                                data-content={item.popover}
                                            />
                                        )}
                                    </span>
                                )}
                                {'value' in item && (
                                    <span
                                        id={
                                            item.is_total
                                                ? 'js-quote-price'
                                                : undefined
                                        }
                                        className="-value">
                                        {item.original_value &&
                                            item.original_value != item.value &&
                                            item.type == 'price' && (
                                                <s className="mr-2">
                                                    £
                                                    {formatPrice(
                                                        item.original_value
                                                    )}
                                                </s>
                                            )}
                                        {item.value_prefix ?? ''}
                                        {item.type == 'price'
                                            ? '£' + formatPrice(item.value)
                                            : item.value}
                                        {item.type == 'percentage' ? '%' : ''}
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                );
            }
        });
    };

    return (
        <div className={`breakdown-container ${className ?? ''}`}>
            {renderItems()}
        </div>
    );
}
