import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from '@/utils/hooks';
import { aws } from '@/config';

export default function ChefsAvatar({ className = '' }) {
    const { options } = useSelector((state) => state.requestWizard);
    const [random, setRandom] = useState(1);
    const prev = usePrevious(options.chefsCount);

    useEffect(() => {
        // Avoid changes while transitioning
        if (options.chefsCount === 0) return;

        // Only update avatars if chefs count has changed
        if (options.chefsCount !== prev) {
            // Random chef avatars from 1 to 3
            setRandom(Math.floor(Math.random() * 3) + 1);
        }
    }, [options.chefsCount]);

    return (
        <img
            src={`${aws.url}/images/avatar/chefs-${random}.png`}
            alt="Chefs"
            className={className}
        />
    );
}
