export const FETCH_BASKET = 'FETCH_BASKET';
export const FETCH_BASKET_SUCCESS = 'FETCH_BASKET_SUCCESS';
export const FETCH_BASKET_ERROR = 'FETCH_BASKET_ERROR';
export const UPDATE_BASKET_OPTION = 'UPDATE_BASKET_OPTION';
export const UPDATE_POSTCODE = 'UPDATE_POSTCODE';
export const UPDATE_EXTRADISHESCOST = 'UPDATE_EXTRADISHESCOST';
export const UPDATE_EVENT_DETAILS = 'UPDATE_EVENT_DETAILS';
export const UPDATE_BASKET_DISHES = 'UPDATE_BASKET_DISHES';
export const UPDATE_SERVING_TYPE = 'UPDATE_SERVING_TYPE';
export const UPDATE_TOTAL = 'UPDATE_TOTAL';
export const UPDATE_TRAVEL_FEE = 'UPDATE_TRAVEL_FEE';
export const CLEAR_BASKET = 'CLEAR_BASKET';
export const GET_SUPPLY_DEMAND_DAY_RATE = 'GET_SUPPLY_DEMAND_DAY_RATE';
export const GET_SUPPLY_DEMAND_DAY_RATE_SUCCESS =
    'GET_SUPPLY_DEMAND_DAY_RATE_SUCCESS';
export const GET_SUPPLY_DEMAND_DAY_RATE_ERROR =
    'GET_SUPPLY_DEMAND_DAY_RATE_ERROR';

// Dishes
export const FILTER_DISHES = 'FILTER_DISHES';
export const FETCH_DISH_FILTERS = 'FETCH_DISH_FILTERS';
export const FETCH_DISH_FILTERS_SUCCESS = 'FETCH_DISH_FILTERS_SUCCESS';

export const FETCH_DISHES = 'FETCH_DISHES';
export const FETCH_DISHES_ERROR = 'FETCH_DISHES_ERROR';
export const FETCH_DISHES_SUCCESS = 'FETCH_DISHES_SUCCESS';

export const CREATING_DISH = 'CREATING_DISH';
export const CREATING_DISH_ERROR = 'CREATING_DISH_ERROR';
export const CREATING_DISH_SUCCESS = 'CREATING_DISH_SUCCESS';

export const UPDATING_DISH = 'UPDATING_DISH';
export const UPDATE_DISH_VALUE = 'UPDATE_DISH_VALUE';
export const UPDATE_DISH_ERROR = 'UPDATE_DISH_ERROR';
export const UPDATE_DISH_SUCCESS = 'UPDATE_DISH_SUCCESS';

export const DELETE_DISH = 'DELETE_DISH';
export const DELETE_DISH_ERROR = 'DELETE_DISH_ERROR';
export const DELETE_DISH_SUCCESS = 'DELETE_DISH_SUCCESS';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_DISHES = 'CLEAR_DISHES';

// Menus Explorer
export const SEARCH_SET_MENUS = 'SEARCH_SET_MENUS';
export const SEARCH_SET_MENUS_SUCCESS = 'SEARCH_SET_MENUS_SUCCESS';
export const SEARCH_SET_MENUS_PAGINATE_SUCCESS =
    'SEARCH_SET_MENUS_PAGINATE_SUCCESS';
export const SEARCH_SET_MENUS_ERROR = 'SEARCH_SET_MENUS_ERROR';
export const FILTER_SET_MENUS = 'FILTER_SET_MENUS';
export const APPLY_MENUS_FILTERS = 'APPLY_MENUS_FILTERS';
export const ORDER_SET_MENUS = 'ORDER_SET_MENUS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const UPDATE_MENU_CARD_CLICK = 'UPDATE_MENU_CARD_CLICK';
export const FETCH_MENU_EXPLORER_FILTERS = 'FETCH_MENU_EXPLORER_FILTERS';
export const FETCH_MENU_EXPLORER_FILTERS_SUCCESS =
    'FETCH_MENU_EXPLORER_FILTERS_SUCCESS';
export const FETCH_MENU_EXPLORER_FILTERS_ERROR =
    'FETCH_MENU_EXPLORER_FILTERS_ERROR';

export const FETCH_SET_MENUS = 'FETCH_SET_MENUS';
export const FETCH_SET_MENUS_SUCCESS = 'FETCH_SET_MENUS_SUCCESS';
export const FETCH_SET_MENUS_ERROR = 'FETCH_SET_MENUS_ERROR';
export const CLEAR_SET_MENUS = 'CLEAR_SET_MENUS';
export const VIEW_SET_MENU = 'VIEW_SET_MENU';
export const STORE_SET_MENUS = 'STORE_SET_MENUS';
export const FETCH_SET_MENUS_AVAILABILITY = 'FETCH_SET_MENUS_AVAILABILITY';
export const FETCH_SET_MENUS_AVAILABILITY_ERROR =
    'FETCH_SET_MENUS_AVAILABILITY_ERROR';
export const FETCH_SET_MENUS_AVAILABILITY_SUCCESS =
    'FETCH_SET_MENUS_AVAILABILITY_SUCCESS';

// dietary requirements
export const FETCH_DIETARY_REQUIREMENTS = 'FETCH_DIETARY_REQUIREMENTS';
export const FETCH_DIETARY_REQUIREMENTS_SUCCESS =
    'FETCH_DIETARY_REQUIREMENTS_SUCCESS';
export const FETCH_DIETARY_REQUIREMENTS_ERROR =
    'FETCH_DIETARY_REQUIREMENTS_ERROR';
export const UPDATE_SELECTED_DIETARY_REQUIREMENTS =
    'UPDATE_SELECTED_DIETARY_REQUIREMENTS';
export const SET_DIETARY_REQUIREMENTS = 'SET_DIETARY_REQUIREMENTS';

// user
export const FETCH_USER = 'FETCH_USER';
export const SELECTED_ORDER = 'SELECTED_ORDER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const TOGGLE_USER_CREDITS = 'TOGGLE_USER_CREDITS';
export const UPDATE_USER_VOUCHER = 'UPDATE_USER_VOUCHER';
export const UPDATE_SELECTED_FILTER = 'UPDATE_SELECTED_FILTER';

export const FETCH_AVAILABILITY = 'FETCH_AVAILABILITY';
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH_AVAILABILITY_SUCCESS';
export const FETCH_AVAILABILITY_ERROR = 'FETCH_AVAILABILITY_ERROR';
export const UPDATE_AVILABILITY = 'UPDATE_AVILABILITY';
export const SYNC_AVILABILITY = 'SYNC_AVILABILITY';

export const FETCH_ANALYTICS = 'FETCH_ANALYTICS';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_ERROR = 'FETCH_ANALYTICS_ERROR';

export const BESPOKE_QUOTE_NEXT_STEP = 'BESPOKE_QUOTE_NEXT_STEP';
export const BESPOKE_QUOTE_PREV_STEP = 'BESPOKE_QUOTE_PREV_STEP';
export const BESPOKE_QUOTE_TO_STEP = 'BESPOKE_QUOTE_TO_STEP';
export const BESPOKE_QUOTE_UPDATE_VALUE = 'BESPOKE_QUOTE_UPDATE_VALUE';
export const BESPOKE_QUOTE_UPDATE_ATTRIBUTE = 'BESPOKE_QUOTE_UPDATE_ATTRIBUTE';
export const BESPOKE_QUOTE_SUBMIT = 'BESPOKE_QUOTE_SUBMIT';
export const BESPOKE_QUOTE_SUBMIT_SUCCESS = 'BESPOKE_QUOTE_SUBMIT_SUCCESS';
export const BESPOKE_QUOTE_SUBMIT_ERROR = 'BESPOKE_QUOTE_SUBMIT_ERROR';
export const BESPOKE_QUOTE_IS_EMBED = 'BESPOKE_QUOTE_IS_EMBED';
export const FETCH_CUISINES_SUCCESS = 'FETCH_CUISINES_SUCCESS';
export const BESPOKE_QUOTE_FETCH_CHEF_COUNT = 'BESPOKE_QUOTE_FETCH_CHEF_COUNT';
export const BESPOKE_QUOTE_FETCH_CHEF_COUNT_ERROR =
    'BESPOKE_QUOTE_FETCH_CHEF_COUNT_ERROR';
export const BESPOKE_QUOTE_FETCH_CHEF_COUNT_SUCCESS =
    'BESPOKE_QUOTE_FETCH_CHEF_COUNT_SUCCESS';
export const BESPOKE_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS =
    'BESPOKE_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS';
export const BESPOKE_QUOTE_SELECT_RECOMMENDED_CHEF =
    'BESPOKE_QUOTE_SELECT_RECOMMENDED_CHEF';
export const GET_EXISTING_QUOTE = 'GET_EXISTING_QUOTE';
export const GET_EXISTING_QUOTE_ERROR = 'GET_EXISTING_QUOTE_ERROR';
export const GET_EXISTING_QUOTE_SUCCESS = 'GET_EXISTING_QUOTE_SUCCESS';
export const UPDATE_EXISTING_QUOTE = 'UPDATE_EXISTING_QUOTE';
export const UPDATE_EXISTING_QUOTE_SUCCESS = 'UPDATE_EXISTING_QUOTE_SUCCESS';
export const UPDATE_EXISTING_QUOTE_ERROR = 'UPDATE_EXISTING_QUOTE_ERROR';

export const POSTCODES_FETCH_AUTOCOMPLETE = 'POSTCODES_FETCH_AUTOCOMPLETE';
export const POSTCODES_FETCH_AUTOCOMPLETE_SUCCESS =
    'POSTCODES_FETCH_AUTOCOMPLETE_SUCCESS';
export const POSTCODES_FETCH_AUTOCOMPLETE_ERROR =
    'POSTCODES_FETCH_AUTOCOMPLETE_ERROR';

export const FETCH_MENU_MULTIPLIER = 'FETCH_MENU_MULTIPLIER';
export const FETCH_MENU_MULTIPLIER_ERROR = 'FETCH_MENU_MULTIPLIER_ERROR';
export const FETCH_MENU_MULTIPLIER_SUCCESS = 'FETCH_MENU_MULTIPLIER_SUCCESS';
export const UPDATE_MENU_MULTIPLIER = 'UPDATE_MENU_MULTIPLIER';

export const FETCH_CHEF_SURGE_PRICING = 'FETCH_CHEF_SURGE_PRICING';
export const FETCH_CHEF_SURGE_PRICING_ERROR = 'FETCH_CHEF_SURGE_PRICING_ERROR';
export const FETCH_CHEF_SURGE_PRICING_SUCCESS =
    'FETCH_CHEF_SURGE_PRICING_SUCCESS';
export const UPDATE_CHEF_SURGE_PRICING = 'UPDATE_CHEF_SURGE_PRICING';
export const ADD_CHEF_SURGE_PRICING = 'ADD_CHEF_SURGE_PRICING';
export const REMOVE_CHEF_SURGE_PRICING = 'REMOVE_CHEF_SURGE_PRICING';

// MESSAGES
export const INIT_MESSAGES = 'INIT_MESSAGES';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';

export const FETCH_RECIPIENTS = 'FETCH_RECIPIENTS';
export const FETCH_RECIPIENTS_SUCCESS = 'FETCH_RECIPIENTS_SUCCESS';
export const FETCH_RECIPIENTS_PAGINATE_SUCCESS =
    'FETCH_RECIPIENTS_PAGINATE_SUCCESS';
export const FETCH_RECIPIENTS_ERROR = 'FETCH_RECIPIENTS_ERROR';

export const FILTER_MESSAGES = 'FILTER_MESSAGES';
export const FILTER_MESSAGES_CLEAR = 'FILTER_MESSAGES_CLEAR';
export const SET_MESSAGE_CONFIG = 'SET_MESSAGE_CONFIG';

export const UPDATE_FILES = 'UPDATE_FILES';
export const UPDATE_FILES_SUCCESS = 'UPDATE_FILES_SUCCESS';

// Chefs Explorer
export const FETCH_CHEFS = 'FETCH_CHEFS';
export const FETCH_CHEFS_SUCCESS = 'FETCH_CHEFS_SUCCESS';
export const FETCH_CHEFS_ERROR = 'FETCH_CHEFS_ERROR';
export const FETCH_CHEFS_PAGINATE_SUCCESS = 'FETCH_CHEFS_PAGINATE_SUCCESS';
export const FILTER_CHEFS = 'FILTER_CHEFS';
export const APPLY_CHEFS_FILTERS = 'APPLY_CHEFS_FILTERS';
export const FILTER_CHEFS_CLEAR = 'FILTER_CHEFS_CLEAR';
export const ORDER_CHEFS = 'ORDER_CHEFS';
export const UPDATE_CHEF_CARD_CLICK = 'UPDATE_CHEF_CARD_CLICK';
export const UPDATE_CHEF_CARD_CTA = 'UPDATE_CHEF_CARD_CTA';
export const FETCH_CHEFS_EXPLORER_FILTERS = 'FETCH_CHEFS_EXPLORER_FILTERS';
export const FETCH_CHEFS_EXPLORER_FILTERS_SUCCESS =
    'FETCH_CHEFS_EXPLORER_FILTERS_SUCCESS';
export const FETCH_CHEFS_EXPLORER_FILTERS_ERROR =
    'FETCH_CHEFS_EXPLORER_FILTERS_ERROR';

// Quote filters in chef portal
export const FETCH_QUOTES = 'FETCH_QUOTES';
export const FETCH_QUOTES_SUCCESS = 'FETCH_QUOTES_SUCCESS';
export const FETCH_QUOTES_ERROR = 'FETCH_QUOTES_ERROR';
export const FETCH_QUOTES_PAGINATE_SUCCESS = 'FETCH_QUOTES_PAGINATE_SUCCESS';
export const FILTER_QUOTES = 'FILTER_QUOTES';
export const FILTER_QUOTES_CLEAR = 'FILTER_QUOTES_CLEAR';
export const ORDER_QUOTES = 'ORDER_QUOTES';
export const SELECT_QUOTE = 'SELECT_QUOTE';
export const DEEPLINK_FILTERS = 'DEEPLINK_FILTERS';
export const UPDATE_QUOTE_COORDS = 'UPDATE_QUOTE_COORDS';

// Chef quote subimssions
export const FETCH_CHEF_QUOTE = 'FETCH_CHEF_QUOTE';
export const FETCH_CHEF_QUOTE_SUCCESS = 'FETCH_CHEF_QUOTE_SUCCESS';
export const FETCH_CHEF_QUOTE_ERROR = 'FETCH_CHEF_QUOTE_ERROR';
export const SUBMIT_CHEF_QUOTE = 'SUBMIT_CHEF_QUOTE';
export const SUBMIT_CHEF_QUOTE_SUCCESS = 'SUBMIT_CHEF_QUOTE_SUCCESS';
export const SUBMIT_CHEF_QUOTE_ERROR = 'SUBMIT_CHEF_QUOTE_ERROR';
export const SET_CHEF_PROMPT = 'SET_CHEF_PROMPT';
export const CHEF_QUOTE_ADD_DISH = 'CHEF_QUOTE_ADD_DISH';
export const CHEF_QUOTE_UPDATE_DISH = 'CHEF_QUOTE_UPDATE_DISH';
export const CHEF_QUOTE_REMOVE_DISH = 'CHEF_QUOTE_REMOVE_DISH';
export const CHEF_QUOTE_UPDATE = 'CHEF_QUOTE_UPDATE';
export const CHEF_QUOTE_RESET_COURSE = 'CHEF_QUOTE_RESET_COURSE';
export const CHEF_QUOTE_SET_COURSE_AMOUNT = 'CHEF_QUOTE_SET_COURSE_AMOUNT';
export const CHEF_QUOTE_EDIT = 'CHEF_QUOTE_EDIT';
export const UPDATE_QUOTE_GALLERY_FLAG = 'UPDATE_QUOTE_GALLERY_FLAG';
export const ADD_GALLERY_IMAGE = 'ADD_GALLERY_IMAGE';
export const ADD_GALLERY_IMAGE_ERROR = 'ADD_GALLERY_IMAGE_ERROR';
export const ADD_GALLERY_IMAGE_SUCCESS = 'ADD_GALLERY_IMAGE_SUCCESS';
export const UPDATE_CHEF_QUOTE_TEMPLATE = 'UPDATE_CHEF_QUOTE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const DELETE_TEMPLATE_ERROR = 'DELETE_TEMPLATE_ERROR';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const CHEF_QUOTE_PRSELECT_DISHES = 'CHEF_QUOTE_PRSELECT_DISHES';
export const SET_CHEF_POPULATE_FROM_MENU = 'SET_CHEF_POPULATE_FROM_MENU';
export const CHEF_QUOTE_SET_COURSE_LABEL = 'CHEF_QUOTE_SET_COURSE_LABEL';

export const FETCH_CHEF_QUOTE_TEMPLATES = 'FETCH_CHEF_QUOTE_TEMPLATES';
export const FETCH_CHEF_QUOTE_TEMPLATES_SUCCESS =
    'FETCH_CHEF_QUOTE_TEMPLATES_SUCCESS';
export const FETCH_CHEF_QUOTE_TEMPLATES_ERROR =
    'FETCH_CHEF_QUOTE_TEMPLATES_ERROR';
export const RESET_CHEF_QUOTE_TEMPLATE = 'RESET_CHEF_QUOTE_TEMPLATE';

// Chef reviews.
export const CHEF_REVIEW_SUBMIT = 'CHEF_REVIEW_SUBMIT';
export const CHEF_REVIEW_SET_DEFAULT = 'CHEF_REVIEW_SET_DEFAULT';
export const CHEF_REVIEW_SUBMIT_ERROR = 'CHEF_REVIEW_SUBMIT_ERROR';
export const CHEF_REVIEW_SUBMIT_SUCCESS = 'CHEF_REVIEW_SUBMIT_SUCCESS';
export const CHEF_REVIEW_UPDATE_ATTRIBUTE = 'CHEF_REVIEW_UPDATE_ATTRIBUTE';

// Menu builder.
export const ADD_DISH = 'ADD_DISH';
export const REMOVE_DISH = 'REMOVE_DISH';
export const ADDING_DISH = 'ADDING_DISH';
export const RESET_COURSE = 'RESET_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const DELETE_COURSE = 'DELETE_COURSE';
export const AUTO_POPULATING = 'AUTO_POPULATING';
export const UPDATING_DISHES = 'UPDATING_DISHES';
export const FETCH_CHEF_MENU = 'FETCH_CHEF_MENU';
export const SUBMITTING_MENU = 'SUBMITTING_MENU';
export const FETCH_ALL_MENUS = 'FETCH_ALL_MENUS';
export const UPDATE_DISH_NAME = 'UPDATE_DISH_NAME';
export const CLEAR_DISH_ERRORS = 'CLEAR_DISH_ERRORS';
export const REMOVE_DISH_ERROR = 'REMOVE_DISH_ERROR';
export const ADDING_DISH_ERROR = 'ADDING_DISH_ERROR';
export const UPDATE_MENU_VALUE = 'UPDATE_MENU_VALUE';
export const UPDATE_COVER_IMAGE = 'UPDATE_COVER_IMAGE';
export const UPDATE_COURSE_ERROR = 'UPDATE_COURSE_ERROR';
export const REMOVE_DISH_SUCCESS = 'REMOVE_DISH_SUCCESS';
export const DELETE_COURSE_ERROR = 'DELETE_COURSE_ERROR';
export const AUTO_POPULATING_ERROR = 'AUTO_POPULATING_ERROR';
export const FETCH_ALL_MENUS_ERROR = 'FETCH_ALL_MENUS_ERROR';
export const SUBMITTING_MENU_ERROR = 'SUBMITTING_MENU_ERROR';
export const UPDATING_DISHES_ERROR = 'UPDATING_DISHES_ERROR';
export const DELETE_COURSE_SUCCESS = 'DELETE_COURSE_SUCCESS';
export const UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS';
export const UPDATE_COURSE_SUCCESS = 'UPDATE_COURSE_SUCCESS';
export const FETCH_CHEF_MENU_ERROR = 'FETCH_CHEF_MENU_ERROR';
export const UPDATING_DISHES_SUCCESS = 'UPDATING_DISHES_SUCCESS';
export const FETCH_CHEF_MENU_SUCCESS = 'FETCH_CHEF_MENU_SUCCESS';
export const FETCH_ALL_MENUS_SUCCESS = 'FETCH_ALL_MENUS_SUCCESS';
export const SUBMITTING_MENU_SUCCESS = 'SUBMITTING_MENU_SUCCESS';
export const AUTO_POPULATING_SUCCESS = 'AUTO_POPULATING_SUCCESS';

// Jobs board.
export const SELECT_JOB = 'SELECT_JOB';
export const FETCH_JOBS = 'FETCH_JOBS';
export const FILTER_JOBS = 'FILTER_JOBS';
export const FETCH_JOBS_ERROR = 'FETCH_JOBS_ERROR';
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS';
export const DEEPLINK_FILTERS_JOBS = 'DEEPLINK_FILTERS_JOBS';
export const FETCH_JOBS_PAGINATE_SUCCESS = 'FETCH_JOBS_PAGINATE_SUCCESS';

// Direct quotes.
export const DIRECT_QUOTE_RESET = 'DIRECT_QUOTE_RESET';
export const DIRECT_QUOTE_SUBMIT = 'DIRECT_QUOTE_SUBMIT';
export const DIRECT_QUOTE_TO_STEP = 'DIRECT_QUOTE_TO_STEP';
export const DIRECT_QUOTE_PREV_STEP = 'DIRECT_QUOTE_PREV_STEP';
export const DIRECT_QUOTE_NEXT_STEP = 'DIRECT_QUOTE_NEXT_STEP';
export const DIRECT_QUOTE_SELECT_QUOTE = 'DIRECT_QUOTE_SELECT_QUOTE';
export const DIRECT_QUOTE_HANDLE_LOGIN = 'DIRECT_QUOTE_HANDLE_LOGIN';
export const DIRECT_QUOTE_SUBMIT_ERROR = 'DIRECT_QUOTE_SUBMIT_ERROR';
export const DIRECT_QUOTE_UPDATE_ATTRIBUTE = 'DIRECT_QUOTE_UPDATE_ATTRIBUTE';
export const DIRECT_QUOTE_FETCH_EXISTING_QUOTES =
    'DIRECT_QUOTE_FETCH_EXISTING_QUOTES';
export const DIRECT_QUOTE_UPDATE_EXISTING_QUOTE =
    'DIRECT_QUOTE_UPDATE_EXISTING_QUOTE';
export const DIRECT_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS =
    'DIRECT_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS';
export const DIRECT_QUOTE_UPDATE_EXISTING_QUOTE_ERROR =
    'DIRECT_QUOTE_UPDATE_EXISTING_QUOTE_ERROR';

export const FETCH_CHEF_INTRO = 'FETCH_CHEF_INTRO';
export const FETCH_CHEF_INTRO_SUCCESS = 'FETCH_CHEF_INTRO_SUCCESS';
export const FETCH_CHEF_INTRO_ERROR = 'FETCH_CHEF_INTRO_ERROR';
