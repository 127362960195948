import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateRequest,
    getChefsCount,
    updateMeta,
} from '@/actions/wizard/RequestWizardActions';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { toast } from '@/utils/Toast';
import useValidation from '@/hooks/wizard/useValidation';
import RequestWizardLoading from '@/views/Wizards/RequestWizard/States/RequestWizardLoading';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import Pills from '@/elements/pills/Pills';
import Input from '@/elements/Input';

export default function CuisineStep() {
    const maxCuisines = 3;
    const dispatch = useDispatch();
    const { request, options, status, meta } = useSelector(
        (state) => state.requestWizard
    );
    const { completeStep, stepError } = useValidation();
    const [search, setSearch] = useState('');
    const [filteredCuisines, setFilteredCuisines] = useState(options.cuisines);

    if (status.loading) {
        return <RequestWizardLoading />;
    }

    useEffect(() => {
        if (options.cuisines.length !== 0 && !status.loading) return;
        stepError('There was an error loading cuisines. Please try again.');
    }, [options.cuisines]);

    // useEffect(() => {
    //     if (!request.cuisines.length || options.chefsCount == 0) return;
    //     dispatch(updateMeta('activePrompt', 'chefs-count'));
    // }, [meta.step]);

    useEffect(() => {
        if (!search) {
            setFilteredCuisines(options.cuisines);
            return;
        }

        setFilteredCuisines(
            options.cuisines.filter((cuisine) =>
                cuisine.name.toLowerCase().includes(search.toLowerCase())
            )
        );
    }, [search, options.cuisines]);

    const select = (cuisine) => {
        const current = request.cuisines ?? [];

        cuisine = options.cuisines.find((c) => c.name === cuisine);

        if (current.length >= maxCuisines && !current.includes(cuisine.id)) {
            toast({
                title: `You can only choose up to ${maxCuisines} cuisines`,
                type: 'error',
            });
            return;
        }

        let newValue = [];

        if (current.includes(cuisine.id)) {
            newValue = current.filter((c) => c !== cuisine.id);
        } else {
            newValue = [...current, cuisine.id];
        }

        dispatch(updateRequest('cuisines', newValue));

        if (newValue.length > 0) {
            // dispatch(getChefsCount());
            completeStep();
        } else {
            // Optional step
            stepError(false);
        }
    };

    const renderSelectedCuisines = () => {
        if (!request.cuisines?.length) return;

        return (
            <div className="mb-3">
                <p className="mb-2">Selected</p>
                <Pills
                    className="btn btn-gray btn-outline rounded-pill mr-1 mb-1 --bg-brand-black border-black text-white"
                    items={options.cuisines
                        .filter((cuisine) =>
                            request.cuisines.includes(cuisine.id)
                        )
                        .map((cuisine) => cuisine.name)}
                    onClick={(cuisine) => select(cuisine)}
                />
            </div>
        );
    };

    const renderBody = () => {
        if (!options.cuisines?.length) return;

        return (
            <>
                <Input
                    // autoFocus
                    inputType="text"
                    value={search}
                    placeholder="Search cuisines..."
                    handleChange={(v) => setSearch(v)}
                    icon={<MagnifyingGlass size={20} />}
                    error={
                        filteredCuisines.length === 0 &&
                        'No cuisines matching your search'
                    }
                />

                {renderSelectedCuisines()}

                {!!filteredCuisines?.length && (
                    <Pills
                        className="btn btn-gray btn-outline rounded-pill mr-1 mb-1"
                        // active={options.cuisines
                        //     .filter((cuisine) =>
                        //         request.cuisines.includes(cuisine.id)
                        //     )
                        //     .map((cuisine) => cuisine.name)}
                        activeClass="--bg-brand-black border-black text-white"
                        items={filteredCuisines
                            .filter(
                                (cuisine) =>
                                    !request.cuisines.includes(cuisine.id)
                            )
                            .map((cuisine) => cuisine.name)}
                        onClick={(cuisine) => select(cuisine)}
                    />
                )}
            </>
        );
    };

    return (
        <Step>
            <h4 className="step-title">Which cuisines do you like?</h4>
            <p className="step-info">Choose up to 3 options</p>
            {renderBody()}
        </Step>
    );
}
