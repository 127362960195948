import React from 'react';

export default function TrustpilotStars(props) {
    return (
        <svg
            width="212"
            height="40"
            viewBox="0 0 212 40"
            fill="none"
            className="max-w-full"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g id="stars-5 1" clipPath="url(#clip0_248_22280)">
                <g id="Trustpilot_ratings_5star-RGB">
                    <g id="Group">
                        <path
                            id="Rectangle-path"
                            d="M39.6429 0H0V40H39.6429V0Z"
                            fill="#00B67A"
                        />
                        <path
                            id="Rectangle-path_2"
                            d="M82.5893 0H42.9464V40H82.5893V0Z"
                            fill="#00B67A"
                        />
                        <path
                            id="Rectangle-path_3"
                            d="M125.536 0H85.8928V40H125.536V0Z"
                            fill="#00B67A"
                        />
                        <path
                            id="Rectangle-path_4"
                            d="M168.482 0H128.839V40H168.482V0Z"
                            fill="#00B67A"
                        />
                        <path
                            id="Rectangle-path_5"
                            d="M211.429 0H171.786V40H211.429V0Z"
                            fill="#00B67A"
                        />
                        <path
                            id="Shape"
                            d="M19.8214 26.9583L25.8504 25.4167L28.3694 33.25L19.8214 26.9583ZM33.6964 16.8333H23.0837L19.8214 6.75L16.5591 16.8333H5.94641L14.5357 23.0833L11.2734 33.1667L19.8627 26.9167L25.1484 23.0833L33.6964 16.8333Z"
                            fill="white"
                        />
                        <path
                            id="Shape_2"
                            d="M62.7678 26.9583L68.7968 25.4167L71.3158 33.25L62.7678 26.9583ZM76.6428 16.8333H66.0301L62.7678 6.75L59.5055 16.8333H48.8928L57.4821 23.0833L54.2198 33.1667L62.8091 26.9167L68.0948 23.0833L76.6428 16.8333Z"
                            fill="white"
                        />
                        <path
                            id="Shape_3"
                            d="M105.714 26.9583L111.743 25.4167L114.262 33.25L105.714 26.9583ZM119.589 16.8333H108.977L105.714 6.75L102.452 16.8333H91.8392L100.429 23.0833L97.1662 33.1667L105.756 26.9167L111.041 23.0833L119.589 16.8333Z"
                            fill="white"
                        />
                        <path
                            id="Shape_4"
                            d="M148.661 26.9583L154.69 25.4167L157.209 33.25L148.661 26.9583ZM162.536 16.8333H151.923L148.661 6.75L145.398 16.8333H134.786L143.375 23.0833L140.113 33.1667L148.702 26.9167L153.988 23.0833L162.536 16.8333Z"
                            fill="white"
                        />
                        <path
                            id="Shape_5"
                            d="M191.607 26.9583L197.636 25.4167L200.155 33.25L191.607 26.9583ZM205.482 16.8333H194.869L191.607 6.75L188.345 16.8333H177.732L186.321 23.0833L183.059 33.1667L191.648 26.9167L196.934 23.0833L205.482 16.8333Z"
                            fill="white"
                        />
                    </g>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_248_22280">
                    <rect width="211.429" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
