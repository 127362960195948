import moment from 'moment';

import {
    FETCH_USER,
    SELECTED_ORDER,
    FETCH_USER_SUCCESS,
    FETCH_USER_ERROR,
    TOGGLE_USER_CREDITS,
    UPDATE_USER_VOUCHER,
    UPDATE_SELECTED_FILTER,
} from '../constants/ActionTypes';

const initialState = {
    error: false,
    loading: false,
    hasLoaded: false,
    id: '',
    first_name: '',
    last_name: '',
    hasPhone: true,
    phone: '',
    email: '',
    hasBirthday: true,
    birthday: '',
    hasAcquisitionMethod: true,
    howDidYouFindOutAboutUs: '',
    total_credit: '',
    use_credits: false,
    favouritesList: false,
    returning_customer: false,
    voucher: null,
    voucherType: null,
    voucherCode: null,
    discountType: false,
    discountValue: false,
    voucherResult: false,
    voucherEntry: '',
    order_id: false,
    selected_filter: 'invite',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_USER:
            return {
                ...initialState,
                loading: true,
            };
        case FETCH_USER_SUCCESS:
            let birthday = action.payload.data.userable?.birthday;
            const minDate = moment().subtract('10', 'years');

            // check if users birthday is in the last 12 years, too young.
            if (birthday && moment(birthday) > minDate) {
                birthday = '';
            }

            return {
                ...state,
                error: false,
                loading: false,
                hasLoaded: true,
                id: action.payload.data.id,
                first_name: action.payload.data.first_name,
                last_name: action.payload.data.last_name,
                email: action.payload.data.email,
                hasPhone:
                    action.payload.data.userable?.phone != '' &&
                    action.payload.data.userable?.phone != null,
                phone: action.payload.data.userable?.phone,
                hasBirthday: birthday != '' && birthday != null,
                birthday: birthday,
                hasAcquisitionMethod:
                    action.payload.data.userable
                        ?.how_did_you_find_out_about_us != '' &&
                    action.payload.data.userable
                        ?.how_did_you_find_out_about_us != null,
                howDidYouFindOutAboutUs:
                    action.payload.data.userable?.how_did_you_find_out_about_us,
                total_credit: action.payload.data.userable?.total_credits,
                favouritesList: action.payload.data.favouritesList,
                is_chef:
                    action.payload.data.userable_type == 'App\\Chef'
                        ? true
                        : false,
                returning_customer:
                    action.payload.data.userable?.returning_customer,
            };
        case FETCH_USER_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                hasLoaded: true,
            };
        case TOGGLE_USER_CREDITS:
            return {
                ...state,
                use_credits: !state.use_credits,
            };

        case UPDATE_USER_VOUCHER:
            return {
                ...state,
                voucher: action.payload.voucher,
                voucherType: action.payload.voucherType,
                voucherCode: action.payload.voucherCode,
                discountType: action.payload.discountType,
                discountValue: action.payload.discountValue,
                voucherResult: action.payload.voucherResult,
                voucherEntry: action.payload.voucherEntry,
            };
        case SELECTED_ORDER:
            return {
                ...state,
                order_id: action.payload,
            };
        case UPDATE_SELECTED_FILTER:
            return {
                ...state,
                selected_filter: action.payload,
            };
        default:
            return state;
    }
}
