import React, { useState, useEffect } from 'react';
import { Calendar, User, MapPin } from '@phosphor-icons/react';
import useExistingRequest from '@/hooks/wizard/useExistingRequest';
import Prompt from '@/views/Wizards/RequestWizard/Wrappers/Prompt';
import NavLink from '@/elements/NavLink';
import moment from 'moment';

export default function ExistingRequestPrompt() {
    const { matchingRequests } = useExistingRequest();
    const [request, setRequest] = useState({});

    useEffect(() => {
        if (!matchingRequests?.length) return;
        setRequest(matchingRequests?.[0] ?? {});
    }, [matchingRequests]);

    if (!request.id) return <></>;

    const requestItems = [
        {
            icon: Calendar,
            text: moment(request.meal_date).format('MMM D, YYYY'),
        },
        {
            icon: User,
            text: request.guests + ' guests',
        },
        {
            icon: MapPin,
            text: request.postcode,
        },
    ];

    return (
        <Prompt>
            <div>
                <h5 className="text-base mb-0">
                    You already have a request for this date!
                </h5>
                <p className="m-0">
                    If you want to raise a new request for the same date,
                    continue to next step
                </p>
                <div className="rounded-lg bg-white border-1 -border-grey-200 !py-2 px-1 mt-2">
                    <ul className="flex m-0 text-sm -text-color-grey-700">
                        {requestItems.map((item, i) => (
                            <li key={i} className="flex items-center mx-2">
                                <item.icon size={16} />
                                <span className="ml-2">{item.text}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <NavLink
                routeName="customer-quote-view"
                params={{ quote: request.id }}
                target="_blank"
                className="btn btn-dark btn-outline bg-black text-white w-full md:w-auto mt-2 md:!mt-0">
                View and edit
            </NavLink>
        </Prompt>
    );
}
