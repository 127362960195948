import Segment from '@/utils/Segment';
import Cookies from 'js-cookie';
import GoogleAnalytics from '../GoogleAnalytics';

class AuthTracking {
    isInitialised = () => {
        return sessionStorage.getItem('yh-auth-tracking') ? true : false;
    };

    get = () => {
        if (!this.isInitialised()) return {};

        let data = sessionStorage.getItem('yh-auth-tracking');
        return JSON.parse(data);
    };

    set = (data) =>
        sessionStorage.setItem('yh-auth-tracking', JSON.stringify(data));

    forget = () => {
        sessionStorage.removeItem('yh-auth-tracking');
        Cookies.remove('yh_auth_login');
        Cookies.remove('yh_auth_registered');
    };

    // Initial data
    getBaseData = () => {
        return {
            source_url: window.location.href,
            source_routeName: window.isInertia ? route().current() : null,
        };
    };

    // Use this to add data to the tracking object
    addData = (data) => {
        let existingData = this.get();

        // If not initialised, set base data
        if (!existingData) {
            existingData = this.getBaseData();
        }

        let newData = {
            ...existingData,
            ...data,
        };

        this.set(newData);
    };

    // Make sure base data is always up to date without resetting the whole object
    // Fire this before auth events and any redirects
    softRefresh = () => {
        let baseData = this.getBaseData();
        this.addData(baseData);
    };

    // Fire this after auth events
    track = async (event = false, appendData = {}) => {
        let data = this.get();
        if (!data || !event) return;

        data = {
            ...data,
            event: event,
            redirect_url: window.location.href,
            redirect_routeName: window.isInertia ? route().current() : null,
            ...appendData,
        };

        await Segment.track('auth', data);

        if (event === 'registered') await GoogleAnalytics.signup(data);

        // Reset after tracking
        this.forget();
    };
}

export default new AuthTracking();
