import React from 'react';
import { createReducer } from '@reduxjs/toolkit';

import {
    Cake,
    Tree,
    UsersThree,
    BeerStein,
    BowlFood,
    Confetti,
    Coffee,
} from '@phosphor-icons/react';

import {
    DIRECT_QUOTE_SUBMIT,
    DIRECT_QUOTE_TO_STEP,
    DIRECT_QUOTE_PREV_STEP,
    DIRECT_QUOTE_NEXT_STEP,
    DIRECT_QUOTE_SELECT_QUOTE,
    DIRECT_QUOTE_HANDLE_LOGIN,
    DIRECT_QUOTE_SUBMIT_ERROR,
    DIRECT_QUOTE_UPDATE_ATTRIBUTE,
    DIRECT_QUOTE_FETCH_EXISTING_QUOTES,
    DIRECT_QUOTE_UPDATE_EXISTING_QUOTE,
    DIRECT_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS,
    DIRECT_QUOTE_UPDATE_EXISTING_QUOTE_ERROR,
} from '../constants/ActionTypes';

import { customerExpectionOptions } from '../constants/options/QuoteOptions';

const initialState = {
    step: 0,
    error: false,
    hasLoaded: false,
    isLoggedIn: false,
    existingQuotes: [],
    isSubmitting: false,
    selectedQuote: false,
    chefsUnavailableDays: [],
    existingQuotesLoaded: false,
    quote: {
        kids: 0,
        budget: 0,
        guests: 10,
        budgetPp: 0,
        mealDate: '',
        postcode: '',
        chefId: false,
        source: false,
        allergies: [],
        setMenuId: null,
        selectedDishes: [],
        allergiesExtra: '',
        selectedCard: false,
        foodPreferences: '',
        vibe: [],
        foodPresentation: null,
        foodQuantity: null,
        chefEngagement: null,
        chefPreparation: null,
        equipment: null,
        tableware: null,
    },
    options: {
        dietaries: [
            "Cow's milk",
            'Egg',
            'Fish',
            'Peanut',
            'Plant based only',
            'Shellfish',
            'Soy',
            'Tree nuts',
            'Wheat',
            'Other',
            'None',
        ],
        typeIconMapping: {
            BBQ: 'yh-new-icon-Fire',
            Other: 'yh-new-icon-Cutlery',
            Christmas: 'yh-new-icon-Gift',
            'Date Night': 'yh-new-icon-Heart',
            Anniversary: 'yh-new-icon-Heart',
            'Hen Do': 'yh-new-icon-Glass---Wine',
            'Stag Do': 'yh-new-icon-Glass---Wine',
            'Dinner Party': 'yh-new-icon-Cutlery',
            'Casual Dinner': 'yh-new-icon-Cutlery',
            'Engagement Dinner': 'yh-new-icon-Heart',
            // Add more mappings here as needed
        },
        typeIconComponentMapping: {
            Birthday: <Cake size={20} />,
            'Stag Do': <BeerStein size={20} />,
            'Garden Party': <Tree size={20} />,
            'Finger Food': <BowlFood size={20} />,
            'Themed Party': <Confetti size={20} />,
            'Afternoon Tea': <Coffee size={20} />,
            'Family Dinner': <UsersThree size={20} />,
            'Friends Catchup': <UsersThree size={20} />,
            'Buffet/ Finger Food': <BowlFood size={20} />,
            // Add more mappings here as needed
        },

        ...customerExpectionOptions,
    },
};

export const directQuote = createReducer(initialState, (builder) => {
    builder
        .addCase('DIRECT_QUOTE_HANDLE_LOGIN', (state, action) => {
            state.isLoggedIn = true;
        })
        .addCase('DIRECT_QUOTE_RESET', (state, action) => {
            state.step = 0;
            state.error = false;
            state.isSubmitting = false;
            state.selectedQuote = false;
            state.quote = initialState.quote;
            state.existingQuotesLoaded = false;
        })
        .addCase('DIRECT_QUOTE_UPDATE_ATTRIBUTE', (state, action) => {
            if (action.payload.array) {
                console.log();
                if (
                    state.quote[action.payload.attribute].includes(
                        action.payload.value
                    )
                ) {
                    state.quote[action.payload.attribute] = state.quote[
                        action.payload.attribute
                    ].filter((item) => item !== action.payload.value);
                } else {
                    state.quote[action.payload.attribute].push(
                        action.payload.value
                    );
                }
            } else {
                state.quote[action.payload.attribute] = action.payload.value;
            }
        })
        .addCase('DIRECT_QUOTE_PREV_STEP', (state, action) => {
            state.error = false;

            if (action.payload.stepsToSkip) {
                state.step = state.step - action.payload.stepsToSkip;
            } else {
                state.step = state.step - 1 > 0 ? state.step - 1 : 0;
            }
        })
        .addCase('DIRECT_QUOTE_NEXT_STEP', (state, action) => {
            state.error = false;

            if (action.payload.stepsToSkip) {
                state.step = state.step + action.payload.stepsToSkip;
            } else {
                state.step++;
            }
        })
        .addCase('DIRECT_QUOTE_TO_STEP', (state, action) => {
            state.step = action.payload.step;
        })
        .addCase(
            'DIRECT_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS',
            (state, action) => {
                state.chefsUnavailableDays = action.payload.unavailable_days;
            }
        )
        .addCase('DIRECT_QUOTE_FETCH_EXISTING_QUOTES', (state, action) => {
            state.existingQuotesLoaded = true;
            state.existingQuotes = action.payload.quote
                ? action.payload.quote
                : [];
        })
        .addCase('DIRECT_QUOTE_SELECT_QUOTE', (state, action) => {
            state.selectedQuote = action.payload.quote
                ? action.payload.quote
                : false;
        })
        .addCase('DIRECT_QUOTE_UPDATE_EXISTING_QUOTE', (state, action) => {
            state.error = false;
            state.isSubmitting = true;
        })
        .addCase(
            'DIRECT_QUOTE_UPDATE_EXISTING_QUOTE_ERROR',
            (state, action) => {
                state.isSubmitting = false;
                state.error = action.payload;
            }
        )
        .addCase('DIRECT_QUOTE_SUBMIT', (state, action) => {
            state.error = false;
            state.isSubmitting = true;
        })
        .addCase('DIRECT_QUOTE_SUBMIT_ERROR', (state, action) => {
            state.isSubmitting = false;
            state.error = action.payload;
        });
});
