import axios from 'axios';
import _ from 'lodash';

import {
    BESPOKE_QUOTE_NEXT_STEP,
    BESPOKE_QUOTE_PREV_STEP,
    BESPOKE_QUOTE_TO_STEP,
    BESPOKE_QUOTE_UPDATE_VALUE,
    BESPOKE_QUOTE_UPDATE_ATTRIBUTE,
    POSTCODES_FETCH_AUTOCOMPLETE,
    POSTCODES_FETCH_AUTOCOMPLETE_SUCCESS,
    POSTCODES_FETCH_AUTOCOMPLETE_ERROR,
    BESPOKE_QUOTE_SUBMIT,
    BESPOKE_QUOTE_SUBMIT_SUCCESS,
    BESPOKE_QUOTE_SUBMIT_ERROR,
    BESPOKE_QUOTE_IS_EMBED,
    FETCH_CUISINES_SUCCESS,
    BESPOKE_QUOTE_FETCH_CHEF_COUNT,
    BESPOKE_QUOTE_FETCH_CHEF_COUNT_ERROR,
    BESPOKE_QUOTE_FETCH_CHEF_COUNT_SUCCESS,
    BESPOKE_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS,
    BESPOKE_QUOTE_SELECT_RECOMMENDED_CHEF,
    GET_EXISTING_QUOTE,
    GET_EXISTING_QUOTE_ERROR,
    GET_EXISTING_QUOTE_SUCCESS,
    UPDATE_EXISTING_QUOTE,
    UPDATE_EXISTING_QUOTE_SUCCESS,
    UPDATE_EXISTING_QUOTE_ERROR,
} from '../constants/ActionTypes';
import Segment from '../utils/Segment';

// FETCH DATA
export const fetchCuisines = () => (dispatch, getState) => {
    const url = `/cuisines`;

    const request = axios.get(url);

    return request
        .then((response) => {
            console.log(response.data);
            dispatch(fetchCuisinesSuccess(response));
        })
        .catch((err) => {
            console.error(err.response);
        });
};

export const fetchCuisinesSuccess = (response) => {
    return {
        type: FETCH_CUISINES_SUCCESS,
        payload: response.data,
    };
};

// STEP ACTIIONS

/* Next step */
export const nextStep = (stepsToSkip = false) => {
    return {
        type: BESPOKE_QUOTE_NEXT_STEP,
        payload: { stepsToSkip },
    };
};

/* Prev Step */
export const prevStep = (stepsToSkip = false) => {
    return {
        type: BESPOKE_QUOTE_PREV_STEP,
        payload: { stepsToSkip },
    };
};

/* To Step */
export const toStep = (step) => {
    return {
        type: BESPOKE_QUOTE_TO_STEP,
        payload: { step },
    };
};

export const isEmbed = () => {
    return {
        type: BESPOKE_QUOTE_IS_EMBED,
    };
};

// STATE UPDATEs

/* Update quote array */
export const updateQuoteValue = (value) => {
    return {
        type: BESPOKE_QUOTE_UPDATE_VALUE,
        payload: {
            value,
        },
    };
};
/* Update attribute value */
export const updateAttribute = (attribute, value, array = false) => {
    return {
        type: BESPOKE_QUOTE_UPDATE_ATTRIBUTE,
        payload: {
            attribute,
            value,
            array,
        },
    };
};

// QUOTE ACTIONS
/* Submit quote */
export const submitQuote = () => (dispatch, getState) => {
    const url = `/quote/create`;

    const { bespokeQuote, dietaryRequirementOptions } = getState();
    
    // let selectedDietaries = _.map(dietaryRequirementOptions.selected, 'id');

    let data = Object.assign({}, bespokeQuote.quote);

    const request = axios.post(url, data);

    // Update reducer
    dispatch({
        type: BESPOKE_QUOTE_SUBMIT,
        payload: request,
    });

    return request
        .then((response) => {
            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

            if (response.data.redirect && !bespokeQuote.embed) {
                Segment.track('quote.submit', {
                    source: bespokeQuote.quote.source,
                    cta_text: bespokeQuote.quote.ctaText,
                    revenue: String(response.data.budgetAvg),
                    budget: bespokeQuote.quote.budget,
                    budget_pp: bespokeQuote.quote.budget / bespokeQuote.quote.guests,
                    additional_info_length: bespokeQuote.quote.foodPreferences.length,
                    wizardVersion: 1,
                });

                setTimeout(() => {
                    window.location.href = response.data.redirect;
                }, 250);
            } else if (response.data.redirect && bespokeQuote.embed) {
                Segment.track('quote.submit', {
                    source: 'wordpress',
                    cta_text: bespokeQuote.quote.ctaText,
                    revenue: String(response.data.budgetAvg),
                    budget: bespokeQuote.quote.budget,
                    budget_pp: bespokeQuote.quote.budget / bespokeQuote.quote.guests,
                    additional_info_length: bespokeQuote.quote.foodPreferences.length,
                    wizardVersion: 1,
                });
                window.parent.postMessage(
                    JSON.stringify({ redirect: response.data.redirect }),
                    'https://yhangry.com'
                );

                setTimeout(() => {
                    window.location.href = response.data.redirect;
                }, 250);
            } else {
                Segment.track('quote.submit', {
                    source: bespokeQuote.quote.source,
                    cta_text: bespokeQuote.quote.ctaText,
                    revenue: String(response.data.budgetAvg),
                    budget: bespokeQuote.quote.budget,
                    budget_pp: bespokeQuote.quote.budget / bespokeQuote.quote.guests,
                    additional_info_length: bespokeQuote.quote.foodPreferences.length,
                    wizardVersion: 1,
                });
            }

            dispatch(submitQuoteSuccess(response));
        })
        .catch((error) => {
            dispatch(submitQuoteError(error.response));
        });
};

/* Submit quote success action */
export const submitQuoteSuccess = (response) => {
    return {
        type: BESPOKE_QUOTE_SUBMIT_SUCCESS,
        payload: response.data,
    };
};

/* Submit quote error action */
export const submitQuoteError = (error) => ({
    type: BESPOKE_QUOTE_SUBMIT_ERROR,
    payload: error,
});

// POSTCODE LOOKUP

/* Update value */
export const postcodeAutocomplete = (postcode) => (dispatch) => {
    const url = `/postcode/autocomplete/${postcode}`;
    const request = axios.get(url);

    return request
        .then((response) => {
            if (response.data.success) {
                dispatch(postcodeAutocompleteSuccess(response.data));
            }
        })
        .catch((err) => {
            dispatch(postcodeAutocompleteError());
        });
};

/* Update value */
export const postcodeAutocompleteSuccess = (data) => {
    return {
        type: POSTCODES_FETCH_AUTOCOMPLETE_SUCCESS,
        payload: data,
    };
};

export const postcodeAutocompleteError = (postcodes) => {
    return {
        type: POSTCODES_FETCH_AUTOCOMPLETE_ERROR,
    };
};

/* Get chef count */
export const getChefCount = () => (dispatch, getState) => {
    const url = `/quote/chefs`;
    const { bespokeQuote } = getState();

    let params = {
        postcode: bespokeQuote?.quote?.postcode,
        mealDate: bespokeQuote?.quote?.mealDate,
        cuisines: bespokeQuote?.quote?.cuisines,
    };

    // Update reducer.
    dispatch({
        type: BESPOKE_QUOTE_FETCH_CHEF_COUNT,
    });

    const request = axios.post(url, params);

    return request
        .then((response) => {
            dispatch(getChefCountSuccess(response));
        })
        .catch((error) => {
            dispatch(getChefCountError(error.response));
        });
};

/* Submit quote chef count error action */
export const getChefCountError = (error) => ({
    type: BESPOKE_QUOTE_FETCH_CHEF_COUNT_ERROR,
    payload: error,
});

/* Submit quote chef count success action */
export const getChefCountSuccess = (response) => {
    return {
        type: BESPOKE_QUOTE_FETCH_CHEF_COUNT_SUCCESS,
        payload: response.data,
    };
};

// FETCH chefs unavailable days
export const fetchUnavailableDays = (chefId) => (dispatch) => {
    const url = `/chef-availability/get-data/${chefId}`;

    const request = axios
        .get(url)
        .then((response) => {
            dispatch({
                type: BESPOKE_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS,
                payload: response.data,
            });
        })
        .catch((error) => {
            console.log('Unable to get chefs dates');
        });
};

/* Submit quote chef count success action */
export const selectRecommendedChef = (id) => {
    return {
        type: BESPOKE_QUOTE_SELECT_RECOMMENDED_CHEF,
        payload: id,
    };
};

/* Update value */
export const getExistingQuoteSuccess = (response) => {
    return {
        type: GET_EXISTING_QUOTE_SUCCESS,
        payload: response,
    };
};

export const getExistingQuoteError = (response) => {
    return {
        type: GET_EXISTING_QUOTE_ERROR,
    };
};

/* Check if the customer has an existing quote on the given date */
export const getExistingQuote = (date) => (dispatch) => {
    let url = `/quote/existing-quote`;

    const data = {
        date: date,
    };

    const request = axios.post(url, data);

    // Update reducer.
    dispatch({
        type: GET_EXISTING_QUOTE,
        payload: request,
    });

    return request
        .then((response) => {
            dispatch(getExistingQuoteSuccess(response.data));
        })
        .catch((error) => {
            console.log(
                'There was an error while existing quote: ' + error.response
            );
            dispatch(getExistingQuoteError(error.response));
        });
};

/* Update existing quote success action */
export const updateExistingQuoteSuccess = (response) => {
    return {
        type: UPDATE_EXISTING_QUOTE_SUCCESS,
        payload: response.data,
    };
};

/* Update existing quote error action */
export const updateExistingQuoteError = (error) => ({
    type: UPDATE_EXISTING_QUOTE_ERROR,
    payload: error,
});

/* Update existing quote action */
export const updateExistingQuote = () => (dispatch, getState) => {
    const url = `/quote/update-existing-quote`;
    const { bespokeQuote } = getState();

    const data = {
        quote_id: bespokeQuote.quote.selectedExistingQuote,
        chef_id: bespokeQuote.quote.chefId,
        message: bespokeQuote.quote.message,
        setMenuId: bespokeQuote.quote?.setMenuId,
        source: bespokeQuote.quote?.source,
    };

    const request = axios.post(url, data);

    // Update reducer
    dispatch({
        type: UPDATE_EXISTING_QUOTE,
        payload: request,
    });

    return request
        .then((response) => {
            dispatch(updateExistingQuoteSuccess(response));
        })
        .catch((error) => {
            dispatch(updateExistingQuoteError(error.response));
        });
};
