const round = (number) => Math.round(number * 100) / 100;

const monitorReducerEnhancer =
    (createStore) => (reducer, initialState, enhancer) => {
        const monitoredReducer = (state, action) => {
            let start = 0;
            let end = 0;

            if (!is_node) {
                start = performance.now();
            }

            const newState = reducer(state, action);

            if (!is_node) {
                end = performance.now();
                const diff = round(end - start);

                // console.log('reducer process time:', diff);
            }

            return newState;
        };

        return createStore(monitoredReducer, initialState, enhancer);
    };

export default monitorReducerEnhancer;
