import React from 'react';
import { useSelector } from 'react-redux';
import { evaluateCondition } from '@/utils/helpers';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import BudgetOptions from '../Inputs/Budget/BudgetOptions';
import BudgetInput from '../Inputs/Budget/BudgetInput';

export default function BudgetStep() {
    const { requestWizard } = useSelector((state) => state);
    const { request, options } = requestWizard;

    const budgetTypes =
        options.budgetTypes.find((type) =>
            type.conditions?.every((condition) =>
                evaluateCondition(
                    condition.operator,
                    requestWizard[condition.dataSet][condition.key],
                    condition.value
                )
            )
        ) || options.budgetTypes.find((type) => type.default);

    const selectedOption =
        budgetTypes?.options?.find(
            (option) => option.value === request.budgetType
        ) || false;

    return (
        <Step>
            <h4 className="step-title">Pricing</h4>
            <p className="step-info">
                {budgetTypes?.description ||
                    `You'll see a range of chefs within your budget`}
            </p>
            <BudgetOptions
                budgetTypes={budgetTypes}
                selectedOption={selectedOption}
            />
            <BudgetInput
                budgetTypes={budgetTypes}
                selectedOption={selectedOption}
            />
        </Step>
    );
}
