import React, { useState, useEffect } from 'react';
import Input from '@/elements/Input';

export default function TabsSwitch({
    options = [],
    selected = false,
    onSelect = () => {},

    size = '',
    className = '',
    optionClass = '',
    indicatorClass = '',
}) {
    if (!options.length) return;

    return (
        <div
            className={`switch-tabs rounded-pill border-1 -bg-grey-100 p-1 w-full ${className}`}>
            <ul
                className={`list-inline flex w-full justify-between m-0 relative`}>
                {selected && (
                    <div
                        className={`switch-tabs__indicator absolute btn btn-${size} rounded-pill bg-black w-full pointer-events-none z-0 ${indicatorClass}`}
                        style={{
                            width: `${100 / options.length}%`,
                            left: `${
                                (100 / options.length) *
                                options.findIndex(
                                    (opt) => opt.value == selected
                                )
                            }%`,
                        }}>
                        &nbsp;
                    </div>
                )}
                {options.map((opt) => (
                    <li
                        key={opt.value}
                        className={`btn btn-${size} rounded-pill w-full z-10 ${
                            selected == opt.value ? 'active text-white' : ''
                        } ${optionClass}`}
                        onClick={() => onSelect(opt)}>
                        {opt.label}
                    </li>
                ))}
            </ul>
        </div>
    );
}
