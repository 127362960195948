import Segment from '@/utils/Segment';
import {
    SEARCH_SET_MENUS,
    SEARCH_SET_MENUS_SUCCESS,
    SEARCH_SET_MENUS_PAGINATE_SUCCESS,
    SEARCH_SET_MENUS_ERROR,
    FILTER_SET_MENUS,
    APPLY_MENUS_FILTERS,
    ORDER_SET_MENUS,
    CLEAR_FILTERS,
    CLEAR_ORDER,
    UPDATE_MENU_CARD_CLICK,
    FETCH_MENU_EXPLORER_FILTERS,
    FETCH_MENU_EXPLORER_FILTERS_SUCCESS,
    FETCH_MENU_EXPLORER_FILTERS_ERROR,
} from '@/constants/ActionTypes';

const emptyFilters = {
    cuisines: [],
    chefs: [],
    dietary: [],
    price: [],
    pricing: {
        min: 0,
        max: 0,
        ranges: [],
    },
    type: [],
    tags: [],
    vibe: [],
    order: [
        // If changed, update config/food.php
        { key: 'best-sellers', value: 'Best sellers' },
        { key: 'price-low-high', value: 'Price low to high' },
        { key: 'price-high-low', value: 'Price high to low' },
        { key: 'recently-added', value: 'Recently Added' },
    ],
    category: [],
    search: '',
    chefTypes: [],
};

const initialState = {
    error: false,
    errors: [],
    loading: false,
    hasLoaded: false,
    filtersLoading: false,
    filtersHasLoaded: false,
    setMenus: [],
    filters: emptyFilters,
    selectedFilters: {
        ...emptyFilters,
        order: false,
    },
    cardClick: false,
    pagination: {
        links: false,
        meta: false,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEARCH_SET_MENUS:
            return {
                ...state,
                loading: true,
            };
        case SEARCH_SET_MENUS_SUCCESS:
            return {
                ...state,
                setMenus:
                    action.payload.data.setMenus?.data ??
                    action.payload.data.setMenus,
                filters: action.payload.data?.filters ?? state.filters,
                error: false,
                loading: false,
                hasLoaded: true,
                pagination: {
                    ...state.pagination,
                    links: action.payload.data.setMenus?.links,
                    meta: action.payload.data.setMenus?.meta,
                },
            };
        case SEARCH_SET_MENUS_PAGINATE_SUCCESS:
            return {
                ...state,
                setMenus: [
                    ...state.setMenus,
                    ...action.payload.data.setMenus?.data,
                ],
                error: false,
                loading: false,
                hasLoaded: true,
                pagination: {
                    ...state.pagination,
                    links: action.payload.data.setMenus?.links,
                    meta: action.payload.data.setMenus?.meta,
                },
            };
        case SEARCH_SET_MENUS_ERROR:
            return {
                ...state,
                error: true,
                errors: action.payload.data.errors,
                loading: false,
                hasLoaded: true,
            };
        case FETCH_MENU_EXPLORER_FILTERS:
            return {
                ...state,
                filtersLoading: true,
            };
        case FETCH_MENU_EXPLORER_FILTERS_SUCCESS:
            return {
                ...state,
                filters: action.payload.data?.filters ?? state.filters,
                filtersLoading: false,
                filtersHasLoaded: true,
            };
        case FETCH_MENU_EXPLORER_FILTERS_ERROR:
            return {
                ...state,
                filters: state.filters,
                filtersLoading: false,
                filtersHasLoaded: true,
            };

        case FILTER_SET_MENUS:
            // check if exists
            const index = state.selectedFilters[action.payload.filter].indexOf(
                action.payload.value
            );

            let payloadVal = action.payload.value;

            // if price, round the value
            if (action.payload.filter == 'price') {
                payloadVal = [
                    Math.round(payloadVal[0]),
                    Math.round(payloadVal[1]),
                ];
            }

            // if we want to remove item,
            if (index > -1) {
                return {
                    ...state,
                    selectedFilters: {
                        ...state.selectedFilters,
                        [action.payload.filter]:
                            action.payload.filter != 'price' &&
                            action.payload.filter != 'search'
                                ? [
                                      ...state.selectedFilters[
                                          action.payload.filter
                                      ].filter((item) => item != payloadVal),
                                  ]
                                : payloadVal,
                    },
                };
            }

            if (action.payload.filter != 'search') {
                Segment.track('explorer.filter', {
                    filter: action.payload.filter,
                    value: payloadVal,
                    source: action.payload.source,
                    show: 'set-menus',
                });
            }

            // we haven't found the item, simply add to array
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [action.payload.filter]:
                        action.payload.filter != 'price' &&
                        action.payload.filter != 'search'
                            ? [
                                  ...state.selectedFilters[
                                      action.payload.filter
                                  ],
                                  payloadVal,
                              ]
                            : payloadVal,
                },
            };

        case APPLY_MENUS_FILTERS:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    ...action.payload,
                },
            };

        case CLEAR_FILTERS:
            if (action.payload) {
                return {
                    ...state,
                    selectedFilters: {
                        ...state.selectedFilters,
                        [action.payload]: emptyFilters[action.payload],
                    },
                };
            } else {
                return {
                    ...state,
                    selectedFilters: {
                        ...initialState.selectedFilters,
                    },
                };
            }

        case ORDER_SET_MENUS:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    order: action.payload,
                },
            };

        case CLEAR_ORDER:
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    order: emptyFilters.order,
                },
            };

        case UPDATE_MENU_CARD_CLICK:
            return {
                ...state,
                cardClick: action.payload,
            };

        default:
            return state;
    }
}
