import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateMeta } from '@/actions/wizard/RequestWizardActions';
import moment from 'moment';
import { debounce } from 'lodash';

export default function useExistingRequest() {
    const dispatch = useDispatch();
    const { options, request, status, meta } = useSelector(
        (state) => state.requestWizard
    );
    const [matchingRequests, setMatchingRequests] = useState([]);

    const delayPrompt = useCallback(
        debounce(() => {
            dispatch(updateMeta('activePrompt', 'existing-request'));
        }, 100),
        []
    );

    // Listen for mealDate changes and show prompt
    useEffect(() => {
        if (
            !options.existingRequests ||
            options.existingRequests.length === 0 ||
            status.pending ||
            !request.mealDate.length ||
            meta.stepName !== 'date'
        )
            return;

        const matchedRequests = options.existingRequests?.filter((req) => {
            return moment(request.mealDate?.[0]).format('YYYY-MM-DD') === req.meal_date;
        });

        setMatchingRequests(matchedRequests);

        if (matchedRequests?.length === 0) return;

        delayPrompt();
    }, [request.mealDate, options.existingRequests, meta.stepName]);

    return { matchingRequests };
}
