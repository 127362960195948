import React, { Component } from 'react';
import Axios from 'axios';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { router } from '@inertiajs/react';

import SocialButtons from './SocialButtons/SocialButtons';
import CustomerEmail from './CustomerEmail/CustomerEmail';
import CustomerLogin from './CustomerLogin/CustomerLogin';
import CustomerRegister from './CustomerRegister/CustomerRegister';
import ForgottenPassword from './ForgottenPassword/ForgottenPassword';
// import Config from 'Config';
import PhoneAuth from './Phone/PhoneAuth';
import { XCircle } from '@phosphor-icons/react';
import PhoneOTP from './Phone/PhoneOTP';
import Modal from '@/elements/Modal';
import Segment from '@/utils/Segment';
import AuthTracking from '@/utils/Auth/AuthTracking';
import TrustpilotWidget from '@/components/Wordpress/TrustPilot/TrustpilotWidget';
import TrustpilotStars from '@/elements/logos/TrustpilotStars';

export default class Auth extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            formData: {
                email: '',
                password: '',
                firstName: '',
                lastName: '',
                howDidYouFindOutAboutUs: '',
                phone: '',
                phoneIso: '44GB',
                'newsletter-checkbox': true,
                'terms-checkbox': false,
                'g-recaptcha-response': '',
                postcode: '',
                next: this.props.next ?? window?.location.href,
                otp: '',
            },
            formPost: false,
            errors: false,
            success: false,
            authType: false,
            errorMessage: false,
            method: false,
            message: false,
            loading: false,
            prioritisePhone: this.props.usingEmail ? false : true,
            passedPhoneVerification: false,
            redirect: false,
        };

        this.cookieName = 'hideLogin';

        this.state = this.initialState;

        this.reset = this.reset.bind(this);
        this.authHandler = this.authHandler.bind(this);
    }

    componentDidMount() {
        // Refresh auth tracking object on modal open
        $('#customerLoginModal').on('show.bs.modal', (e) => {
            AuthTracking.softRefresh();
            const redirectUrl = $(e.relatedTarget).data('redirect');
            this.setState({ redirect: redirectUrl ?? false });

            // if event data attribute is set, fire segment event
            if ($(e.relatedTarget).data('event')) {
                Segment.track($(e.relatedTarget).data('event'));
            }
        });

        // Refresh auth tracking object on mount if embedded
        if (this.props.embed) {
            AuthTracking.softRefresh();
        }
    }

    openModel = () => {
        $('#customerLoginModal').modal('show');
    };

    closeModal = (isEmbedded) => {
        $('#customerLoginModal').modal('toggle');

        $('#customerLoginModal .modal-title').text(
            this.renderModalTitle(isEmbedded)
        );
    };

    shouldShowPopup() {
        // if we find the hide login cookie, exit early
        if (Cookies.get(this.cookieName)) return false;

        // if we need to hide the login because we have the paramater set,
        // then set the cookie and exit early
        if (window?.hideLogin) {
            Cookies.set(this.cookieName, true, { expires: 30 });
            return false;
        }

        if (window?.isGuest) {
            return true;
        }

        return false;
    }

    reset() {
        this.setState(this.initialState);
    }

    authCheck = async (event) => {
        event.preventDefault();

        let formData = this.state.formData;

        this.setState({ loading: true });

        if (this.props.type == 'chef') {
            Segment.identify(
                {
                    email: formData.email,
                    type: 'Chef',
                },
                formData.email
            );
            Object.assign(formData, { type: 'chef' });
        } else {
            // delete formData.phone;
            delete formData.postcode;
        }

        Axios.post('/auth/check', this.state.formData)
            .then((response) => {
                const data = response.data;
                this.setState({
                    success: true,
                    errors: false,
                    method: data.method,
                    formPost: data.formPost,
                    loading: false,
                    authType: data.type,
                });
            })
            .catch((error) => {
                const data = error.response.data;

                this.setState({
                    success: false,
                    errors: _.get(data, 'errors'),
                    loading: false,
                });
            });
    };

    phoneVerification = async (event) => {
        event.preventDefault();

        const self = this;

        // strip form data
        let formData = {
            phone: this.state.formData.phone,
            phoneIso: this.state.formData.phoneIso,
        };

        // set loading state
        this.setState({ loading: true });

        grecaptcha.ready(function () {
            grecaptcha
                .execute(import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY, {
                    action: 'submit',
                })
                .then(function (token) {
                    Object.assign(formData, { 'g-recaptcha-response': token });

                    Axios.post('/auth/verify-phone', formData)
                        .then((response) => {
                            const data = response.data;
                            self.setState({
                                success: true,
                                errors: false,
                                method: data.method,
                                formPost: data.formPost,
                                loading: false,
                                authType: data.type,
                                'g-recaptcha-response': token,
                            });
                        })
                        .catch((error) => {
                            const data = error.response.data;

                            self.setState({
                                success: false,
                                errors: _.get(data, 'errors'),
                                loading: false,
                                passedPhoneVerification: false,
                            });
                        });
                });
        });

        // check if chef type, if so, add to form data
    };

    phoneOTPSubmit = async (event) => {
        event.preventDefault();

        // strip form data
        let formData = {
            phone: this.state.formData.phone,
            phoneIso: this.state.formData.phoneIso,
            otp: this.state.formData.otp,
        };

        // set loading state
        this.setState({ loading: true });

        // check if chef type, if so, add to form data
        if (this.props.type == 'chef')
            Object.assign(formData, { type: 'chef' });

        Axios.post('/auth/verify-phone-otp', formData)
            .then((response) => {
                this.onSubmitSuccess(response);
            })
            .catch((error) => {
                const data = error.response.data;

                this.setState({
                    success: false,
                    errors: _.get(data, 'errors'),
                    loading: false,
                    passedPhoneVerification: false,
                });
            });
    };

    authHandler = async (event) => {
        event.preventDefault();
        const self = this;

        this.setState({ loading: true });

        grecaptcha.ready(function () {
            grecaptcha
                .execute(import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY, {
                    action: 'submit',
                })
                .then(function (token) {
                    self.setState(
                        {
                            formData: {
                                ...self.state.formData,
                                'g-recaptcha-response': token,
                            },
                        },
                        () => self.submitData()
                    );
                });
        });
    };

    submitData() {
        Axios.post(this.state.formPost, this.state.formData)
            .then((response) => {
                this.onSubmitSuccess(response);
            })
            .catch((error) => {
                const data = error.response.data;
                this.setState({
                    success: false,
                    errors: _.get(data, 'errors'),
                    loading: false,
                });
            });
    }

    onSubmitSuccess = (response) => {
        const data = response.data;

        if (this.state.method == 'password-reset') {
            this.setState({
                success: true,
                message: data.message,
                loading: false,
            });
        } else if (data?.method == 'account-create') {
            this.setState({
                message: data.message,
                success: true,
                errors: false,
                method: data.method,
                formPost: data.formPost,
                loading: false,
                authType: data.type,
                passedPhoneVerification: true,
            });
        } else {
            if (this.props.callback) {
                this.props.callback();
            } else if (this.state.redirect) {
                if (window?.isInertia) {
                    $('#customerLoginModal').modal('hide');
                    router.visit(this.state.redirect, { only: ['auth'] });
                } else {
                    window.location.href = this.state.redirect;
                }
            } else if (data.redirect) {
                let url = data.redirect.includes('http')
                    ? data.redirect
                    : Config.sub_dir + data.redirect;

                window.location.href = url;
            } else {
                if (this.state.authType == 'chef') {
                    window.location.href = Config.sub_dir + '/chef-jobs';
                } else {
                    location.reload();
                }
            }
        }
    };

    onChange = (event, label = false) => {
        return new Promise((resolve) => {
            this.setState((prevState) => {
                let formData = { ...prevState.formData };

                if (label) {
                    formData[label] = event;
                } else {
                    const value =
                        event.target.type === 'checkbox'
                            ? event.target.checked
                            : event.target.value;

                    formData[event.target.name] = value;
                }

                return { formData };
            }, resolve);
        });
    };

    resetPassword = () => {
        this.setState({
            formPost: '/password/email',
            method: 'password-reset',
            success: false,
            errors: this.initialState.errors,
        });
    };

    renderModalTitle(isEmbedded) {
        const { method, authType } = this.state;

        if (method == 'account-login') {
            if (authType == 'chef') {
                return 'Welcome back chef!';
            }

            return 'Welcome back!';
        } else if (method == 'password-reset') {
            return 'Reset your password';
        }

        if (this.props.title) {
            return this.props.title;
        } else if (isEmbedded) {
            return 'Log in or sign up to continue';
        } else if (this.props.type == 'chef') {
            return 'Welcome chef!';
        } else {
            return 'Log in or sign up to yhangry';
        }
    }

    renderFooter() {
        return (
            <>
                <p className="text-xs mt-3 mb-4">
                    By clicking 'continue', you confirm that you accept our{' '}
                    <a
                        href="https://yhangry.com/terms-and-conditions/"
                        target="_blank">
                        Terms and Conditions
                    </a>{' '}
                    and have read our{' '}
                    <a
                        href="https://yhangry.com/privacy-policy/"
                        target="_blank">
                        Privacy policy
                    </a>
                    .
                </p>
                <div className="flex items-center justify-center mb-1">
                    <span className="fw-bold mr-2">Excellent</span>
                    <TrustpilotStars height="24" width="100" />
                </div>
                <TrustpilotWidget height="24" theme="light" />
            </>
        );
    }

    renderModelContent() {
        return (
            <div className="sign-up-options">
                {!this.state.method && (
                    <>
                        <div className="grid grid-cols-1">
                            <div
                                className={
                                    !this.state.prioritisePhone
                                        ? 'order-3'
                                        : 'order-1'
                                }>
                                <PhoneAuth
                                    onSubmit={this.phoneVerification}
                                    onChange={this.onChange}
                                    formData={this.state.formData}
                                    errors={this.state.errors}
                                    loading={this.state.loading}
                                    open={this.state.prioritisePhone}
                                    toggleDefault={() =>
                                        this.setState({
                                            prioritisePhone:
                                                !this.state.prioritisePhone,
                                        })
                                    }
                                />
                            </div>

                            <div className="order-2">
                                <hr className="hr-slim hr-light my-4" />

                                <SocialButtons
                                    type={this.props.type}
                                    redirect={this.state.redirect}
                                    beforeSocialAuth={
                                        this.props.beforeSocialAuth
                                    }
                                />
                            </div>

                            <div
                                className={
                                    this.state.prioritisePhone
                                        ? 'order-3'
                                        : 'order-1'
                                }>
                                <CustomerEmail
                                    type={this.props.type}
                                    onSubmit={this.authCheck}
                                    onChange={this.onChange}
                                    formData={this.state.formData}
                                    errors={this.state.errors}
                                    loading={this.state.loading}
                                    open={!this.state.prioritisePhone}
                                    toggleDefault={() =>
                                        this.setState({
                                            prioritisePhone:
                                                !this.state.prioritisePhone,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}

                {this.state.method == 'account-login' && (
                    <CustomerLogin
                        onSubmit={this.authHandler}
                        onChange={this.onChange}
                        formData={this.state.formData}
                        errors={this.state.errors}
                        reset={this.reset}
                        resetPassword={this.resetPassword}
                        loading={this.state.loading}
                    />
                )}

                {this.state.method == 'account-create' && (
                    <CustomerRegister
                        onSubmit={this.authHandler}
                        onChange={this.onChange}
                        formData={this.state.formData}
                        errors={this.state.errors}
                        reset={this.reset}
                        loading={this.state.loading}
                        type={this.props.type}
                        passedPhoneVerification={
                            this.state.passedPhoneVerification
                        }
                    />
                )}

                {this.state.method == 'password-reset' && (
                    <ForgottenPassword
                        onSubmit={this.authHandler}
                        onChange={this.onChange}
                        formData={this.state.formData}
                        errors={this.state.errors}
                        reset={this.reset}
                        {...this.state}
                    />
                )}

                {this.state.method == 'phone-otp' && (
                    <PhoneOTP
                        onSubmit={this.phoneOTPSubmit}
                        onChange={this.onChange}
                        formData={this.state.formData}
                        errors={this.state.errors}
                        reset={this.reset}
                        resendCode={this.phoneVerification}
                        {...this.state}
                    />
                )}

                {this.renderFooter()}
            </div>
        );
    }

    renderView(isEmbedded) {
        return (
            <>
                {!this.props.removeTitle && (
                    <h4 className="text-center px-2 py-4 text-xl fw--bold">
                        {this.renderModalTitle(isEmbedded)}
                    </h4>
                )}

                {this.renderModelContent()}
            </>
        );
    }

    renderModal() {
        return (
            <Modal
                id="customerLoginModal"
                title={this.renderModalTitle(false)}
                // disableMobileSlideUp={true}
            >
                {this.renderModelContent()}
            </Modal>
        );
    }

    renderEmbed() {
        return <div className="sign-up-embed">{this.renderView(true)}</div>;
    }

    render() {
        return (
            <>{this.props.embed ? this.renderEmbed() : this.renderModal()}</>
        );
    }
}
