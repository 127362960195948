import React from 'react';

import { X } from '@phosphor-icons/react';

const Pill = ({
    children,
    className = false,
    active = false,
    activeClass = '',
    onClick = false,
    dissmissable = false,
}) => {
    return (
        <span
            className={`badge ${className ? className : 'yh-pill'} ${
                active ? activeClass : ''
            }`}
            onClick={onClick ? () => onClick(children) : null}>
            {children}
            {(active || dissmissable) && <X size={17} className='ml-2'/>}
        </span>
    );
};

export default Pill;
