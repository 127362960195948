import { createReducer } from '@reduxjs/toolkit'
  
const initialState = {
    error: false,
    loading: false,
    hasLoaded: false,
    multipliers: []
};

export const chefMenuMultiplierReducer = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_MENU_MULTIPLIER', (state, action) => {
            state.error = false;
            state.loading = true;
            state.hasLoaded = false;
            state.multipliers = [];
        })
        .addCase('FETCH_MENU_MULTIPLIER_ERROR', (state, action) => {
            state.error = true;
            state.loading = false;
            state.hasLoaded = true;
            state.multipliers = [];
        })
        .addCase('FETCH_MENU_MULTIPLIER_SUCCESS', (state, action) => {
            state.error = false;
            state.loading = false;
            state.hasLoaded = true;
            state.multipliers = action.payload.data.menuMultipliers;
        })
        .addCase('UPDATE_MENU_MULTIPLIER', (state, action) => {
            state.error = false;
            state.loading = false;
            state.hasLoaded = true;
            state.multipliers[action.payload.key] = action.payload.value;
        })
})