import _ from 'lodash'

import {
    FETCH_DIETARY_REQUIREMENTS,
    FETCH_DIETARY_REQUIREMENTS_SUCCESS,
    FETCH_DIETARY_REQUIREMENTS_ERROR,
    UPDATE_SELECTED_DIETARY_REQUIREMENTS,
    SET_DIETARY_REQUIREMENTS
} from '../constants/ActionTypes';


const initialState = {
    error: false,
    loading: false,
    hasLoaded: false,
    options: [],
    selected: []
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_DIETARY_REQUIREMENTS:
            return {
                ...initialState,
                selected: state.selected.length > 0 ? state.selected : initialState.selected,
                loading: true,
            };
        case FETCH_DIETARY_REQUIREMENTS_SUCCESS:
            return {
                ...state,
                options: action.payload.data.dietaryRequirements,
                error: false,
                loading: false,
                hasLoaded: true,
            };
        case FETCH_DIETARY_REQUIREMENTS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                hasLoaded: true,
            };

        case UPDATE_SELECTED_DIETARY_REQUIREMENTS:
            const id = action.payload

            // copy state to new array
            let clonedOptions = [...state.options],
                clonedSelected = [...state.selected]

            // check if we're removing and grab the option
            const option = _.find(clonedOptions, { id: parseInt(id) }),
                found = (_.find(clonedSelected, { id: parseInt(id) })) ? true : false

            // remove from array
            if (found) {
                _.remove(clonedSelected, { id: parseInt(id) } )
            } else {
                // add to aray
                clonedSelected.push(option)
            }
            // update state
            return {
                ...state,
                selected: clonedSelected
            };

        case SET_DIETARY_REQUIREMENTS:
            return {
                ...state,
                selected: action.payload
            };


        default:
            return state;
    }
}
