import { createReducer } from '@reduxjs/toolkit';
import { initialState } from '@/constants/options/Wizard/RequestWizard/RequestWizardOptions';
import {
    // API
    WIZARD_SUCCESS,

    // State update
    WIZARD_UPDATE,
} from '@/constants/Actions/RequestWizardTypes';

export const requestWizard = createReducer(initialState, (builder) => {
    /**
     * MARK: API actions
     */
    builder.addCase('WIZARD_SUCCESS', (state, action) => {
        state.options = {
            ...state.options,
            [action.payload.key]: action.payload.data,
        };
        state.status.loading = false;
    });

    /**
     * MARK: State update
     */
    builder.addCase('WIZARD_UPDATE', (state, action) => {
        const { key, value, type } = action.payload;

        // Update multiple keys
        if (typeof key === 'object') {
            state[type] = {
                ...state[type],
                ...key,
            };
            return;
        }

        state[type][key] = value;
    });
});
