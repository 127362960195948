import { createAction, createReducer } from '@reduxjs/toolkit';

import moment from 'moment';
import { initial, map } from 'lodash';

import {
    FETCH_CHEF_QUOTE,
    FETCH_CHEF_QUOTE_SUCCESS,
    FETCH_CHEF_QUOTE_ERROR,
    SET_CHEF_PROMPT,
    CHEF_QUOTE_ADD_DISH,
    CHEF_QUOTE_UPDATE_DISH,
    CHEF_QUOTE_REMOVE_DISH,
    CHEF_QUOTE_UPDATE,
    CHEF_QUOTE_RESET_COURSE,
    CHEF_QUOTE_SET_COURSE_AMOUNT,
    SUBMIT_CHEF_QUOTE,
    SUBMIT_CHEF_QUOTE_SUCCESS,
    SUBMIT_CHEF_QUOTE_ERROR,
    CHEF_QUOTE_EDIT,
    UPDATE_QUOTE_GALLERY_FLAG,
    ADD_GALLERY_IMAGE,
    ADD_GALLERY_IMAGE_ERROR,
    ADD_GALLERY_IMAGE_SUCCESS,
    UPDATE_CHEF_QUOTE_TEMPLATE,
    DELETE_TEMPLATE,
    DELETE_TEMPLATE_ERROR,
    DELETE_TEMPLATE_SUCCESS,
    FETCH_CHEF_QUOTE_TEMPLATES,
    FETCH_CHEF_QUOTE_TEMPLATES_SUCCESS,
    FETCH_CHEF_QUOTE_TEMPLATES_ERROR,
    RESET_CHEF_QUOTE_TEMPLATE,
    CHEF_QUOTE_PRSELECT_DISHES,
    FETCH_CHEF_INTRO_SUCCESS,
    SET_CHEF_POPULATE_FROM_MENU,
    CHEF_QUOTE_SET_COURSE_LABEL,
} from '../constants/ActionTypes';

const defaultChefResponse = {
    input_price: 0,
    viewed_at: null,
    chef_prompt: false,
    proposal: '',
    generatedMessage: '',
    message: '',
    menu_intro: '',
    quoted_price: 0,
    proposal_sent_at: false,
    requested_call_customer: false,
    set_menus: null,
    save_menu: false,
    format: 2,
    status: false,
    reason: '',
    chef_images: [],
    edit: true,
    menu: [
        {
            key: 'canape',
            label: 'Canapes',
            amount: 'Included',
            dishes: [],
        },
        {
            key: 'starter',
            label: 'Starter',
            amount: 'Included',
            dishes: [],
        },
        {
            key: 'main dish',
            label: 'Mains',
            amount: 'Included',
            dishes: [],
        },
        {
            key: 'side dish',
            label: 'Sides',
            amount: 'Included',
            dishes: [],
        },
        {
            key: 'dessert',
            label: 'Desserts',
            amount: 'Included',
            dishes: [],
        },
        {
            key: 'add on',
            label: 'Extras',
            amount: 'Included',
            dishes: [],
        },
    ],
    travel: [],
    rating: 0,
    populate_from_menu: false,
    overall_rating: null,
    quote_feedback: '',
    quote_confidence: [],
};

const defaultChefGallery = {
    images: [],
    uploadErrors: [],
    galleryError: '',
    showGallery: false,
    galleryLoading: false,
};

const defaultChefTemplate = {
    templates: [],
    loading: false,
    templateName: '',
    showTemplate: false,
    selectedTemplate: 'new_template',
    deleteTemplateError: '',
    deleteTemplateSuccess: false,
    selectedCuisine: null,
};

const initialState = {
    errors: [],
    chef: false,
    quote: false,
    loading: false,
    hasLoaded: false,
    isBooked: false,
    chef_gallery: defaultChefGallery,
    chef_template: defaultChefTemplate,
    chef_response: defaultChefResponse,
    showMessage: false,
    cuisines: [],
};

export const chefQuote = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_CHEF_QUOTE', (state) => {
            state.loading = true;
            state.hasLoaded = false;
            state.errors = initialState.errors;
            state.isBooked = initialState.isBooked;
            state.quote = initialState.quote;
            state.chef_gallery = defaultChefGallery;
            state.chef_template = defaultChefTemplate;
            state.chef_response = defaultChefResponse;
        })
        .addCase('FETCH_CHEF_QUOTE_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = initialState.errors;
            state.quote = action.payload.quote;
            state.chef = action.payload.chef;

            // parse selected dishes
            if (action.payload.chef?.pivot?.selected_dishes) {
                state.chef.pivot.selected_dishes = JSON.parse(
                    action.payload.chef.pivot.selected_dishes
                );
            }

            state.isBooked = initialState.isBooked;
            state.cuisines = action.payload.cuisines;
            // state.edit = action.payload.quote?.chef_response?.status > 2 ? false : true
            state.chef_gallery.images = action.payload?.gallery
                ? action.payload?.gallery
                : defaultChefGallery.images;

            state.chef_template.showTemplate = defaultChefTemplate.showTemplate;
            state.chef_template.templateName = defaultChefTemplate.templateName;
            state.chef_template.selectedTemplate =
                defaultChefTemplate.selectedTemplate;
            state.chef_template.deleteTemplateError =
                defaultChefTemplate.deleteTemplateError;
            state.chef_template.deleteTemplateSuccess =
                defaultChefTemplate.deleteTemplateSuccess;
            state.chef_template.templates = action.payload?.templates
                ? action.payload?.templates
                : defaultChefTemplate.templates;

            state.chef_response.edit = action.payload.quote.edit;
            state.edit = !action.payload.quote.edit;

            // map chef response keys
            map(defaultChefResponse, (value, key) => {
                if (
                    !action.payload.quote?.chef_response[key] &&
                    key != 'proposal'
                )
                    return;

                if (
                    key == 'menu' ||
                    key == 'set_menus' ||
                    key == 'chef_images'
                ) {
                    state.chef_response[key] = JSON.parse(
                        action.payload.quote?.chef_response[key]
                    );
                } else if (key == 'format') {
                    // state.chef_response[key] = action.payload.quote.chef_response[key] < 3 ? 2 : action.payload.quote?.chef_response[key]
                    state.chef_response[key] = !action.payload.quote
                        .chef_response[key]
                        ? 2
                        : action.payload.quote?.chef_response[key];
                } else if (key == 'proposal') {
                    state.chef_response[key] =
                        action.payload.quote?.chef_response[key] != '' &&
                        action.payload.quote?.chef_response[key] != null
                            ? action.payload.quote?.chef_response[key]
                            : action.payload.previousProposal;
                } else {
                    state.chef_response[key] =
                        action.payload.quote?.chef_response[key];
                }
            });

            const now = moment(); // This creates a moment object in local time
            const viewedAt = now.format('YYYY-MM-DD HH:mm:ss');

            // Set the viewed_at state to the formatted timestamp
            state.viewed_at = viewedAt;
        })
        .addCase('FETCH_CHEF_QUOTE_ERROR', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = action.payload?.errors;
            state.isBooked = action.payload?.booked ?? false;
        })
        .addCase('SET_CHEF_PROMPT', (state, action) => {
            state.chef_response.chef_prompt = parseInt(action.payload);
        })
        .addCase('CHEF_QUOTE_ADD_DISH', (state, action) => {
            state.chef_response.menu[action.payload.course].dishes = [
                ...state.chef_response.menu[action.payload.course].dishes,
                action.payload.dish,
            ];
        })
        .addCase('CHEF_QUOTE_UPDATE_DISH', (state, action) => {
            state.chef_response.menu[action.payload.course].dishes =
                state.chef_response.menu[action.payload.course].dishes.map(
                    (dish) => {
                        if (dish.id === action.payload.dish.original) {
                            dish.name = action.payload.dish.name;
                            dish.id = action.payload.dish.id;
                        }

                        return dish;
                    }
                );
        })
        .addCase('CHEF_QUOTE_REMOVE_DISH', (state, action) => {
            state.chef_response.menu[action.payload.course].dishes =
                state.chef_response.menu[action.payload.course].dishes.filter(
                    (dish) => {
                        return dish.id !== action.payload.id;
                    }
                );
        })
        .addCase('CHEF_QUOTE_UPDATE', (state, action) => {
            state.chef_response[action.payload.key] = action.payload.value;
        })
        .addCase('CHEF_QUOTE_RESET_COURSE', (state, action) => {
            state.chef_response.menu[action.payload].dishes =
                defaultChefResponse.menu[action.payload].dishes;
        })
        .addCase('CHEF_QUOTE_SET_COURSE_AMOUNT', (state, action) => {
            state.chef_response.menu[action.payload.course].amount =
                action.payload.amount;
        })
        .addCase('CHEF_QUOTE_EDIT', (state, action) => {
            state.edit = action.payload != null ? action.payload : !state.edit;
        })
        .addCase('SUBMIT_CHEF_QUOTE', (state) => {
            state.loading = true;
            state.errors = initialState.errors;
        })
        .addCase('SUBMIT_CHEF_QUOTE_SUCCESS', (state, action) => {
            state.loading = false;
            state.errors = initialState.errors;
            state.edit = false;
            state.chef_response.status = action.payload.status;
            state.showMessage = false;

            state.chef_template.showTemplate = defaultChefTemplate.showTemplate;
            state.chef_template.templateName = defaultChefTemplate.templateName;
            state.chef_template.selectedTemplate =
                defaultChefTemplate.selectedTemplate;
            state.chef_template.templates = action.payload?.templates
                ? action.payload?.templates
                : defaultChefTemplate.templates;

            state.chef_response.proposal = action.payload.proposal;
        })
        .addCase('SUBMIT_CHEF_QUOTE_ERROR', (state, action) => {
            state.loading = false;
            state.errors = action.payload?.errors;
            state.showMessage = action.payload?.show_message ?? false;
            state.edit = true;
        })
        .addCase('UPDATE_QUOTE_GALLERY_FLAG', (state, action) => {
            state.chef_gallery.showGallery = action.payload?.toggle
                ? action.payload?.toggle
                : defaultChefGallery.showGallery;
        })
        .addCase('ADD_GALLERY_IMAGE', (state, action) => {
            state.chef_gallery.galleryLoading = true;
            state.chef_gallery.galleryError = defaultChefGallery.galleryError;
        })
        .addCase('ADD_GALLERY_IMAGE_ERROR', (state, action) => {
            state.chef_gallery.galleryLoading = false;
            state.chef_gallery.galleryError = action.payload?.message
                ? action.payload?.message
                : 'There was an error while updating your gallery, please try again';
        })
        .addCase('ADD_GALLERY_IMAGE_SUCCESS', (state, action) => {
            state.chef_gallery.galleryLoading = false;
            state.chef_gallery.galleryError = defaultChefGallery.galleryError;
            state.chef_gallery.images = action.payload?.gallery
                ? action.payload?.gallery
                : defaultChefGallery.images;
        })
        .addCase('UPDATE_CHEF_QUOTE_TEMPLATE', (state, action) => {
            if (
                action.payload.key == 'populateFromTemplate' &&
                action.payload.value != ''
            ) {
                let template = action.payload.value;

                if (template.format == 2) {
                    state.chef_response.menu = template.menu;

                    let guestCount = state.quote.guests + state.quote.kids;

                    if (template.price_per_person > 0 && guestCount > 0) {
                        let newValue = Math.ceil(
                            template.price_per_person * guestCount
                        );

                        state.chef_response.input_price =
                            template.min_spend > 0 &&
                            newValue < template.min_spend
                                ? template.min_spend
                                : newValue;
                    }
                }

                state.chef_response.format = template.format;
                state.chef_response.proposal = template.proposal;
                state.chef_template.selectedTemplate = template.id;

                state.chef_template.deleteTemplateError =
                    defaultChefTemplate.deleteTemplateError;
                state.chef_template.deleteTemplateSuccess =
                    defaultChefTemplate.deleteTemplateSuccess;
            } else if (action.payload.key == 'clearSelectedTemplate') {
                state.chef_response.menu = defaultChefResponse.menu;
                state.chef_response.format = defaultChefResponse.format;
                // state.chef_response.proposal = defaultChefResponse.proposal;
                state.chef_template.selectedTemplate = defaultChefResponse.id;
                state.chef_template.deleteTemplateError =
                    defaultChefTemplate.deleteTemplateError;
                state.chef_template.deleteTemplateSuccess =
                    defaultChefTemplate.deleteTemplateSuccess;
            } else {
                state.chef_template[action.payload.key] = action.payload.value;

                if (
                    action.payload.key == 'selectedTemplate' ||
                    action.payload.key == 'showTemplate'
                ) {
                    state.chef_template.templateName =
                        defaultChefTemplate.templateName;
                }
            }
        })
        .addCase('DELETE_TEMPLATE', (state, action) => {
            state.chef_template.loading = true;
            state.chef_template.deleteTemplateSuccess = false;
            state.chef_template.deleteTemplateError =
                defaultChefTemplate.deleteTemplateError;
        })
        .addCase('DELETE_TEMPLATE_ERROR', (state, action) => {
            state.chef_template.loading = false;
            state.chef_template.deleteTemplateSuccess = false;
            state.chef_template.deleteTemplateError = action.payload?.error
                ? action.payload?.error
                : 'There was an error while deleting a template, please try again.';
        })
        .addCase('DELETE_TEMPLATE_SUCCESS', (state, action) => {
            state.chef_template.loading = false;
            state.chef_template.deleteTemplateSuccess = true;
            state.chef_template.deleteTemplateError =
                defaultChefTemplate.deleteTemplateError;
            state.chef_template.templates = action.payload?.templates
                ? action.payload?.templates
                : defaultChefTemplate.templates;
        })
        .addCase('FETCH_CHEF_QUOTE_TEMPLATES_SUCCESS', (state, action) => {
            state.chef_template.templates = action.payload?.templates
                ? action.payload?.templates
                : defaultChefTemplate.templates;
        })
        .addCase('RESET_CHEF_QUOTE_TEMPLATE', (state) => {
            state.chef_template.templateName =
                initialState.chef_response.templateName;
            state.chef_template.selectedTemplate =
                initialState.chef_response.selectedTemplate;

            state.chef_response.menu = initialState.chef_response.menu;
            state.chef_response.proposal = initialState.chef_response.proposal;
        })
        .addCase('CHEF_QUOTE_PRSELECT_DISHES', (state, action) => {
            state.chef_response.input_price = action.payload.selectedMenuPrice
                ? action.payload.selectedMenuPrice
                : 0;
            state.chef_response.menu = action.payload.selectedMenu
                ? action.payload.selectedMenu
                : defaultChefResponse.menu;
        })
        .addCase('SET_CHEF_POPULATE_FROM_MENU', (state, action) => {
            state.chef_response.populate_from_menu = action.payload;
        })
        .addCase('CHEF_QUOTE_SET_COURSE_LABEL', (state, action) => {
            state.chef_response.menu[action.payload.course].key =
                action.payload.value;
            state.chef_response.menu[action.payload.course].label =
                action.payload.value;
        })
        .addCase('FETCH_CHEF_INTRO_SUCCESS', (state, action) => {
            state.chef_response.generatedMessage = action.payload?.message;
        });
});
