import React from 'react';

export default function TrustpilotWidget({
    theme = 'dark',
    height = '40',
    className = '',
}) {
    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    return (
        <div className={`${className}`}>
            <div
                ref={ref}
                className="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="5419b6a8b0d04a076446a9ad"
                data-businessunit-id="5fdf4b17ba66fe00015a1b40"
                data-style-height={height}
                data-style-width="100%"
                data-theme={theme}
                data-style-alignment="center">
                <a
                    href="https://uk.trustpilot.com/review/yhangry.com"
                    target="_blank"
                    rel="noopener"></a>
            </div>
        </div>
    );
}
