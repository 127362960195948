import { createAction, createReducer } from '@reduxjs/toolkit'

import {
    ADD_DISH,
    ADDING_DISH,
    REMOVE_DISH,
    RESET_COURSE,
    UPDATE_COURSE,
    UPDATE_FILTER,
    DELETE_COURSE,
    SUBMITTING_MENU,
    UPDATING_DISHES,
    FETCH_CHEF_MENU,
    FETCH_ALL_MENUS,
    AUTO_POPULATING,
    UPDATE_DISH_NAME,
    UPDATE_MENU_VALUE,
    CLEAR_DISH_ERRORS,
    ADDING_DISH_ERROR,
    UPDATE_COVER_IMAGE,
    UPDATE_COURSE_ERROR,
    DELETE_COURSE_ERROR,
    REMOVE_DISH_SUCCESS,
    UPDATING_DISHES_ERROR,
    FETCH_ALL_MENUS_ERROR,
    AUTO_POPULATING_ERROR,
    UPDATE_COURSE_SUCCESS,
    DELETE_COURSE_SUCCESS,
    UPDATE_FILTER_SUCCESS,
    FETCH_CHEF_MENU_ERROR,
    SUBMITTING_MENU_ERROR,
    FETCH_ALL_MENUS_SUCCESS,
    UPDATING_DISHES_SUCCESS,
    FETCH_CHEF_MENU_SUCCESS,
    SUBMITTING_MENU_SUCCESS,
    AUTO_POPULATING_SUCCESS
} from '../constants/ActionTypes';

const emptyFilters = {
    type: [],
    dietary: []
};

const defaultCoverImage = {
    image: [],
    uploadErrors: [],
    imageLoading: false,
}

const initialState = {
    menu: [],
    error: false,
    chefMenus: [],
    embed: false,
    loading: false,
    originalMenu: [],
    hasLoaded: false,
    addDishError: [],
    menusError: false,
    dishLoading: false,
    menusLoaded: false,
    loadingMenus: false,
    isSubmitting: false,
    dishHasLoaded: false,
    courseLoading: false,
    courseHasLoaded: true,
    filters: emptyFilters,
    autoPopulateError: [],
    updatingDishError: [],
    deleteDishError: false,
    isAutoPopulated: false,
    submittingMenuError: [],
    isAutoPopulating: false,
    deleteDishSuccess: false,
    deleteCourseError: false,
    updateCourseError: false,
    updatingDishSuccess: false,
    deleteCourseSuccess: false,
    updateCourseSuccess: false,
    submittingMenuSuccess: false,
    coverImage: defaultCoverImage,
};

export const menuBuilder = createReducer(initialState, (builder) => {
    builder
        .addCase('UPDATE_FILTER', (state, action) => {
            if (action.payload.filter == 'menu_type') {
                state.loading = true;
                state.hasLoaded = false;
                state.addDishSuccess = false;
                state.submittingMenuError = [];
                state.updatingDishSuccess = false,
                state.updateCourseSuccess = false;
                state.menu.type = action.payload.value;
                return;
            } else {
                state[action.payload.filter] = action.payload.value;
            };
        })
        .addCase('UPDATE_FILTER_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
        })
        .addCase('FETCH_CHEF_MENU', (state, action) => {
            state.loading = true;
            state.hasLoaded = false;
            state.error = initialState.error;
            state.filters = initialState.filters;
        })
        .addCase('FETCH_CHEF_MENU_ERROR', (state, action) => {
            state.error = true;
            state.loading = false;
            state.hasLoaded = true;
        })
        .addCase('FETCH_CHEF_MENU_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.isAutoPopulated = false;
            state.isAutoPopulating = false;
            state.error = initialState.error;
            state.autoPopulateError = initialState.autoPopulateError;
            state.menu = (action.payload?.menu) ? action.payload?.menu : initialState.menu;
            state.filters = (action.payload?.filters) ? action.payload?.filters : initialState.filters;
            state.originalMenu = (action.payload?.menu) ? action.payload?.menu : initialState.orginalMenu;
        })
        .addCase('UPDATE_MENU_VALUE', (state, action) => {
            state.menu[action.payload.key] = action.payload.value;
        })
        .addCase('UPDATE_COVER_IMAGE', (state, action) => {
            state.coverImage.imageLoading = true;
            state.coverImage.uploadErrors = defaultCoverImage.uploadErrors;
            state.coverImage.image = (action.payload) ? action.payload : defaultCoverImage.images;
        })
        .addCase('UPDATE_COURSE', (state, action) => {
            state.courseLoading = true;
            state.courseHasLoaded = false;
            state.updateCourseError = false;
            state.updateCourseSuccess = false;
        })
        .addCase('UPDATE_COURSE_SUCCESS', (state, action) => {
            state.courseLoading = false;
            state.courseHasLoaded = true;
            state.updateCourseError = false;
            state.updateCourseSuccess = true;

            const courseIndex = state.menu.grouping_array.findIndex(course => course.key === action.key);

            // Update the course.
            if (courseIndex > -1) {
                state.menu.grouping_array[courseIndex] = action.payload;

                // Update the dishes.
                if (action.key !== action.payload.key) {
                    const dishIndex = state.menu.dishes.findIndex(dish => dish.group === action.key);

                    if (dishIndex > -1) {
                        state.menu.dishes.map((dish, index) => {
                            if (dish.group === action.key) {
                                dish.group = action.payload.key;
                                state.menu.dishes[index] = dish;
                            }
                        });
                    } 
                }
            } else {
                state.menu.grouping_array = [...state.menu.grouping_array, action.payload];
            }
        })
        .addCase('UPDATE_COURSE_ERROR', (state, action) => {
            state.courseLoading = false;
            state.courseHasLoaded = false;
            state.updateCourseSuccess = false;
            state.updateCourseError = (action?.error) ? action?.error : 'There was an error, please try again later.';
        })
        .addCase('DELETE_COURSE', (state, action) => {
            state.courseLoading = true;
            state.courseHasLoaded = false;
            state.deleteCourseError = false;
            state.deleteCourseSuccess = false;
        })
        .addCase('DELETE_COURSE_ERROR', (state, action) => {
            state.courseLoading = false;
            state.courseHasLoaded = false;
            state.deleteCourseSuccess = false;
            state.deleteCourseError = (action?.error) ? action?.error : 'There was an issue deleting your course.';
        })
        .addCase('DELETE_COURSE_SUCCESS', (state, action) => {
            state.courseLoading = false;
            state.courseHasLoaded = true;
            state.deleteCourseError = false;
            state.deleteCourseSuccess = true;

            const courseIndex = state.menu.grouping_array.findIndex(course => course.key === action.key);

            if (courseIndex > -1) {
                state.menu.grouping_array = state.menu.grouping_array.filter((course) => {
                    return course.key !== action.key;
                });

                const dishIndex = state.menu.dishes.findIndex(dish => dish.group === action.key);

                // Remove the dishes.
                if (dishIndex > -1) {
                    state.menu.dishes = state.menu.dishes.filter((dish) => {
                        return dish.group !== action.key;
                    });
                }
            }
        })
        .addCase('RESET_COURSE', (state, action) => {
            state.courseLoading = initialState.courseLoading;
            state.courseHasLoaded = initialState.courseHasLoaded;
            state.deleteCourseError = initialState.deleteCourseError;
            state.updateCourseError = initialState.updateCourseError;
            state.deleteCourseSuccess = initialState.deleteCourseSuccess;
            state.updateCourseSuccess = initialState.updateCourseSuccess;
            state.submittingMenuError = initialState.submittingMenuError;
        })
        .addCase('DUPLICATE_DISH_ERROR', (state, action) => {
            state.dishLoading = false;
            state.deleteDishSuccess = false;

            const index = state.menu.grouping_array.findIndex(course => course.key === action.key);

            if (index > -1) {
                state.menu.grouping_array[index] = action.payload;

                return;
            } else {
                state.menu.grouping_array = [...state.menu.grouping_array, action.payload];
            }

            state.addDishError = (action.payload) ? action.payload : initialState.addDishError;
        })
        .addCase('ADDING_DISH_ERROR', (state, action) => {
            state.dishLoading = false;
            state.deleteDishSuccess = false;

            const index = state.addDishError.findIndex(error => error.course === action.course);

            if (index > -1) {
                state.addDishError[index] = {
                    'course': action.course, 
                    'errors': (action.payload.errors) ? Object.entries(action.payload?.errors) : action.payload?.error
                };

                return;
            } else {
                state.addDishError = [...state.addDishError, {
                    'course': action.course, 
                    'errors': (action.payload.errors) ? Object.entries(action.payload?.errors) : action.payload?.error
                }];
            }
        })
        .addCase('CLEAR_DISH_ERRORS', (state, action) => {
            state.dishLoading = false;
            state.addDishSuccess = false;

            const index = state.addDishError.findIndex(course => course.course === action.payload);

            if (index > -1) {
                state.addDishError = state.addDishError.filter((course) => {
                    return course.course !== action.payload;
                });
            }
        })
        .addCase('ADDING_DISH', (state, action) => {
            state.dishLoading = true;
            state.dishHasLoaded = false;
            state.addDishSuccess = false;
        })
        .addCase('ADD_DISH', (state, action) => {
            state.dishLoading = false;
            state.dishHasLoaded = true;
            state.addDishSuccess = true;

            if (action.isArray) {
                if (action.payload && action.payload.dishes.length > 0) {
                    action.payload.dishes.forEach((dish) => {
                        let newDish = dish[0];
                        newDish.group = action.course;

                        const index = state.menu.dishes.findIndex(dish => dish.id === newDish.id);
                        
                        if (index == -1) {
                            state.menu.dishes = [...state.menu.dishes, newDish];
                        } 
                    });
                }
            } else {
                const index = state.menu.dishes.findIndex(dish => dish.id === action.payload?.id);

                if (index == -1) {
                    state.menu.dishes = [...state.menu.dishes, action.payload];
                } 
            }
        })
        .addCase('REMOVE_DISH', (state, action) => {
            state.dishLoading = true;
            state.deleteDishSuccess = false;
            state.deleteDishError = initialState.deleteDishError;
        })
        .addCase('REMOVE_DISH_SUCCESS', (state, action) => {
            state.dishLoading = false;
            state.deleteDishSuccess = true;
            state.deleteDishError = initialState.deleteDishError;

            const index = state.menu.dishes.findIndex(dish => dish.id === action.payload);

            if (index > -1) {
                state.menu.dishes = state.menu.dishes.filter((dish) => {
                    return dish.id !== action.payload;
                });

                // Set the course amount to dish count if it's above.
                if (action.course) {
                    const courseIndex = state.menu.grouping_array.findIndex(course => course.key === action.course);

                    if (courseIndex > -1) {
                        const relatedDishes = state.menu.dishes.filter((dish) => {
                            return dish.group === action.course;
                        });

                        if (state.menu.grouping_array[courseIndex].amount > relatedDishes.length) {
                            state.menu.grouping_array[courseIndex].amount = relatedDishes.length;
                        }
                    }
                }
                
            } 
        })
        .addCase('REMOVE_DISH_ERROR', (state, action) => {
            state.dishLoading = false;
            state.deleteDishSuccess = false;
            state.deleteDishError = (action.payload?.error) ? action.payload?.error : 'There was an error while deleting a dish, please try again.';
        })
        .addCase('UPDATING_DISHES', (state, action) => {
            state.dishLoading = true;
            state.dishHasLoaded = false;
            state.updatingDishSuccess = false;
            state.updatingDishError = initialState.updatingDishError;
        })
        .addCase('UPDATING_DISHES_ERROR', (state, action) => {
            state.dishLoading = false;
            state.dishHasLoaded = true;
            state.updatingDishSuccess = false;
            state.updatingDishError = (action.payload.errors) ? Object.entries(action.payload?.errors) : action.payload?.error;
        })
        .addCase('UPDATING_DISHES_SUCCESS', (state, action) => {
            state.dishLoading = false;
            state.dishHasLoaded = true;
            state.updatingDishSuccess = true;
            state.updatingDishError = initialState.updatingDishError;

            const index = state.menu.dishes.findIndex(dish => dish.id === action.payload.id);

            if (index > -1) {
                action.payload['group'] = action.course;
                state.menu.dishes[index] = action.payload;
            } 
        })
        .addCase('SUBMITTING_MENU', (state, action) => {
            state.isSubmitting = true;
            state.submittingMenuSuccess = false;
            state.submittingMenuError = initialState.submittingMenuError;
        })
        .addCase('SUBMITTING_MENU_ERROR', (state, action) => {
            state.isSubmitting = false;
            state.submittingMenuSuccess = false;
            state.submittingMenuError = (action.payload?.errors) ? Object.entries(action.payload?.errors) : 'There was an error while submitting, please try again.';
        })
        .addCase('FETCH_ALL_MENUS', (state, action) => {
            state.menusError = false;
            state.menusLoaded = false;
            state.loadingMenus = true;
            state.chefMenus = initialState.chefMenus;
        })
        .addCase('FETCH_ALL_MENUS_ERROR', (state, action) => {
            state.menusError = true;
            state.menusLoaded = true;
            state.loadingMenus = false;
            state.chefMenus = initialState.chefMenus;
        })
        .addCase('FETCH_ALL_MENUS_SUCCESS', (state, action) => {
            state.menusError = false;
            state.menusLoaded = true;
            state.loadingMenus = false;
            state.chefMenus = (action.payload) ? action.payload : initialState.chefMenus;
        })
        .addCase('SUBMITTING_MENU_SUCCESS', (state, action) => {
            state.isSubmitting = false;
            state.submittingMenuSuccess = true;
            state.submittingMenuError = initialState.submittingMenuError;
            state.menu = (action.payload?.menu) ? action.payload?.menu : initialState.menu;
            state.filters = (action.payload?.filters) ? action.payload?.filters : initialState.filters;
            state.originalMenu = (action.payload?.menu) ? action.payload?.menu : initialState.orginalMenu;
        })
        .addCase('AUTO_POPULATING', (state, action) => {
            state.isAutoPopulated = false;
            state.isAutoPopulating = true;
            state.autoPopulateError = initialState.autoPopulateError;
        })
        .addCase('AUTO_POPULATING_ERROR', (state, action) => {
            state.isAutoPopulated = false;
            state.isAutoPopulating = false;
            state.autoPopulateError = (action.payload.errors) ? Object.entries(action.payload?.errors) : action.payload?.error;
        })
        .addCase('AUTO_POPULATING_SUCCESS', (state, action) => {
            state.isAutoPopulated = true;
            state.isAutoPopulating = false;
            state.autoPopulateError = initialState.autoPopulateError;
            state.menu = (action.payload?.menu) ? action.payload?.menu : initialState.menu;
        })

        .addCase('UPDATE_DISH_NAME', (state, action) => {
            const index = state.menu.dishes.findIndex(dish => dish.id === action.dishId);

            if (index > -1) {
                state.menu.dishes[index].name = action.inputValue;
            }
        })
});