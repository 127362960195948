import { combineReducers } from 'redux';

import userData from './UserReducer';
import { dishes } from './DishReducer';
import setMenus from './SetMenuReducer';
import explorer from './ExplorerReducer';
import { jobs } from './JobsBoardReducer';
import { messages } from './MessageReducer';
import { chefs } from './SearchChefsReducer';
import { quotes } from './SearchQuotesReducer';
import { chefQuote } from './ChefQuoteReducer';
import { basketReducer } from './BasketReducer';
import { chefReview } from './ChefReviewReducer';
import { menuBuilder } from './MenuBuilderReducer';
import { directQuote } from './DirectQuoteReducer';
import chefAnalytics from './ChefAnalyticsReducer';
import { onboarding } from './ChefOnboardingReducer';
import { bespokeQuote } from './BespokeQuoteReducer';
import { requestWizard } from './wizard/RequestWizardReducer';
import availabilityForm from './AvailabilityFormReducer';
import { chefSurgePricingReducer } from './ChefSurgePricingReducer';
import dietaryRequirementOptions from './DietaryRequirementsReducer';
import { chefMenuMultiplierReducer } from './ChefMenuMultiplierReducer';

const rootReducer = combineReducers({
    jobs,
    chefs,
    dishes,
    quotes,
    userData,
    setMenus,
    explorer,
    messages,
    chefQuote,
    onboarding,
    chefReview,
    menuBuilder,
    directQuote,
    bespokeQuote,
    requestWizard,
    chefAnalytics,
    availabilityForm,
    basketData: basketReducer,
    dietaryRequirementOptions,
    chefSurgePricing: chefSurgePricingReducer,
    chefMenuMultiplier: chefMenuMultiplierReducer,
});

export default rootReducer;