import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import useValidation from '@/hooks/wizard/useValidation';
import RequestWizardLoading from '@/views/Wizards/RequestWizard/States/RequestWizardLoading';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import Input from '@/elements/Input';
import _ from 'lodash';

export default function DietStep() {
    const dispatch = useDispatch();
    const { request, options, status } = useSelector(
        (state) => state.requestWizard
    );
    const { completeStep, stepError } = useValidation();

    useEffect(() => {
        if (options.dietaries.length !== 0 || status.loading) return;
        stepError(
            'There was an error loading dietary requirements. Please try again.'
        );
    }, [options.dietaries, status.loading]);

    const handleSelect = (v) => {
        let newValues = request.dietaryPreferences ?? [];

        if (newValues.includes(v)) {
            newValues = newValues.filter((value) => value !== v);
        } else {
            newValues = [...newValues, v];
        }

        dispatch(updateRequest('dietaryPreferences', newValues));

        if (newValues.length) {
            completeStep();
        } else {
            stepError('Please select at least one option.');
        }
    };

    const renderBody = () => {
        if (!options.dietaries.length) return;

        const mappedOptions = options.dietaries.map((option) => {
            return {
                label: option.name,
                value: option.id,
            };
        });

        return (
            <>
                <Input
                    cols={3}
                    inputType="checkbox"
                    value={request.dietaryPreferences}
                    handleChange={(v) => handleSelect(v)}
                    className="input__checkbox"
                    options={mappedOptions}
                />
            </>
        );
    };

    if (status.loading) {
        return <RequestWizardLoading />;
    }

    return (
        <Step>
            <h4 className="step-title">
                Does your group have dietary requirements?
            </h4>
            <p className="step-info">
                You can always confirm with your guests and inform the chef
                later.
            </p>
            {renderBody()}
        </Step>
    );
}
