import React, { useRef } from 'react';

import Input from '@/elements/Input';
import { components } from 'react-select';
import useCountryCodes from '@/hooks/auth/useCountryCodes';

const SingleValue = ({ children, data, ...props }) => (
    <components.SingleValue {...props}>
        {data.phone_code ? `+ ${data.phone_code}` : children}
    </components.SingleValue>
);

export default function PhoneNumber({
    errors,
    onChange,
    formData,
    label = false,
    labelClass = '',
}) {
    const inputRef = useRef(null);
    const { data } = useCountryCodes();
    let phoneError = errors?.phone || errors?.uniquePhoneIso;

    // if phone error, check if array, if so, join them
    if (phoneError && Array.isArray(phoneError)) {
        phoneError = phoneError.join(' ');
    }

    if (!data) return null;

    const selectedOption = data?.find(
        (item) => item.phone_iso == formData?.phoneIso
    );

    return (
        <>
            {label && (
                <label
                    className={labelClass}
                    onClick={() => inputRef?.current?.focus()}>
                    Phone
                </label>
            )}
            <div className="flex items-flex-start phone-input">
                <div className="w-24 flex-shrink-0 ">
                    <Input
                        ref={inputRef}
                        options={data}
                        name="phoneIso"
                        required={true}
                        placeholder="+44"
                        inputType="select"
                        value={selectedOption}
                        components={{ SingleValue }}
                        defaultValue={selectedOption}
                        error={phoneError ? ' ' : null}
                        getOptionValue={(option) => option.phone_iso}
                        getOptionLabel={(option) => option.input_label}
                        handleChange={(value) => {
                            onChange(value?.phone_iso, 'phoneIso');
                        }}
                    />
                </div>

                <Input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    className="grow-1 mt-0"
                    placeholder="Phone number"
                    error={phoneError ? phoneError : null}
                    handleChange={(phone) => onChange(phone, 'phone')}
                />
            </div>
        </>
    );
}
