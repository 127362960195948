import React from 'react';
import { X, ArrowLeft } from '@phosphor-icons/react';
import uniqid from 'uniqid';
import Portal from './Portal/Portal';

/**
 *
 * @param {string} id
 * @param {string} className
 * @param {string} size (lg|sm)
 * @param {string} title
 * @param {string} backText
 * @param {boolean} isOffCanvasPanel
 * @param {boolean} fullscreen
 * @param {boolean} undismissable
 * @param {boolean} disableMobileSlideUp
 * @param {boolean} scrollableBody
 * @param {boolean} stickyHeader
 * @param {boolean} usePortal
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} props.footer
 * @param {React.ReactNode} props.header
 *
 * @returns
 */
export default function Modal(props) {
    const isMobile = window?.innerWidth < 768,
        isMobileActive = isMobile && !props.disableMobileSlideUp,
        id = props.id ?? 'modal_' + uniqid();

    // Fix modal stacking when multiple modals are opened
    if (!is_node) {
        $(document).on('show.bs.modal', '.modal#' + id, function () {
            const zIndex = 1040 + 1 * $('.modal:visible').length;
            $(this).css('z-index', zIndex);
            setTimeout(() =>
                $('.modal-backdrop')
                    .not('.modal-stack')
                    .css('z-index', zIndex - 1)
                    .addClass('modal-stack')
            );
        });
    }

    // Mobile slide up modal close on scroll to top
    if (
        isMobileActive &&
        !props.undismissable &&
        !props.isOffCanvasPanel &&
        !props.fullscreen
    ) {
        let modal = $('#' + id);
        modal
            // .on('show.bs.modal', function (e) {
            //     $('html').addClass('modal-open');
            // })
            // .on('hide.bs.modal', function () {
            //     $('html').removeClass('modal-open');
            // })
            .on('scroll', function () {
                if (modal.scrollTop() == 0) {
                    modal.modal('hide');
                }
            });
    }

    if (props.usePortal) {
        $('#' + id)
            .on('show.bs.modal', function (e) {
                $('body').addClass('-show-backdrop');
            })
            .on('hide.bs.modal', function () {
                $('body').removeClass('-show-backdrop');
            });
    }

    const renderHeader = () => {
        if (props.header) {
            return props.header;
        }

        if (props.isOffCanvasPanel) {
            return (
                <div className="flex justify-between w-full sm:items-center flex-col sm:flex-row">
                    <div className="modal-header__left">
                        {!props.undismissable && (
                            <a
                                href="#close"
                                className="modal-title fs-16 fw-bold font-dm-sans -text-color-black text-decoration-none"
                                data-dismiss="modal">
                                <ArrowLeft
                                    size={20}
                                    weight="bold"
                                    className="mr-2"
                                />
                                {props.backText ?? 'Back'}
                            </a>
                        )}
                    </div>
                    {props.headerActions && (
                        <div className="modal-header__right">
                            {props.headerActions}
                        </div>
                    )}
                </div>
            );
        }

        return (
            <>
                <h5 className="modal-title fs-20 font-dm-sans -text-color-black">
                    {props.title}
                    {props.subTitle && (
                        <span className="modal-subtitle d-block mt-1">
                            {props.subTitle}
                        </span>
                    )}
                </h5>
                {props.headerActions && (
                    <div className="modal-header__right">
                        {props.headerActions}
                    </div>
                )}
                {!props.undismissable && (
                    <button
                        type="button"
                        className="modal-close-button close m-0"
                        data-dismiss="modal"
                        aria-label="Close">
                        <X size={20} weight="regular" />
                    </button>
                )}
            </>
        );
    };

    const renderModal = () => {
        return (
            <div
                ref={props?.refValue}
                className={`modal fade 
                    ${props.isOffCanvasPanel ? 'off-canvas-panel pr-0' : ''} 
                    ${props.fullscreen ? 'modal-fullscreen pr-0' : ''} 
                    ${
                        props.disableMobileSlideUp ||
                        props.isOffCanvasPanel ||
                        props.fullscreen
                            ? ''
                            : 'mobile-modal'
                    } 
                    ${props.className ?? ''}`}
                data-backdrop={props.undismissable ? 'static' : true}
                id={id}
                tabIndex="-1"
                role="dialog"
                aria-labelledby={props.title}
                aria-hidden="true">
                <div
                    className={`modal-dialog 
                    ${
                        isMobileActive ||
                        props.isOffCanvasPanel ||
                        props.fullscreen
                            ? ''
                            : 'modal-dialog-centered'
                    } 
                    ${
                        props.scrollableBody &&
                        !isMobileActive &&
                        !props.isOffCanvasPanel
                            ? 'modal-dialog-scrollable'
                            : ''
                    } 
                    modal-${props.size ?? ''}`}
                    role="document">
                    <div className="modal-content">
                        <div
                            className={`modal-header align-items-center ${
                                props.stickyHeader ? '--sticky' : ''
                            }`}>
                            {renderHeader()}
                        </div>
                        <div className={`modal-body ${props.modalBodyClass}`}>
                            {props.children}
                        </div>
                        {props.footer && (
                            <div
                                className={`modal-footer ${props.footerClass}`}>
                                {props.footer}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    // if usePortal then render modal in portal
    if (props.usePortal) {
        return <Portal wrapperId="modal-portal">{renderModal()}</Portal>;
    }

    return renderModal();
}
