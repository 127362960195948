import React, { Component, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PhoneNumber from '@/elements/inputs/PhoneNumber';
import Input from '@/elements/Input';
import {
    customerHowDidYouFindOutAboutUsOptions,
    chefHowDidYouFindOutAboutUsOptions,
} from '@/constants/ReferralOptions';
import { postcodeAutocomplete } from '@/actions/BespokeQuoteActions';
import ReduxContainer from '@/ReduxContainer';
import { aws } from '@/config';
import Segment from '@/utils/Segment';

const ChefFields = (props) => {
    const dispatch = useDispatch();
    const { options } = useSelector((state) => state.bespokeQuote);
    const delayAutocomplete = _.debounce((value) => {
        dispatch(postcodeAutocomplete(value));
    }, 500);

    let phoneError = _.get(props.errors, ['phone'][0]);
    const termsError = _.get(props.errors, ['terms-checkbox'][0]);

    if (phoneError && phoneError.length > 0) {
        phoneError = props.errors.phone[0];
    }

    if (!is_node) {
        $('.chef-login__img').attr(
            'src',
            `${aws.url}/images/auth/become-a-private-chef2.webp`
        );
    }

    return (
        <ReduxContainer>
            {!props.passedPhoneVerification && (
                <PhoneNumber
                    errors={props.errors}
                    formData={props.formData}
                    onChange={props.onChange}
                    label="Phone"
                />
            )}
            <Input
                id="postcode"
                inputType="autocomplete"
                placeholder="Enter your postcode..."
                label="Postcode"
                name="postcode"
                value={_.get(props.formData, ['postcode'])}
                autoComplete="postcode"
                options={options.postcodes}
                handleChange={(val) => {
                    props.onChange(val, 'postcode');
                    delayAutocomplete(val);
                }}
                onSelect={(val) => props.onChange(val, 'postcode')}
                error={props.errors?.postcode?.join(', ')}
                required
            />
            {/* handleChange={value => {
                    handleChange('postcode', value)
                    delayAutocomplete(value)
                }}
                options={options.postcodes}
                onSelect={value => handleChange('postcode', value)} */}
            {/* {!this.props.guestAccount && (
                <Input
                    id="howDidYouFindOutAboutUs"
                    name="howDidYouFindOutAboutUs"
                    label="How did you find out about us?"
                    value={this.props.formData.howDidYouFindOutAboutUs}
                    handleChange={(val) =>
                        this.props.onChange(
                            val?.value,
                            'howDidYouFindOutAboutUs'
                        )
                    }
                    error={this.props.errors?.howDidYouFindOutAboutUs?.join(
                        ', '
                    )}
                    required
                    options={chefHowDidYouFindOutAboutUsOptions}
                    inputType="select"
                />
            )} */}
            {/* <Input
                id="terms-checkbox"
                inputType="checkbox"
                checked={_.get(this.props.formData, 'terms-checkbox')}
                handleChange={(val) =>
                    this.props.onChange(val, 'terms-checkbox')
                }
                label={
                    <React.Fragment> */}
            By signing up, I agree with Yhangry's{' '}
            <a
                target="_blank"
                href="https://yhangry.com/terms-and-conditions/"
                title="Terms of Service"
                className="form__link">
                Terms of Service
            </a>{' '}
            and{' '}
            <a
                target="_blank"
                href="https://yhangry.com/privacy-policy/"
                title="Privacy Policy"
                className="form__link">
                Privacy Policy
            </a>
            {/* </React.Fragment>
                }
                error={termsError}
                required
            /> */}
        </ReduxContainer>
    );
};

export default class CustomerRegister extends Component {
    componentDidMount() {
        // fire tracking event when someone hits the register step
        Segment.track('auth.register-step');
    }

    renderCaptchaResponse() {
        const error = _.get(this.props.errors, 'g-recaptcha-response');

        if (!error) return false;

        return error;
    }

    _togglePassword() {
        if ($('#password').attr('type') == 'password') {
            $('#password').attr('type', 'text');
        } else {
            $('#password').attr('type', 'password');
        }
    }

    render() {
        let phoneError = _.get(this.props.errors, ['phone'][0]);

        if (phoneError && phoneError.length > 0) {
            phoneError = this.props.errors.phone[0];
        }

        let fields = {
            firstName: 'First Name',
            lastName: 'Last Name',
            password: 'Password',
        };

        // if props.passedPhoneVerification is true, then add the email field to the fields list
        if (this.props.passedPhoneVerification) {
            fields = {
                ...fields,
                email: 'Email',
            };
        }

        return (
            <div>
                <form onSubmit={this.props.onSubmit} className="text-left">
                    <div className="form-group" key="auth-register-field-email">
                        <label>Email</label>
                        <p>{_.get(this.props.formData, 'email')}</p>
                    </div>

                    {_.map(fields, (label, key) => {
                        const error = _.get(this.props.errors, [key][0])?.join(', ');
                        return (
                            <Input
                                id={key}
                                inputType={key}
                                placeholder={`Enter your ${label.toLowerCase()}...`}
                                label={label}
                                name={key}
                                value={_.get(this.props.formData, [key][0])}
                                autoComplete={key}
                                handleChange={(val) =>
                                    this.props.onChange(val, key)
                                }
                                inputClass={
                                    key == 'password' && 'password-input'
                                }
                                error={error}
                                required>
                                {key == 'password' && (
                                    <span
                                        className="show-password"
                                        onClick={this._togglePassword}>
                                        <i className="fa fa-eye icon"></i>
                                    </span>
                                )}
                            </Input>
                        );
                    })}

                    {this.props.type && this.props.type == 'chef' ? (
                        <ReduxContainer>
                            <ChefFields {...this.props} />
                        </ReduxContainer>
                    ) : (
                        <>
                            {!this.props.guestAccount && (
                                <>
                                    {!this.props.passedPhoneVerification && (
                                        <PhoneNumber
                                            errors={this.props.errors}
                                            formData={this.props.formData}
                                            onChange={this.props.onChange}
                                            label="Phone"
                                        />
                                    )}

                                    {/* <Input
                                        id="howDidYouFindOutAboutUs"
                                        name="howDidYouFindOutAboutUs"
                                        label="How did you find out about us?"
                                        value={
                                            this.props.formData
                                                .howDidYouFindOutAboutUs
                                        }
                                        handleChange={(val) =>
                                            this.props.onChange(
                                                val?.value,
                                                'howDidYouFindOutAboutUs'
                                            )
                                        }
                                        error={this.props.errors?.howDidYouFindOutAboutUs?.join(
                                            ', '
                                        )}
                                        required
                                        options={
                                            customerHowDidYouFindOutAboutUsOptions
                                        }
                                        inputType="select"
                                    /> */}
                                </>
                            )}
                        </>
                    )}

                    {this.renderCaptchaResponse() && (
                        <span className="invalid-feedback d-block" role="alert">
                            <strong>{this.renderCaptchaResponse()}</strong>
                        </span>
                    )}

                    <div className="form-group d-flex justify-content-between">
                        <button
                            className="btn btn-dark btn-outline"
                            onClick={this.props.reset}
                            type="button">
                            Back to login
                        </button>

                        <button
                            type="submit"
                            className={`btn btn-primary ${
                                this.props.loading ? 'btn-loading' : ''
                            }`}
                            disabled={this.props.loading}>
                            {this.props.loading ? (
                                <div className="loading-dots"></div>
                            ) : (
                                'Sign up'
                            )}
                        </button>
                    </div>

                    {(!this.props.type || this.props.type != 'chef') && (
                        <Input
                            id="newsletter-checkbox"
                            name="newsletter-checkbox"
                            inputType="checkbox"
                            isSelected={_.get(
                                this.props.formData,
                                'newsletter-checkbox'
                            )}
                            handleChange={(val) =>
                                this.props.onChange(val, 'newsletter-checkbox')
                            }
                            label="Keep me updated with special offers, exciting products and news from YHANGRY"
                        />
                    )}
                </form>
            </div>
        );
    }
}
