import { Star } from '@phosphor-icons/react';
import React, { Component, useEffect, useState } from 'react';

export default function StarRating({ value = 0, stars = 5, onChange = false }) {
    const [rating, setRating] = useState(value);
    const [hovered, setHovered] = useState(0);
    const [starCount, setStarCount] = useState([]);

    useEffect(() => {
        setRating(value);

        let outOf = stars ? stars : 5;

        let starArray = [];
        for (var i = 0; i < outOf; i++) {
            starArray.push(i + 1);
        }

        setStarCount(starArray);
    }, []);

    const changeRating = (newRating) => {
        setRating(newRating);
        if (onChange) onChange(newRating);
    };

    return (
        <div className="chef-reviews__star">
            {starCount.map((star) => {
                return (
                    <span
                        key={star}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            changeRating(star);
                        }}
                        onMouseEnter={() => {
                            setHovered(star);
                        }}
                        onMouseLeave={() => {
                            setHovered(0);
                        }}>
                        {rating < star ? (
                            hovered < star ? (
                                <Star />
                            ) : (
                                <Star weight="fill" />
                            )
                        ) : (
                            <Star weight="fill" />
                        )}
                    </span>
                );
            })}
        </div>
    );
}

// class StarRating extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             stars: [],
//             rating: this.props.rating,
//             hovered: 0
//         };

//         let outOf = props.outOf ? props.outOf : 5;

//         for (var i = 0; i < outOf; i++) {
//             this.state.stars.push(i + 1);
//         }
//     }

//     changeRating(newRating) {
//         this.setState({
//             rating: newRating
//         });

//         if (this.props.onChange) this.props.onChange(this.props.value, newRating);
//     }

//     hoverRating(rating) {
//         this.setState({
//             hovered: rating
//         });
//     }

//     render() {
//         const { stars, rating, hovered } = this.state;

//     }
// }

// export default StarRating;
