import React from 'react';
import { aws } from '@/config';

export default function Representative({
    className = '',
    imgClass = 'w-12 h-12 mr-3',
}) {
    return (
        <div
            className={`flex w-full items-center justify-between ${className}`}>
            <img
                className={`rounded-circle object-cover grow bg-primary p-1 ${imgClass}`}
                src={`${aws.url}/images/representative.png`}
                alt="Representative"
            />
            <div className="w-full">
                <p className="m-0 fw-bold">Akifa</p>
                <p className="m-0">Personal Event Concierge</p>
            </div>
        </div>
    );
}
