import {
    Cake,
    Heart,
    Martini,
    CallBell,
    Confetti,
    ForkKnife,
    HouseLine,
    Champagne,
    UsersThree,
} from '@phosphor-icons/react';

export const customerExpectionOptions = {
    vibe: {
        fun_and_friendly: 'Fun and friendly',
        chilled: 'Chilled',
        classy_and_polished: 'Classy and polished',
        slightly_formal: 'Slightly formal',
        not_stuffy: 'Not stuffy',
        sophisticated_and_fancy: 'Sophisticated and fancy',
        black_tie_affair: 'Black tie affair',
        party_and_music: 'Party and music',
    },
    food_presentation: {
        artful_and_formal_dining: 'Artful and formal dining',
        casual_plating_and_service: 'Casual plating and service',
        sharing_plates_buffet_style: 'Sharing plates buffet style',
    },
    food_quantity: {
        bite_sized: 'Refined & bite sized',
        just_right: 'Just right',
        large_portions: 'Large portions',
    },
    chef_engagement: {
        chefs_table_interaction: 'I want a chefs table level of interaction',
        good_level_interaction: 'I want a good level of interaction',
        discrete_chef: 'I want chef to be discreet',
    },
    chef_preparation: {
        prep_beforehand: 'Prep most of it beforehand',
        part_live_cook: 'Prep 50% / live cook 50% for the effect',
        everything_at_venue: 'Cook most of it at venue',
    },
    equipment: {
        minimal_equipment: 'Minimal equipment',
        decent_equipment: 'Decent equipment',
        complete_set: 'A complete set',
        not_sure: 'Not sure, will think about this later',
    },
    tableware: {
        have_enough: 'I have enough plates, platters, cutlery etc',
        check_with_chef:
            'I might need to check with chef if I have enough stuff',
        dont_have: "I don't have this. Can the chef help on a paid basis?",
        not_sure: 'Not sure, will think about this later',
    },
};

export const quoteOptions = {
    // use fetchDietaryRequirements to populate this
    dietaryPreferences: {},
    type: [
        {
            label: 'Birthday',
            icon: <Cake size={20} weight="bold" />,
        },
        {
            label: 'Dinner party',
            icon: <ForkKnife size={20} weight="bold" />,
        },
        {
            label: 'Hen / Stag do',
            icon: <Martini size={20} weight="bold" />,
        },
        {
            label: 'Date night',
            icon: <Heart size={20} weight="bold" />,
        },
        {
            label: 'Corporate',
            icon: <CallBell size={20} weight="bold" />,
        },
        {
            label: 'Family / Casual dinner',
            icon: <UsersThree size={20} weight="bold" />,
        },
        {
            label: 'Wedding / Engagement',
            icon: <Confetti size={20} weight="bold" />,
        },
        {
            label: 'Themed party',
            icon: <Champagne size={20} weight="bold" />,
        },
        {
            label: 'Holiday home chef',
            icon: <HouseLine size={20} weight="bold" />,
        },
        {
            label: 'Other',
            icon: <CallBell size={20} weight="bold" />,
        },
    ],
    budget: {
        4: 'I want to stay within my budget ideally',
        5: 'I want an insane experience, and flexible with budget for the right chef',
    },
    postcodes: [],
    cuisines: [],
    urgency: {
        1: 'Urgent, ASAP',
        3: 'Within a few weeks',
        5: 'Just started looking',
    },
    experiences: {
        1: 'Cooking Class',
        2: 'Wine & Cheese Pairing',
        3: 'Food & Art Pairing',
        4: 'Food & Molecular Mixology',
        5: 'Virtual Cooking Class',
    },
    servingType: {
        1: {
            id: 1, // Seated
            label: '3-course meal',
        },
        2: {
            id: 11, // Seated
            label: 'Sharing style',
        },
        3: {
            id: 6, // Fine dining
            label: 'Fine dining',
        },
        4: {
            id: 1, // Seated
            label: 'Buffet style',
        },
        5: {
            id: 1, // Seated
            label: 'Canapes',
        },
        6: {
            id: 1, // Seated
            label: 'BBQ',
        },
        7: {
            id: 7, // Cooking class
            label: 'Cooking class',
        },
        8: {
            id: 2, // Delivery platter
            label: 'Delivery platter',
        },
        9: {
            id: 4, // Meal prep
            label: 'Meal prep',
        },
        10: {
            id: 5, // Multi-day chef hire
            label: 'Multi-day chef hire',
        },
        11: {
            id: 8, // Brunch
            label: 'Brunch',
        },
        12: {
            id: 9, // Afternoon tea
            label: 'Afternoon tea',
        },
    },
    bookingFor: {
        1: 'Personal',
        2: 'Business',
        3: 'Client',
    },
    who: {
        Myself: 'Myself',
        Family: 'Family',
        Friends: 'Friends',
        Collegues: 'Collegues',
        Partner: 'Partner',
        Other: 'Other',
    },
    timeOfDay: {
        Morning: 'Morning',
        Midday: 'Midday',
        Evening: 'Evening',
    },
    typeOfChef: {
        1: {
            label: 'Casual dining',
            price: 200,
            min: '200',
            desc: '££',
            default: [40, 70],
            range: [20, 120],
        },
        2: {
            label: 'Fine dining',
            price: 500,
            min: '1k',
            desc: '£££',
            default: [70, 200],
            range: [50, 500],
        },
        3: {
            label: 'Celebrity chefs',
            price: 3000,
            min: '3k',
            desc: '£££££',
            default: [200, 3000],
            range: [250, 5000],
        },
    },
    typeOfChefFineDining: {
        1: {
            label: 'Premium menu',
            price: 700,
            min: '700',
            desc: '££',
            default: [70, 120],
            range: [20, 240],
        },
        2: {
            label: 'Tasting menu',
            price: 1000,
            min: '1000',
            desc: '£££',
            default: [130, 190],
            range: [50, 500],
        },
        3: {
            label: 'Luxury menu',
            price: 3000,
            min: '3k',
            desc: '£££££',
            default: [200, 3000],
            range: [150, 5000],
        },
    },
    shareBudget: {
        1: {
            label: "No, I want to see chef's prices",
            value: false,
        },
        2: {
            label: 'Yes, I want to stick to my budget',
            value: true,
        },
    },
    existingQuote: {
        1: {
            label: 'Yes, add this chef to my quote',
            value: true,
        },
        2: {
            label: 'No, create a new quote',
            value: false,
        },
    },
    protein: {
        meat: ['Beef', 'Pork', 'Lamb', 'Chicken', 'Duck', 'Exotic'],
        seafood: ['Fish', 'Shrimp / Prawns', 'Lobster', 'Crab'],
        vegetarian: ['Tofu', 'Beans & Pulses', 'Dairy', 'Vegan'],
    },
    addons: {
        foods: ['Cake', 'Canapés', 'Cheese platter'],
        nonfoods: ['Mixologist', 'Server', 'Event Planner'],
    },
    kitchen: {
        1: {
            label: 'Minimal',
            desc: 'Little or basic equipment',
            content: [
                "The kitchen isn't cooked in a lot.",
                'Only basic equipment (oven, stone, few pots/ pans, and the rest is up in the air)',
            ],
        },
        2: {
            label: 'Well equipped',
            desc: 'Good range of equipment',
            content: [
                'The kitchen is used for cooking often.',
                'Good range of equipment (oven, stove, enough pots/ pans, blender, grater, sieve, mixing bowls etc)',
            ],
        },
        3: {
            label: "Don't know",
            desc: 'Let the chef know later',
            content: [
                "It's a rented or friend’s home, so you need to check and get back to chef later.",
            ],
        },
    },
    allergies: [
        'Plant based only',
        'Peanut',
        'Tree nuts',
        'Egg',
        "Cow's milk",
        'Soy',
        'Wheat',
        'Fish',
        'Shellfish',
        'Other',
    ],
    lookingFor: {
        1: {
            label: "I'm keen to book, and want free custom quotes by chefs",
            desc: 'quote',
            content: [],
        },
        2: {
            label: 'I want to explore chef profiles',
            desc: 'chefs',
            content: [],
        },
        3: {
            label: 'I want to explore set menus',
            desc: 'menus',
            content: [],
        },
    },
    subscription: {
        1: {
            label: 'One off',
            value: false,
        },
        2: {
            label: 'Subscription (5% off)',
            value: true,
        },
    },
    contact: {
        1: {
            label: 'Messaging',
            value: false,
        },
        2: {
            label: 'Calls',
            value: true,
        },
    },
    previousEvent: {
        1: {
            label: 'Yes, in the last 60 days',
            value: 'Yes, in the last 60 days',
        },
        2: {
            label: 'Yes',
            value: 'Yes',
        },
        3: {
            label: 'No',
            value: 'No',
        },
    },
    concierge: {
        1: {
            label: 'Yes',
            value: true,
        },
        2: {
            label: 'No',
            value: false,
        },
    },

    ...customerExpectionOptions,
};
