import React, { Component } from 'react';

const handleInputChange = (event, field, updateGuests) => {
    if (field == 'guest_number') {
        if (event.target.value === '') {
            updateGuests(event.target.value);
        } else {
            let guests = Math.abs(event.target.value);
            updateGuests(parseInt(guests));
        }
    }
}

const NumOfGuests = ({guests, updateGuests, is_kids, min = 2, id = false, className = ""}) => {
    let minus = parseInt(guests) - 1;

    if (!is_kids && minus < min) {
        minus = min;
    } else if (is_kids && minus < 0) {
        minus = 0;
    }

    let canSubtract = guests > (is_kids ? 0 : min);
    let canAdd = guests < 99;
    
    return (
        <div className="guests-controls">
            <button type="button" className={`guests-control guests-control--subtract ${(!canSubtract) ? '-disabled' : ''}`} disabled={!canSubtract} onClick={() => updateGuests(minus)}> - </button>
            <input id={id && id != 'false' ? id : 'guests-controls'} className={`guests-controls__number form-control ${className}`} type='number' min={is_kids ? 0 : min} max='99' value={guests} onChange={(event) => handleInputChange(event, 'guest_number', updateGuests)}></input>
            <button type="button" className={`guests-control guests-control--add ${(!canAdd) ? '-disabled' : ''}`} disabled={!canAdd} onClick={() => updateGuests(guests != '' ? (parseInt(guests) + 1) : (is_kids ? 1 : 2))}> + </button>
        </div>
    )
}

export default NumOfGuests;
