import React from 'react';

export default function Step({
    size = 'lg',
    containerClass = '',
    className = '',
    children,
}) {
    let cols = 8;

    switch (size) {
        case 'full':
            cols = 12;
            break;
        case 'lg':
            cols = 8;
            break;
        case 'md':
            cols = 5;
            break;
        case 'sm':
            cols = 4;
            break;
        default:
            cols = 8;
            break;
    }

    return (
        <div className={`row justify-center h-100 ${containerClass}`}>
            <div className={`col-lg-${cols} col-12 h-100 ${className}`}>
                {children}
            </div>
        </div>
    );
}
