import React, { useState, useEffect } from 'react';
import TabsSwitch from '@/elements/nav/switch/TabsSwitch';
import Input from '@/elements/Input';

export default function PriceConverter({
    id = 'price-converter',
    guests = 8,
    total = false,
    perPerson = false,
    selectedOption = 'perPerson',
    handleChange = () => {},
    options = [
        { label: 'Per Person', value: 'perPerson' },
        { label: 'Total', value: 'total' },
    ],
    hideSwitch = false,
    autoFocus = false,
}) {
    return (
        <div className="flex justify-between flex-col-reverse md:flex-row">
            <div className="grow">
                <Input
                    id={id}
                    autoFocus={autoFocus}
                    className="m-0"
                    inputType="money"
                    placeholder="Enter your total budget"
                    value={selectedOption === 'perPerson' ? perPerson : total}
                    handleChange={(v) => {
                        if (selectedOption === 'perPerson') {
                            const total = parseInt(v) * guests;
                            handleChange(selectedOption, total, parseInt(v));
                            return;
                        }
                        const perPerson = parseInt(v) / guests;
                        handleChange(selectedOption, parseInt(v), perPerson);
                    }}
                />
            </div>
            {!hideSwitch && (
                <div className="md:ml-2 mb-2 md:!mb-0 w-full md:w-1/3">
                    <TabsSwitch
                        size="sm"
                        options={options}
                        selected={selectedOption}
                        onSelect={(option) =>
                            handleChange(option.value, total, perPerson)
                        }
                    />
                </div>
            )}
        </div>
    );
}
