import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Prompt from '@/views/Wizards/RequestWizard/Wrappers/Prompt';
import ChefsAvatar from '../Partials/ChefsAvatar';

export default function ChefsCountPrompt() {
    const { meta, options } = useSelector((state) => state.requestWizard);
    const [count, setCount] = useState(options.chefsCount);

    const stepPrefix = {
        location: 'in your area',
        date: 'on your date',
        budget: 'for your budget',
        cuisines: 'for your cuisines',
    };

    useEffect(() => {
        // Avoid changes while transitioning
        if (options.chefsCount === 0) return;

        setCount(options.chefsCount);
    }, [options.chefsCount]);

    return (
        <Prompt className='flex-row-reverse md:!flex-row items-center justify-end'>
            <div>
                <h5 className="fs-20 hidden md:block">Good news!</h5>
                <p className="mb-0 text-base ml-1 md:!ml-0">
                    <b>{count > 10 ? count : '10'} chefs available</b>{' '}
                    <br className='d-md-none' />
                    {stepPrefix[meta.stepName] || ''}
                </p>
            </div>
            <div>
                <ChefsAvatar className="max-h-12 md:max-h-none" />
            </div>
        </Prompt>
    );
}
