import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateRequest,
    getChefsCount,
    updateMeta,
} from '@/actions/wizard/RequestWizardActions';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import Input from '@/elements/Input';
import useExistingRequest from '@/hooks/wizard/useExistingRequest';
import useValidation from '@/hooks/wizard/useValidation';
import { debounce } from 'lodash';

export default function DateStep() {
    const dispatch = useDispatch();
    const { matchingRequests } = useExistingRequest();
    const { completeStep, stepError } = useValidation();
    const { request, meta } = useSelector((state) => state.requestWizard);

    const delayPrompt = useCallback(
        debounce(() => {
            dispatch(updateMeta('activePrompt', 'chefs-count'));
        }, 100),
        []
    );

    // useEffect(() => {
    //     if (
    //         request.mealDate.length > 0 &&
    //         options.chefsCount > 0 &&
    //         matchingRequests.length === 0
    //     ) {
    //         delayPrompt();
    //         return;
    //     }

    //     delayPrompt.cancel();
    // }, [matchingRequests, request.mealDate]);

    const updateMealDate = (v) => {
        const current = request.mealDate ?? [];
        const currentDates = current.map((date) => Date.parse(date));
        const newDate = Date.parse(v);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let newValue = [v];

        if (currentDates.includes(newDate)) {
            // deselect
            newValue = current.filter((date) => Date.parse(date) !== newDate);
        } else {
            // stop if new date is in the past (allow same day)
            if (newDate < today) return;
            // select
            newValue = [...current, v];
        }

        dispatch(updateRequest('mealDate', newValue));

        if (newValue.length > 0) {
            // dispatch(getChefsCount());
            completeStep();
        } else {
            stepError('Please select a date for your event.');
        }
    };

    return (
        <Step size={meta.variant === 'control' ? 'md' : 'lg'}>
            <h4 className="step-title">When?</h4>
            <p className="step-info">
                Select the date(s) for which you need a chef
            </p>
            <Input
                inputType="event-date"
                className="-calendar-boxed -calendar-violet"
                value={request.mealDate}
                handleChange={(v) => updateMealDate(v)}
                allowCurrentDate={true}
                embed={true}
                numberOfMonths={
                    meta.isMobile || meta.variant === 'control' ? 1 : 2
                }
            />
        </Step>
    );
}
