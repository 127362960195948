import React from 'react';
import { useSelector } from 'react-redux';
import { useStep } from '@/hooks/wizard/useStep';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import TrustpilotComboBox from '@/components/Wordpress/TrustPilot/TrustpilotComboBox';
// import Representative from '../Partials/Representative';

export default function Splash() {
    const { meta } = useSelector((state) => state.requestWizard);
    const { next } = useStep();

    return (
        <Step>
            <div className="flex flex-col h-100 md:justify-center">
                <div>
                    {/* <Representative className="mb-4" /> */}
                    <h3 className="text-3xl md:text-4xl text-balance grow font-bold">
                        Answer a few questions for{' '}
                        <br className="hidden xl:block" />a personalised
                        experience
                    </h3>
                    <p className="my-4 md:text-lg lg:text-xl">
                        Fine dining, sharing style, finger food or a BBQ
                        <br />
                        <b>- we'll match you with the perfect chefs.</b>
                    </p>

                    <ol className="m-0 list-inside list-numbered">
                        <li className="mb-2">
                            Answer <b>8 questions</b> (takes &lt;1 min)
                        </li>
                        <li className="mb-2">
                            <b>Browse chefs</b> matching your preferences
                        </li>
                        <li>
                            <b>Message chefs</b> about your event
                        </li>
                    </ol>
                </div>

                <div className="bg__chefs-network w-100 h-100 d-lg-none grow bg-contain bg-center" />

                <div className="flex flex-col w-fit cta-container">
                    <button
                        className="btn btn-primary btn-lg mt-4"
                        onClick={() => next()}>
                        Let's start
                    </button>

                    <div className="mt-2">
                        <TrustpilotComboBox />
                    </div>
                </div>
            </div>
        </Step>
    );
}
