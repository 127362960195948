import { createAction, createReducer } from '@reduxjs/toolkit';
import { initial } from 'lodash';
import Segment from '@/utils/Segment';

import {
    FETCH_CHEFS,
    FETCH_CHEFS_SUCCESS,
    FETCH_CHEFS_ERROR,
    FETCH_CHEFS_PAGINATE_SUCCESS,
    FILTER_CHEFS,
    APPLY_CHEFS_FILTERS,
    FILTER_CHEFS_CLEAR,
    ORDER_CHEFS,
    FETCH_CHEFS_EXPLORER_FILTERS,
    FETCH_CHEFS_EXPLORER_FILTERS_SUCCESS,
    FETCH_CHEFS_EXPLORER_FILTERS_ERROR,
} from '../constants/ActionTypes';

const emptyFilters = {
    order: [
        // If changed, update config/chefs.php
        { key: 'most-popular', value: 'Most popular' },
        { key: 'num-of-jobs', value: 'Number of jobs' },
        { key: 'price-low-high', value: 'Price low to high' },
        { key: 'price-high-low', value: 'Price high to low' },
        { key: 'recently-added', value: 'Newest chefs' },
        { key: 'distance', value: 'Closest to me' },
    ],
    price: [],
    type: [],
    offering: [],
    pricing: {
        min: 0,
        max: 0,
        ranges: [],
    },
    cuisines: [],
    rating: [],
    collections: [],
    search: '',
    category: false,
    categoryTitle: false,
    minPricePerPerson: 0,
};

const initialState = {
    loading: false,
    hasLoaded: false,
    errors: [],
    filters: emptyFilters,
    filtersLoading: false,
    filtersHasLoaded: false,
    selectedFilters: emptyFilters,
    selectedOrder: false,
    chefs: {
        list: [],
        pagination: {
            links: false,
            meta: false,
        },
        cardClick: false,
        cta: (chef = false) => {},
    },
    selectedChef: false,
    categoryTitles: {
        'dinner-party': 'Dinner Party',
        premium: 'Premium / Fine Dining',
        'bbq-party': 'BBQ Party',
        'canape-party': 'Canapé Party',
        'chef-hire': 'Chef Hire Only',
        'delivery-platters': 'Delivery Platters',
        'large-event': 'Large Event / Catering 50ppl+',
        christmas: 'Christmas',
        brunch: 'Brunch',
        'group-experiences': 'Group Experiences',
    },
};

export const chefs = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_CHEFS', (state) => {
            state.loading = true;
            // state.hasLoaded = false
        })
        .addCase('FETCH_CHEFS_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = initialState.errors;
            state.chefs.list = action.payload?.chefs?.data ?? [];
            state.chefs.additionalChefs = action.payload?.additionalChefs ?? [];
            state.chefs.recommendedChefs = action.payload?.recommendedChefs ?? [];
            state.chefs.pagination = {
                ...state.pagination,
                links: action.payload.chefs?.links,
                meta: action.payload.chefs?.meta,
            };
            // state.filters = action.payload?.filters
            //     ? action.payload.filters
            //     : state.filters;
        })
        .addCase('FETCH_CHEFS_PAGINATE_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = initialState.errors;
            state.chefs.list = [
                ...state.chefs.list,
                ...action.payload?.chefs?.data,
            ];
            state.chefs.pagination = {
                ...state.pagination,
                links: action.payload.chefs?.links,
                meta: action.payload.chefs?.meta,
            };
        })
        .addCase('FETCH_CHEFS_ERROR', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = action.payload?.errors;
        })
        .addCase('FETCH_CHEFS_EXPLORER_FILTERS', (state, action) => {
            state.filtersLoading = true;
        })
        .addCase('FETCH_CHEFS_EXPLORER_FILTERS_SUCCESS', (state, action) => {
            state.filters = action.payload?.filters
                ? action.payload.filters
                : state.filters;
            state.filtersLoading = false;
            state.filtersHasLoaded = true;
        })
        .addCase('FETCH_CHEFS_EXPLORER_FILTERS_ERROR', (state, action) => {
            state.filtersLoading = false;
            state.filtersHasLoaded = true;
            state.filters = state.filters;
        })
        .addCase('ORDER_CHEFS', (state, action) => {
            if (state.selectedOrder && state.selectedOrder != action.payload) {
                Segment.track('explorer.sort', {
                    value: action.payload?.order?.key || false,
                    label: action.payload?.order?.value || false,
                    source: action.payload.source || false,
                    show: 'chefs',
                });
            }
            state.selectedOrder = action.payload?.order;
        })
        .addCase('UPDATE_CHEF_CARD_CLICK', (state, action) => {
            state.chefs.cardClick = action.payload;
        })
        .addCase('UPDATE_CHEF_CARD_CTA', (state, action) => {
            state.chefs.cta = action.payload;
        })
        .addCase('FILTER_CHEFS', (state, action) => {
            if (
                action.payload.filter == 'search' ||
                action.payload.filter == 'category' ||
                action.payload.filter == 'minPricePerPerson'
            ) {
                state.selectedFilters[action.payload.filter] =
                    action.payload.value;

                if (action.payload.filter == 'category') {
                    state.selectedFilters['categoryTitle'] =
                        initialState.categoryTitles[action.payload.value];
                }

                return;
            }

            const index = state.selectedFilters[action.payload.filter].indexOf(
                action.payload.value
            );

            let payloadVal = action.payload.value;

            // if price, round the value
            if (action.payload.filter == 'price' && payloadVal.length > 1) {
                payloadVal = [
                    Math.round(payloadVal[0]),
                    Math.round(payloadVal[1]),
                ];
            }

            if (index > -1) {
                state.selectedFilters = {
                    ...state.selectedFilters,
                    [action.payload.filter]:
                        action.payload.filter != 'price' &&
                        action.payload.filter != 'rating'
                            ? [
                                  ...state.selectedFilters[
                                      action.payload.filter
                                  ].filter((item) => item != payloadVal),
                              ]
                            : payloadVal,
                };
                return;
            }

            Segment.track('explorer.filter', {
                filter: action.payload.filter,
                value: payloadVal,
                show: 'chefs',
                source: action.payload.source,
            });

            state.selectedFilters = {
                ...state.selectedFilters,
                [action.payload.filter]:
                    action.payload.filter != 'price' &&
                    action.payload.filter != 'rating'
                        ? [
                              ...state.selectedFilters[action.payload.filter],
                              payloadVal,
                          ]
                        : payloadVal,
            };
        })
        .addCase('APPLY_CHEFS_FILTERS', (state, action) => {
            state.selectedFilters = {
                ...state.selectedFilters,
                ...action.payload,
            };
        })
        .addCase('FILTER_CHEFS_CLEAR', (state, action) => {
            // Clear specific filter
            if (action.payload) {
                state.selectedFilters[action.payload] =
                    emptyFilters[action.payload];
                return;
            }

            // Clear all filters
            state.selectedFilters = emptyFilters;
        });
});
