import Input from '@/elements/Input';
import { toast } from '@/utils/Toast';
import React, { useState, useEffect, useRef } from 'react';

export default function PhoneOTP({
    reset,
    loading,
    onSubmit,
    formData,
    errors,
    resendCode,
    onChange,
    ...props
}) {
    const [completeCode, setCompleteCode] = useState('');
    const singleInputRef = useRef(null);

    useEffect(() => {
        if (singleInputRef.current) {
            singleInputRef.current.focus();
        }
    }, []);

    const handlePaste = (event) => {
        let pastedCode = event.clipboardData.getData('text').trim();
        if (pastedCode.length === 6 && /^\d+$/.test(pastedCode)) {
            setCompleteCode(pastedCode);
            setCode(pastedCode.split(''));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        await onChange(completeCode, 'otp');

        onSubmit(event);
    };

    return (
        <div className="text-left">
            <p>
                Enter the code we sent you over SMS to {formData.phone}.{' '}
                <a href="#" onClick={reset} className="fw--bold">
                    Change
                </a>
            </p>
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="phone-otp-validator d-flex gap-3">
                        <Input
                            inputType="text"
                            type="tel"
                            inputMode="numeric"
                            autoComplete="one-time-code"
                            maxLength="6"
                            placeholder="Enter code"
                            pattern="\d{6}"
                            className="my-0 one-time-code "
                            inputClass="text-center !mx-auto"
                            value={completeCode}
                            ref={singleInputRef}
                            handleChange={(event) => {
                                // if already 6 characters, and the customer is trying to type more, dont allow it
                                // but allow deletion of characters
                                if (
                                    completeCode.length === 6 &&
                                    event.length > 6
                                ) {
                                    return;
                                }

                                setCompleteCode(event);
                            }}
                        />
                    </div>
                    {errors?.otp && (
                        <div className="invalid-feedback d-block">
                            {errors.otp.join(', ')}
                        </div>
                    )}
                </div>

                <button
                    className="btn btn-tertiary"
                    onClick={(ev) => {
                        resendCode(ev);
                        toast({
                            type: 'success',
                            title: 'Success!',
                            text: 'Code resent successfully.',
                            position: 'top_right',
                            delay: 0,
                        });
                    }}
                    type="button">
                    Resend code
                </button>

                <div className="form-group d-flex justify-content-between">
                    <button
                        className="btn btn btn-dark btn-outline"
                        onClick={reset}
                        type="button">
                        Back to login
                    </button>

                    <button
                        type="submit"
                        className={`btn btn-primary ${
                            loading ? 'btn-loading' : ''
                        }`}
                        disabled={loading}>
                        {loading ? (
                            <div className="loading-dots"></div>
                        ) : (
                            'Continue'
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
}
123455;
