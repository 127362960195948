export const stripe = {
    key: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
};

export const idealPostcodes = {
    key: import.meta.env.VITE_IDEALPOSTCODES_API_KEY,
};

export const aws = {
    url: import.meta.env.VITE_AWS_URL,
    path: import.meta.env.VITE_AWS_PATH,
    asset_path: import.meta.env.VITE_AWS_URL + import.meta.env.VITE_AWS_PATH,
};
