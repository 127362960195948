import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import { useStep } from '@/hooks/wizard/useStep';
import useValidation from '@/hooks/wizard/useValidation';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import Input from '@/elements/Input';

export default function OccasionStep() {
    const dispatch = useDispatch();
    const { request, options, meta } = useSelector(
        (state) => state.requestWizard
    );
    const { completeStep } = useValidation();
    const { next } = useStep();

    const mapOption = (option) => {
        return {
            label: (
                <>
                    <option.icon
                        size={meta.isMobile ? 20 : 24}
                        className="mr-2"
                    />
                    {option.label}
                </>
            ),
            value: option.label,
        };
    };

    const occasionOptions = options.type.map((option) => mapOption(option));

    const handleSelect = (v) => {
        if (v === 'Meal prep' || request.type === 'Meal prep') {
            dispatch(updateRequest('servingType', ''));
            completeStep(false, 'food-type');
        }
        dispatch(updateRequest('type', v));
        completeStep();
        next();
    };

    return (
        <Step>
            <h4 className="step-title">What type of event are you planning?</h4>
            <p className="step-info">
                Give us a few details to see chefs, menus and prices. It will
                take &lt;2min.
            </p>
            <Input
                cols={3}
                inputType="checkbox"
                value={request.type}
                handleChange={(v) => handleSelect(v)}
                className="input__checkbox"
                options={occasionOptions}
            />
        </Step>
    );
}
