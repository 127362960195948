import { merge } from 'lodash';
import { createAction, createReducer } from '@reduxjs/toolkit'


import {
    FETCH_BASKET,
    FETCH_BASKET_SUCCESS,
    FETCH_BASKET_ERROR,
    CLEAR_BASKET,
    UPDATE_BASKET_OPTION,
    UPDATE_POSTCODE,
    UPDATE_EXTRADISHESCOST,
    UPDATE_EVENT_DETAILS,
    UPDATE_BASKET_DISHES,
    UPDATE_SERVING_TYPE,
    UPDATE_TOTAL,
    UPDATE_TRAVEL_FEE,
    GET_SUPPLY_DEMAND_DAY_RATE,
    GET_SUPPLY_DEMAND_DAY_RATE_SUCCESS,
    GET_SUPPLY_DEMAND_DAY_RATE_ERROR
} from '../constants/ActionTypes';

const initialState = {
    error: false,
    loading: false,
    hasLoaded: false,
    basket: {
        selectedDishes: []
    },
    basketIds: [],
    cuisineModal: false,
    cuisineModalData: '',
    maxDishesPerCourse: 12,
    full: [],
    totalDishesPerCourses: 0,
    isBasketFull: false,
    subscription: null,
    postcode: false,
    city_id: false,
    guests: 10,
    kids: 0,
    mealDate: false,
    base_setmenu: false,
    base_setmenu_details: false,
    builder: false,
    dietaryRequirements: '',
    extra_dishes_cost: false,
    servingType: 1,
    total: 0,
    travelFee: 0,
    supplyDemandRate: 0,
    menu_help: false,
    budget_min: false,
    budget_max: false
};


export const basketReducer = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_BASKET', (state, action) => {
            state.loading = true
        })
        .addCase('FETCH_BASKET_SUCCESS', (state, action) => {
            state.basket = action.payload.data.cart.basket
            state.eventDetails = action.payload.data.cart
            state.postcode = action.payload.data.cart.postcode
            state.guests = action.payload.data.cart.guests ?? 10
            state.kids = action.payload.data.cart.kids ?? 0
            state.city_id = action.payload.data.cart.city_id
            state.mealDate = action.payload.data.cart.mealDate
            state.base_setmenu = action.payload.data.cart.base_setmenu
            state.base_setmenu_details = action.payload.data.cart.base_setmenu_details
            state.builder = action.payload.data.cart.builder
            state.extra_dishes_cost = action.payload.data.cart.extra_dishes_cost
            state.menu_help = action.payload.data.cart.menu_help
            state.budget_min = action.payload.data.cart.budget_min,
            state.budget_max = action.payload.data.cart.budget_max,
            state.error = false
            state.loading = false
            state.hasLoaded = true

        })
        .addCase('FETCH_BASKET_ERROR', (state, action) => {
            state.error = true
            state.loading = false
            state.hasLoaded = true

        })
        .addCase('UPDATE_POSTCODE', (state, action) => {
            state.postcode = action.payload.postcode
            state.city_id = action.payload.city_id

        })
        .addCase('UPDATE_EXTRADISHESCOST', (state, action) => {
            state.extra_dishes_cost = action.payload.extra_dishes_cost;
        })

        .addCase('UPDATE_EVENT_DETAILS', (state, action) => {
            state.postcode = action.payload.postcode
            state.mealDate = action.payload.mealDate
            state.guests = action.payload.guests
            state.kids = action.payload.kids

        })
        .addCase('UPDATE_BASKET_OPTION', (state, action) => {
            const newState = action.payload

            return {
                ...state,
                ...newState
            };
        })

        .addCase('UPDATE_BASKET_DISHES', (state, action) => {
            // Deep clone dishes.
            state.basket.selectedDishes = JSON.parse(JSON.stringify(action.payload.selectedDishes));
        })
        .addCase('CLEAR_BASKET', (state, action) => {
            return initialState
        })
        .addCase('UPDATE_SERVING_TYPE', (state) => {
            state.servingType = (state.servingType == 1) ? 2 : 1
        })
        .addCase('UPDATE_TOTAL', (state, action) => {
            state.total = action.payload
        })
        .addCase('UPDATE_TRAVEL_FEE', (state, action) => {
            state.travelFee = action.payload;
        })
        .addCase('GET_SUPPLY_DEMAND_DAY_RATE', (state, action) => {
            state.supplyDemandRate = initialState.supplyDemandRate;
        })
        .addCase('GET_SUPPLY_DEMAND_DAY_RATE_SUCCESS', (state, action) => {
            state.supplyDemandRate = (action.payload?.demand) ? action.payload?.demand : 0;
        })
        .addCase('GET_SUPPLY_DEMAND_DAY_RATE_ERROR', (state, action) => {
            state.supplyDemandRate = initialState.supplyDemandRate;
        })
})
