import React, { useState, useEffect } from 'react';

import Input from '@/elements/Input';
import { removeSimilarities } from '@/utils/helpers';
import { useSelector, useDispatch } from 'react-redux';
import useValidation from '@/hooks/wizard/useValidation';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import { regexes } from '@/constants/regex';

const similarityThreshold = 25; // 25% similarity.

function checkForFlaggedContent(value) {
    const isFlagged = Object.values(regexes).some((regex) =>
        regex.test(value.replace('-', '').trim())
    );

    if (isFlagged) {
        let flaggedContent = Object.values(regexes)
            .flatMap(
                (regex) =>
                    value
                        .replace(' ', '')
                        .replace('-', '')
                        .trim()
                        .match(regex) || []
            )
            .filter(
                (match, index, self) => match && self.indexOf(match) === index
            );

        // Remove similar items based on a similarity threshold.
        flaggedContent = removeSimilarities(
            flaggedContent,
            similarityThreshold
        );

        // Convert flagged content to displayable format
        flaggedContent = flaggedContent.map(
            (match) => `<strong>"${match}"</strong>`
        );

        let message = `Links and contact info can't be shared until after a reservation is confirmed.`;

        if (flaggedContent.length > 0) {
            message += ` Please remove the info below before submitting:<br /> ${flaggedContent.join(
                ', '
            )}`;
        }

        return {
            isFlagged: true,
            message: message,
        };
    }

    return { isFlagged: false, message: '' };
}

export default function PreferenceStep() {
    const dispatch = useDispatch();
    const { completeStep, stepError } = useValidation();
    const { request } = useSelector((state) => state.requestWizard);

    useEffect(() => {
        const { isFlagged, message } = checkForFlaggedContent(
            request.foodPreferences
        );
        if (isFlagged) {
            stepError(message);
        } else {
            completeStep();
        }
    }, []);

    const updatePreference = (newValue) => {
        dispatch(
            updateRequest({
                foodPreferences: newValue,
                additional_info_length: newValue.length,
            })
        );

        if (newValue.length === 0) {
            stepError(false);
            completeStep();
            return;
        }

        const { isFlagged, message } = checkForFlaggedContent(newValue);

        if (isFlagged) {
            stepError(message);
        } else {
            stepError(false);
            completeStep();
        }
    };

    return (
        <Step size="md">
            <h4 className="step-title">Tell us more</h4>
            <p className="step-info">
                Vibe of your event - casual, formal, any special requirements
                needed etc.
            </p>
            <Input
                rows={10}
                inputType="textarea"
                placeholder="E.g. Two of us are gluten-free. We would like sharing style tapas plates. We like mushrooms, don't like cheese. This is for a 70th birthday. We are hoping for the chef to provide crockery and a waiter."
                value={request.foodPreferences}
                handleChange={(v) => {
                    updatePreference(v);
                }}
            />
        </Step>
    );
}
