import ErrorPrompt from '@/components/Wizard/RequestWizard/Prompts/ErrorPrompt';
import ChefsCountPrompt from '@/components/Wizard/RequestWizard/Prompts/ChefsCountPrompt';
import IntlLocationPrompt from '@/components/Wizard/RequestWizard/Prompts/IntlLocationPrompt';
import ExistingRequestPrompt from '@/components/Wizard/RequestWizard/Prompts/ExistingRequestPrompt';

export const prompts = [
    {
        name: 'none', // required
        component: () => null, // required
    },
    {
        name: 'error',
        component: ErrorPrompt,
    },
    {
        name: 'chefs-count',
        component: ChefsCountPrompt,
    },
    {
        name: 'intl-location',
        component: IntlLocationPrompt,
    },
    {
        name: 'existing-request',
        component: ExistingRequestPrompt,
    },
];
