import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getChefsCount } from '@/actions/wizard/RequestWizardActions';
import { useStep } from '@/hooks/wizard/useStep';
import useValidation from '@/hooks/wizard/useValidation';
import useWizard from '@/hooks/wizard/useWizard';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import ChefsAvatar from '../Partials/ChefsAvatar';
import Auth from '@/views/Auth/Auth';

export default function AuthStep() {
    const dispatch = useDispatch();
    const { request, options, meta } = useSelector(
        (state) => state.requestWizard
    );
    const { completeStep } = useValidation();
    const { activeStep, prev } = useStep();
    const { submit } = useWizard();

    useEffect(() => {
        dispatch(getChefsCount());
    }, []);

    useEffect(() => {
        if (activeStep.name !== 'auth' || !activeStep.isComplete) return;
        submit();
        prev();
    }, [activeStep]);

    const saveToSession = () => {
        sessionStorage.setItem(
            'yh-pending-request',
            JSON.stringify({ meta, request, steps: options.steps })
        );
        sessionStorage.setItem('redirectTo', window.location.href);
    };

    return (
        <Step>
            <div className="flex items-center h-100">
                <div className="xl:max-w-lg">
                    <h4 className="text-2xl">
                        See{' '}
                        {options.chefsCount > 10 ? options.chefsCount : '10'}{' '}
                        available chefs
                    </h4>
                    <p className="step-info mb-2 mx-0 text-left">
                        Matching your location, event date, budget and cuisine
                        preferences
                    </p>
                    <ChefsAvatar />
                    <Auth
                        embed={true}
                        title="Login or sign up to read their reviews and chat with them"
                        beforeSocialAuth={() => saveToSession()}
                        callback={() => completeStep()}
                    />
                </div>
            </div>
        </Step>
    );
}
