import React, { useState, useEffect } from 'react';
import Prompt from '@/views/Wizards/RequestWizard/Wrappers/Prompt';
import { useStep } from '@/hooks/wizard/useStep';
import { usePrevious } from '@/utils/hooks';

export default function ErrorPrompt() {
    const { activeStep } = useStep();
    const [error, setError] = useState(activeStep.error);
    const prev = usePrevious(activeStep.error);

    useEffect(() => {
        // Avoid changes while transitioning
        if (!activeStep.error) return;

        // Only update error if it was changed
        if (activeStep.error !== prev) {
            setError(activeStep.error);
        }
    }, [activeStep.error]);

    if (!error) return <></>;

    return (
        <Prompt containerClass="--bg-brand-primary-light fw-bold">
            <div dangerouslySetInnerHTML={{ __html: error }} />
        </Prompt>
    );
}
