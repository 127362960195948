import React from 'react';
import { toast as toastify } from 'react-toastify';

export function toast({
    type = 'info',
    title = false,
    text = false,
    position = 'bottom_right',
    delay = 4000,
}) {
    const content = () => (
        <div>
            {title && (
                <p className="mb-1 fs-16 fw-bold -text-color-grey-900">
                    {title}
                </p>
            )}
            {text && <p className="m-0">{text}</p>}
        </div>
    );

    toastify[type](content, {
        autoClose: delay,
        position: toastify.POSITION[position.toUpperCase()],
        bodyClassName: '[&>div]:w-auto',
        icon: ({ theme, type }) => {
            type = type == 'error' ? 'danger' : type;
            type = type == 'info' ? 'secondary' : type;

            let icon =
                type == 'secondary'
                    ? 'Info'
                    : type == 'success'
                    ? 'Check---Circle'
                    : type == 'danger'
                    ? 'Alert---Octagon'
                    : 'Info';

            return (
                <div>
                    <div className={`btn btn-icon btn-${type}`}>
                        <i
                            className={`yh-new-icon-${icon} fs-18 ${
                                type == 'secondary'
                                    ? '-text-color-grey-900'
                                    : ''
                            }`}></i>
                    </div>
                </div>
            );
        },
    });
}
