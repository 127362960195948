import { createAction, createReducer } from '@reduxjs/toolkit'

import {
    SELECT_JOB,
    FETCH_JOBS,
    FILTER_JOBS,
    FETCH_JOBS_ERROR,
    FETCH_JOBS_SUCCESS,
    DEEPLINK_FILTERS_JOBS,
    FETCH_JOBS_PAGINATE_SUCCESS
} from '../constants/ActionTypes';

const emptyFilters = {
    orderBy: [],
    budget: [],
    search: '',
    location: [],
};

const initialState = {
    errors: [],
    isChef: false,
    loading: false,
    hasLoaded: false,
    selectedJob: false,
    filters: emptyFilters,
    selectedFilters: {
        orderBy: 'Created at',
        budget: 'Any',
        search: '',
        location: 'uk'    
    },
    jobs: {
        list: [],
        pagination: {
            links: false,
            meta: false,
        },
    },
};

const individualFilters = ['orderBy', 'budget', 'search', 'location'];

export const jobs = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_JOBS', (state) => {
            state.errors = [];
            state.loading = true;
            state.errors = initialState.errors;
        })
        .addCase('FETCH_JOBS_ERROR', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = action.payload?.errors;
        })
        .addCase('FETCH_JOBS_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = initialState.errors;
            state.isChef = action.payload?.is_chef ?? false;
            state.jobs.list = action.payload?.jobs?.data ?? [];
            state.filters = (action.payload?.filters) ? action.payload.filters : state.filters;
            state.jobs.pagination = {...state.pagination, links: action.payload.jobs?.links, meta: action.payload.jobs?.meta};
        })
        .addCase('FETCH_JOBS_PAGINATE_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = initialState.errors;
            state.isChef = action.payload?.is_chef ?? false;
            state.jobs.list = [...state.jobs.list, ...action.payload?.jobs?.data];
            state.jobs.pagination = {...state.pagination, links: action.payload.jobs?.links, meta: action.payload.jobs?.meta};
        })
        .addCase('SELECT_JOB', (state, action) => {
            state.selectedJob = action.payload;
        })
        .addCase('FILTER_JOBS', (state, action) => {
            if (individualFilters.includes(action.payload.filter)) {
                state.selectedFilters[action.payload.filter] = action.payload.value;
                return;
            }
        })
        .addCase('DEEPLINK_FILTERS_JOBS', (state, action) => {            
            const filters = action.payload?.url;
            const location = action.payload?.location;

            state.selectedFilters.location = (location && location != '') ? location: 'uk';
            if (filters.get('budget')) state.selectedFilters.budget = filters.get('budget');
            if (filters.get('search')) state.selectedFilters.search = filters.get('search');
            if (filters.get('orderBy')) state.selectedFilters.orderBy = filters.get('orderBy');
        });
});