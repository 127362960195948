import React from 'react';
import Step from '../Wrappers/Step';

export default function RequestWizardLoading() {
    return (
        <Step>
            <div className="request-wizard__loading">
                <div className="step-title loading-ele rounded h-11 w-1/2 md:mx-auto" />
                <div className="step-info loading-ele rounded h-11" />
                <div className="loading-ele rounded h-72" />
            </div>
        </Step>
    );
}
