import React from 'react';

/**
 *
 * Do NOT use this component directly. Use the Input component instead.
 *
 */

export default function CheckBox({
    label,
    value,
    select = false,
    isSelected = false,
    isMulti = true,
    onChange = false,
    optionClass = '',
    style = null,
    ...props
}) {
    return (
        <span className="form-styling">
            <input
                className="radio-primary peer"
                onClick={select ? () => select(value) : null}
                type={isMulti ? 'checkbox' : 'radio'}
                id={props.id ?? `checkbox-${value}`}
                value={value}
                readOnly
                checked={isSelected}
                onChange={onChange ? (e) => onChange(e) : null}
            />
            <label
                htmlFor={props.id ?? `checkbox-${value}`}
                className={optionClass}
                style={style}>
                {props.image && (
                    <div className="checkbox-image mb-2">
                        <img
                            src={props.image}
                            alt={label}
                            className="w-full max-h-28 object-cover rounded-sm aspect-square"
                        />
                    </div>
                )}
                <span>{label}</span>
            </label>
        </span>
    );
}
