import { createAction, createReducer } from '@reduxjs/toolkit';

import {
    BESPOKE_QUOTE_NEXT_STEP,
    BESPOKE_QUOTE_PREV_STEP,
    BESPOKE_QUOTE_TO_STEP,
    BESPOKE_QUOTE_UPDATE_VALUE,
    BESPOKE_QUOTE_UPDATE_ATTRIBUTE,
    POSTCODES_FETCH_AUTOCOMPLETE,
    POSTCODES_FETCH_AUTOCOMPLETE_SUCCESS,
    POSTCODES_FETCH_AUTOCOMPLETE_ERROR,
    BESPOKE_QUOTE_SUBMIT,
    BESPOKE_QUOTE_SUBMIT_SUCCESS,
    BESPOKE_QUOTE_SUBMIT_ERROR,
    BESPOKE_QUOTE_IS_EMBED,
    FETCH_CUISINES_SUCCESS,
    BESPOKE_QUOTE_FETCH_CHEF_COUNT_SUCCESS,
    BESPOKE_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS,
    BESPOKE_QUOTE_SELECT_RECOMMENDED_CHEF,
    GET_EXISTING_QUOTE,
    GET_EXISTING_QUOTE_ERROR,
    GET_EXISTING_QUOTE_SUCCESS,
    UPDATE_EXISTING_QUOTE,
    UPDATE_EXISTING_QUOTE_SUCCESS,
    UPDATE_EXISTING_QUOTE_ERROR,
} from '../constants/ActionTypes';
import { chefs } from '../utils/chefs';
import { getLocalStorage } from '../utils/helpers';

import {
    Cake,
    Heart,
    Martini,
    CallBell,
    Confetti,
    ForkKnife,
    HouseLine,
    Champagne,
    UsersThree,
} from '@phosphor-icons/react';
import { quoteOptions } from '@/constants/options/QuoteOptions';

const initialState = {
    error: false,
    errors: [],
    loading: false,
    hasSubmitted: false,
    updateExistingQuote: false,
    step: 0,
    embed: false,
    quote: {
        guests: 8,
        kids: 0,
        mealDate: '',
        postcode: '',
        dietaryPreferences: [],
        validPostcode: false,
        type: '',
        cuisines: [],
        cuisinesExtra: [],
        experiences: [],
        budget: '',
        budgetRange: [],
        perPersonBudget: 60,
        // budgetType: 5, // Default - I'm flexible with my budget
        budgetType: false,
        suggestedBudget: [200, 1000],
        foodPreferences: '',
        dietaryNotes: '',
        typeOfEvent: false,
        bookingFor: false,
        shareBudget: 2,
        who: [],
        whoExtra: '',
        timeOfDay: [],
        typeOfChef: false,
        servingType: false,
        existingQuote: '',
        selectedExistingQuote: false,
        protein: [],
        addons: [],
        addonsExtra: '',
        kitchen: false,
        allergies: [],
        allergiesExtra: '',
        // user data
        email: '',
        phone: '',
        firstName: '',
        lastName: '',
        'newsletter-checkbox': true,
        budgetAssistance: false,
        multipleMealDatesBoolean: false,
        multipleMealDates: '',
        validEmail: false,
        emailMethod: false,
        urgency: false,
        verified: false,
        timeSlot: '',
        message: '',
        chefId: false,
        source: 'popup',
        ctaText: 'n/a',
        lookingFor: '',
        minSpend: 0,
        minPp: 0,
        setMenuId: false,
        subscriptionType: false,
        contactType: null,
        previousEvent: null,
        // conciergeInterest: null,
        conciergeInterest: false,
        vibe: [],
        foodPresentation: null,
        foodQuantity: null,
        chefEngagement: null,
        chefPreparation: null,
        equipment: null,
        tableware: null,
        version: 1,
        wizardVersion: 1,
    },
    options: quoteOptions,
    confirmation: {
        quoteId: false,
        recommendedChefs: [],
        selectedChefs: [],
        conversationId: false,
    },
    setMenuCount: 0,
    redirect: false,
    quoteChefCount: 0,
    chefsUnavailableDays: [],
    existingQuote: null,
    isPostcodeValid: false,
    isPostcodeInternational: false,
};

// for easy local testing if you need it, just pass localState into the create reducer
// theres another string below to help
// const localState = getLocalStorage('yh-quote', initialState)

const cuisineIds = {
    'Premium / Fine Dining': ['Fine Dining', 22],
    'BBQ Party': ['BBQ', 25],
    Brunch: ['Brunch', 6],
    'Canapé Party': ['Canapé Party', 36],
    Christmas: ['Christmas', 31],
    'Group Experiences': ['Group Experiences', 88],
};

export const bespokeQuote = createReducer(initialState, (builder) => {
    builder
        .addCase('BESPOKE_QUOTE_NEXT_STEP', (state, action) => {
            if (action.payload.stepsToSkip) {
                state.step = state.step + action.payload.stepsToSkip;
            } else {
                state.step++;
            }
        })
        .addCase('BESPOKE_QUOTE_PREV_STEP', (state, action) => {
            if (action.payload.stepsToSkip) {
                state.step = state.step - action.payload.stepsToSkip;
            } else {
                state.step = state.step - 1 > 0 ? state.step - 1 : 0;
            }
            state.quote.existingQuote = initialState.quote.existingQuote;
        })
        .addCase('BESPOKE_QUOTE_TO_STEP', (state, action) => {
            state.step = action.payload.step;
        })
        .addCase('BESPOKE_QUOTE_UPDATE_VALUE', (state, action) => {
            state.quote = action.payload.value;
        })
        .addCase('BESPOKE_QUOTE_UPDATE_ATTRIBUTE', (state, action) => {
            if (action.payload.array) {
                if (
                    state.quote[action.payload.attribute].includes(
                        action.payload.value
                    )
                ) {
                    state.quote[action.payload.attribute] = state.quote[
                        action.payload.attribute
                    ].filter((item) => item !== action.payload.value);
                } else {
                    state.quote[action.payload.attribute].push(
                        action.payload.value
                    );
                }
            } else {
                if (action.payload.attribute == 'type') {
                    if (cuisineIds[action.payload.value]) {
                        state.quote.cuisines = [
                            cuisineIds[action.payload.value][1],
                        ];
                    } else if (action.payload.value == 'Dinner Party') {
                        let cuisineArray = state.quote.cuisines;

                        // bbq cuisine
                        const bbqIndex = cuisineArray.indexOf(25);
                        if (bbqIndex > -1) cuisineArray.splice(bbqIndex, 1);

                        // canape cuisine
                        const canapeIndex = cuisineArray.indexOf(36);
                        if (canapeIndex > -1)
                            cuisineArray.splice(canapeIndex, 1);
                    } else if (
                        state.quote.type == 'Premium / Fine Dining' ||
                        state.quote.type == 'BBQ Party' ||
                        state.quote.type == 'Brunch' ||
                        state.quote.type == 'Canapé Party' ||
                        state.quote.type == 'Christmas' ||
                        state.quote.type == 'Group Experiences'
                    ) {
                        state.quote.cuisines = [];
                    }
                }

                state.quote[action.payload.attribute] = action.payload.value;
            }

            // For easy local testing
            // localStorage.setItem('yh-quote', JSON.stringify(state));
        })
        .addCase('POSTCODES_FETCH_AUTOCOMPLETE_SUCCESS', (state, action) => {
            state.isPostcodeValid = action.payload.isValid ?? false;
            state.options.postcodes = action.payload.postcodes ?? [];
            state.isPostcodeInternational =
                action.payload.isInternational ?? false;
        })
        .addCase('POSTCODES_FETCH_AUTOCOMPLETE_ERROR', (state) => {
            state.options.postcodes = [];
            state.isPostcodeValid = false;
            state.isPostcodeInternational = false;
        })
        .addCase('BESPOKE_QUOTE_SUBMIT', (state) => {
            state.loading = true;
        })
        .addCase('BESPOKE_QUOTE_SUBMIT_SUCCESS', (state, action) => {
            state.setMenuCount = action.payload.setMenuCount;
            state.redirect = action.payload.redirect;
            state.confirmation.quoteId = action.payload.quote;
            state.confirmation.recommendedChefs =
                action.payload.recommendedChefs;
            state.confirmation.conversationId = action.payload.conversation_id;
            state.loading = false;
            state.hasSubmitted = true;
            // state.step = 100;
        })
        .addCase('BESPOKE_QUOTE_SUBMIT_ERROR', (state, action) => {
            state.loading = false;
            state.error = action.payload.data.error;
            state.errors = action.payload.data.errors;
        })
        .addCase('BESPOKE_QUOTE_IS_EMBED', (state) => {
            state.embed = true;
        })
        .addCase('FETCH_CUISINES_SUCCESS', (state, action) => {
            state.options.cuisines = action.payload;
        })
        .addCase('BESPOKE_QUOTE_FETCH_CHEF_COUNT_SUCCESS', (state, action) => {
            state.quoteChefCount =
                action.payload.chefCount < 10 ? 10 : action.payload.chefCount;
        })
        .addCase(
            'BESPOKE_QUOTE_FETCH_CHEF_UNAVAILABLE_DAYS',
            (state, action) => {
                state.chefsUnavailableDays = action.payload.unavailable_days;
            }
        )
        .addCase('BESPOKE_QUOTE_SELECT_RECOMMENDED_CHEF', (state, action) => {
            if (state.confirmation.selectedChefs.includes(action.payload)) {
                state.confirmation.selectedChefs =
                    state.confirmation.selectedChefs.filter(
                        (chefItem) => chefItem != action.payload
                    );
            } else {
                state.confirmation.selectedChefs.push(action.payload);
            }
        })
        .addCase('GET_EXISTING_QUOTE', (state, action) => {
            state.existingQuote = initialState.existingQuote;
            state.quote.existingQuote = initialState.quote.existingQuote;
        })
        .addCase('GET_EXISTING_QUOTE_SUCCESS', (state, action) => {
            state.existingQuote = action.payload?.quote
                ? action.payload?.quote
                : null;
            state.quote.existingQuote = initialState.quote.existingQuote;
        })
        .addCase('GET_EXISTING_QUOTE_ERROR', (state, action) => {
            state.existingQuote = initialState.existingQuote;
            state.quote.existingQuote = initialState.quote.existingQuote;
        })
        .addCase('UPDATE_EXISTING_QUOTE', (state) => {
            state.loading = true;
            state.error = initialState.error;
        })
        .addCase('UPDATE_EXISTING_QUOTE_SUCCESS', (state, action) => {
            state.step = 13;
            state.loading = false;
            state.hasSubmitted = true;
            state.redirect = action.payload.redirect;
            state.setMenuCount = action.payload.setMenuCount;
            state.confirmation.quoteId = action.payload.quote;
            state.updateExistingQuote = action.payload.updateExistingQuote;
            state.confirmation.recommendedChefs =
                action.payload.recommendedChefs;
            state.confirmation.conversationId = action.payload.conversation_id;
        })
        .addCase('UPDATE_EXISTING_QUOTE_ERROR', (state, action) => {
            state.loading = false;
            state.error = action.payload.data.message;
        })
        .addCase('FETCH_DIETARY_REQUIREMENTS_SUCCESS', (state, action) => {
            state.options.dietaryPreferences =
                action.payload?.data?.dietaryRequirements?.map((dietary) => {
                    return {
                        id: dietary.id,
                        label: dietary.name,
                    };
                });
        });
});
