import Input from '@/elements/Input';
import React, { Component, useState } from 'react';
import _ from 'lodash';

// export default class CustomerEmail extends Component {
export default function CustomerEmail({
    onSubmit,
    onChange,
    formData,
    errors,
    loading,
    toggleDefault,
    open,
    type,
}) {
    // const [useEmail, setUseEmail] = useState(false);

    const error = _.get(errors, 'email[0]');

    if (!open) {
        return (
            <button
                className="btn btn-social rounded-lg align-items-center w-100 mb-0"
                onClick={toggleDefault}>
                <span className="icon">
                    <i className="yh-new-icon-Email"></i>
                </span>
                <span className="text">Continue with Email</span>
            </button>
        );
    }

    return (
        <div>
            <form onSubmit={onSubmit} className="text-left">
                <Input
                    id="email"
                    inputType="email"
                    placeholder="Type email address..."
                    name="email"
                    value={formData.email}
                    autoComplete="email"
                    handleChange={(val) => onChange(val, 'email')}
                    error={error}
                    required
                    autoFocus
                />

                <div className="form-group d-flex justify-content-center">
                    <button
                        type="submit"
                        className={`btn btn-primary w-100 ${
                            loading ? 'btn-loading' : ''
                        }`}
                        disabled={loading}>
                        {loading ? (
                            <div className="loading-dots"></div>
                        ) : (
                            type == 'chef' ? 'Become a yhangry chef' : 'Continue with email'
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
}
