import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import useValidation from '@/hooks/wizard/useValidation';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import Input from '@/elements/Input';
import NumOfGuests from '@/views/FoodExplorer/UserOptionsContainer/NumOfGuests/NumOfGuests';

export default function GuestsStep() {
    const dispatch = useDispatch();
    const { request, meta } = useSelector((state) => state.requestWizard);
    const { completeStep, stepError } = useValidation();

    const min = request.type == 'Meal prep' ? 1 : 2;

    const handleChange = (v) => {
        dispatch(updateRequest('guests', v));

        if (request.budget > 0) {
            // Update this when we allow user to enter pp budget or switch between types
            dispatch(updateRequest('budget_pp', request.budget / v));
        }

        if (v && v >= min) {
            completeStep();
            return;
        }

        stepError('Please enter the number of guests.');
    };

    const GuestsCounter = () => (
        <Input
            min={min}
            inline={true}
            inputType="guest-counter"
            info={<b>Adults</b>}
            value={request.guests}
            handleChange={(v) => handleChange(v)}
        />
    );

    return (
        <Step size="sm">
            <h4 className="step-title">For how many?</h4>
            <p className="step-info">
                If you have kids under 10, we'd consider two kids to be equal to
                one adult.
            </p>
            <GuestsCounter />
        </Step>
    );
}
