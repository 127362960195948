import {
    FETCH_SET_MENUS,
    FETCH_SET_MENUS_SUCCESS,
    FETCH_SET_MENUS_ERROR,
    CLEAR_SET_MENUS,
    FILTER_SET_MENUS,
    VIEW_SET_MENU,
    STORE_SET_MENUS,
    FETCH_SET_MENUS_AVAILABILITY,
    FETCH_SET_MENUS_AVAILABILITY_SUCCESS
} from '../constants/ActionTypes';
  
const initialState = {
    error: false,
    loading: false,
    hasLoaded: false,
    allSetMenus: [],
    viewableSetMenus: [],
    viewSetMenu: false,
    isAvailable: false,
    totalDistance: 0,
    isValidPostcode: false,
};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_SET_MENUS:
            return {
                ...initialState,
                loading: true,
            };
        case FETCH_SET_MENUS_SUCCESS:
            return {
                ...state,
                allSetMenus: action.payload.data,
                viewableSetMenus: action.payload.data,
                error: false,
                loading: false,
                hasLoaded: true,
            };
        case FETCH_SET_MENUS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                hasLoaded: true,
                allSetMenus: [],
                viewableSetMenus: []
            };
        case VIEW_SET_MENU:
            return {
                ...state,
                viewSetMenu: action.payload,
            };
        case STORE_SET_MENUS:
            return {
                ...state,
                allSetMenus: action.payload,
                viewableSetMenus: action.payload,
            };
        case CLEAR_SET_MENUS:
            return {
                ...initialState,
            };
        case FETCH_SET_MENUS_AVAILABILITY:
            return {
                ...state,
                isAvailable: false,
                isValidPostcode: false,
            }
        case FETCH_SET_MENUS_AVAILABILITY_SUCCESS:
            return {
                ...state,
                isValidPostcode: true,
                isAvailable: action.payload.data.availability,
                totalDistance: (action.payload.data.total_distance) ? action.payload.data.total_distance : 0,
            };
        default:
            return state;
    }
}