import React from 'react';
import Prompt from '@/views/Wizards/RequestWizard/Wrappers/Prompt';
import Representative from '../Partials/Representative';
import { Envelope, ChatCircleDots } from '@phosphor-icons/react';

export default function IntlLocationPrompt() {
    return (
        <Prompt>
            <div>
                <Representative className="mb-2 text-sm" imgClass='w-10 h-10 mr-2' />
                <h5 className="text-base mb-0">
                    We're currently operating mainly in the UK
                </h5>
                <p className="m-0">
                    Get in touch with me directly if you need help finding a
                    chef outside the UK
                </p>
            </div>
            <div className="flex justify-between items-center w-full md:w-auto mt-2 md:!mt-0">
                <a
                    href="mailto:info@yhangry.com"
                    target="_blank"
                    className="btn btn-sm btn-dark btn-outline bg-black text-white mr-1 w-full md:w-auto">
                    <Envelope size={20} className="mr-2" />
                    Email
                </a>
                <a
                    href="https://wa.me/+447418310743"
                    target="_blank"
                    className="btn btn-sm btn-gray btn-outline bg-white w-full md:w-auto">
                    <ChatCircleDots size={20} className="mr-2" />
                    WhatsApp
                </a>
            </div>
        </Prompt>
    );
}
