import { createReducer } from '@reduxjs/toolkit'
  
const initialState = {
    error: false,
    loading: false,
    hasLoaded: false,
    surgePricings: []
};

export const chefSurgePricingReducer = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_CHEF_SURGE_PRICING', (state, action) => {
            state.error = false;
        })
        .addCase('FETCH_CHEF_SURGE_PRICING_ERROR', (state, action) => {
            state.error = true;
        })
        .addCase('FETCH_CHEF_SURGE_PRICING_SUCCESS', (state, action) => {
            state.error = false;
            state.loading = false;
            state.hasLoaded = true;
            state.surgePricings = action.payload.data.surgePricings;
        })
        .addCase('UPDATE_CHEF_SURGE_PRICING', (state, action) => {
            state.error = false;
            state.loading = false;
            state.hasLoaded = true;
            state.surgePricings[action.payload.index][action.payload.key] = action.payload.value;
        })
        .addCase('ADD_CHEF_SURGE_PRICING', (state, action) => {
            state.surgePricings.push(action.payload)
        })
        .addCase('REMOVE_CHEF_SURGE_PRICING', (state, action) => {
            state.surgePricings = state.surgePricings.filter((surgePricing, index) => 
                index !== action.payload.index
            );
        })
})