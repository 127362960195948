import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateRequest } from '@/actions/wizard/RequestWizardActions';
import { useStep } from '@/hooks/wizard/useStep';
import useValidation from '@/hooks/wizard/useValidation';
import Step from '@/views/Wizards/RequestWizard/Wrappers/Step';
import Input from '@/elements/Input';

export default function FoodStep() {
    const dispatch = useDispatch();
    const { request, options } = useSelector((state) => state.requestWizard);
    const { completeStep } = useValidation();
    const { next } = useStep();

    let servingTypes = options.servingType;

    if (request.type == 'Meal prep') {
        servingTypes = options.mealPrepType;
    }

    const mappedOptions = servingTypes.map((option) => {
        return {
            label: (
                <div className="flex flex-col">
                    <div className="embed-responsive embed-responsive-21by9">
                        <img
                            data-src={option.image}
                            alt={option.label}
                            loading="lazy"
                            className="embed-responsive-item object-cover rounded lazyload blur-up"
                        />
                    </div>
                    <span className="mt-1 md:!mt-2">
                        {option.label}
                        {option.price && (
                            <span className="fw-normal ml-1">
                                {option.price}
                            </span>
                        )}
                    </span>
                </div>
            ),
            value: option.label,
        };
    });

    return (
        <Step>
            <h4 className="step-title">Type of food?</h4>
            {/* <p className="step-info">Choose one</p> */}
            <Input
                cols={3}
                inputType="checkbox"
                value={request.servingType}
                handleChange={(v) => {
                    dispatch(updateRequest('servingType', v));
                    completeStep();
                    next(0, false);
                }}
                className="input__checkbox"
                optionClass="[&>span]:w-full"
                options={mappedOptions}
            />
        </Step>
    );
}
