import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import monitorReducersEnhancer from './enhancers/monitorReducers';
import loggerMiddleware from './middleware/logger';
import rootReducer from './reducers';

export default function configureAppStore(preloadedState) {
    let middleware = [];

    if (process.env.NODE_ENV !== 'production') {

        if (import.meta.env.SSR) {
            middleware = [
                loggerMiddleware,
                getDefaultMiddleware()[0]
            ];
        } else {
            middleware = [
                loggerMiddleware,
                ...getDefaultMiddleware({
                    serializableCheck: false,
                }),
            ];
        }
        
    } else {
        middleware = [
            ...getDefaultMiddleware({
                serializableCheck: false,
            }),
        ];
    }

    const store = configureStore({
        reducer: rootReducer,
        middleware: middleware,
        // middleware: [loggerMiddleware],
        preloadedState,
        enhancers: [monitorReducersEnhancer],
    });

    // console.log(typeof loggerMiddleware); // should print "function"
    // console.log(middleware.map((mw) => typeof mw)); // should print an array of "function"
    // console.log(typeof monitorReducersEnhancer); // should print "function"

    // if (process.env.NODE_ENV !== 'production' && module.hot) {
    //     module.hot.accept('./reducers', () =>
    //         store.replaceReducer(rootReducer)
    //     );
    // }

    return store;
}
