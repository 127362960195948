import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker';
// import 'react-day-picker/lib/style.css';
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import moment from 'moment';

class WhenEvent extends Component {
    state = {
        date: new Date(),
        newDate: false,
    };

    tomorrowsDate = () => {
        const date = moment(new Date());
        const tomorrow = date.add(1, 'days');
        return moment(tomorrow).toDate();
    };

    todaysDate = () => {
        const today = moment(new Date());

        return moment(today).toDate();
    };

    cityDateRestrictions = () => {
        if (
            this.props.city &&
            this.props.city?.date_restrictions &&
            this.props.city?.date_restrictions?.length
        ) {
            var restrictions = this.props.city.date_restrictions,
                result = [];
            for (var i = 0; i < restrictions.length; i++) {
                if (restrictions[i]['action'] == 'Exclude') {
                    var from = moment(
                            new Date(restrictions[i]['from'])
                        ).toDate(),
                        to = moment(
                            moment(new Date(restrictions[i]['to'])).add(
                                1,
                                'days'
                            )
                        ).toDate();
                    var disable = {
                        after: from,
                        before: to,
                    };
                    result.push(disable);
                }
            }
            return result[0];
        }
    };

    componentDidMount() {
        const datePickers = document.getElementsByClassName('DayPickerInput');
        Array.from(datePickers).forEach((el) =>
            el.childNodes[0].setAttribute('readOnly', true)
        );
    }

    formatUnavailableDates = () => {
        if (!this.props?.unavailableDates) return;

        let dates = [];
        this.props?.unavailableDates.forEach((date) => {
            dates.push(moment(new Date(date)).toDate());
        });

        return dates;
    };

    formatDiscountedDates = () => {
        let discountedDates = [];

        if (this.props.menuMultipliers) {
            Object.keys(this.props.menuMultipliers).map((key, index) => {
                let value = this.props.menuMultipliers[key];

                if (value < 0) {
                    let day;
                    switch (key) {
                        case 'sunday':
                            day = 0;
                            break;
                        case 'monday':
                            day = 1;
                            break;
                        case 'tuesday':
                            day = 2;
                            break;
                        case 'wednesday':
                            day = 3;
                            break;
                        case 'thursday':
                            day = 4;
                            break;
                        case 'friday':
                            day = 5;
                            break;
                        case 'saturday':
                            day = 5;
                            break;
                    }

                    discountedDates.push(day);
                }
            });
        }

        return discountedDates;
    };

    render() {
        let dates = this.formatUnavailableDates(),
            discountedDates = this.formatDiscountedDates();

        let disabledModifier = [
            {
                before:
                    this.props.allowCurrentDate &&
                    this.props.mealDate !== undefined
                        ? this.todaysDate()
                        : this.tomorrowsDate(),
            },
            this.cityDateRestrictions(),
        ];

        if (dates && dates.length > 0) {
            disabledModifier = disabledModifier.concat(dates);
        }

        let dateValue = '';

        if (
            this.props.mealDate &&
            this.props.mealDate !== false &&
            this.props.mealDate !== undefined
        ) {
            dateValue = new Date(this.props.mealDate).toLocaleDateString(
                'en-GB'
            );
        } else if (this.props.mealDate === undefined) {
            dateValue = this.tomorrowsDate();
        }

        if (this.props.embed) {
            return (
                <DayPicker
                    selectedDays={this.props.mealDate}
                    month={
                        // If it's the last day of the month, show the next month
                        new Date().getMonth() ===
                        this.tomorrowsDate().getMonth()
                            ? new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth()
                              )
                            : new Date(
                                  this.tomorrowsDate().getFullYear(),
                                  this.tomorrowsDate().getMonth()
                              )
                    }
                    onDayClick={this.props.updateMealDate}
                    disabledDays={disabledModifier}
                    numberOfMonths={this.props.numberOfMonths ?? 1}
                />
            );
        }

        return (
            <DayPickerInput
                // component={props => <input className={this.props.className ?? null} id={this.props.id ?? null} {...props} />}
                inputProps={{
                    className: this.props.className ?? null,
                    id: this.props.id ?? null,
                }}
                // showOverlay={true}
                // hideOnDayClick={false}
                keepFocus={false}
                format="DD/MM/yyyy"
                formatDate={formatDate}
                parseDate={parseDate}
                onDayChange={this.props.updateMealDate}
                placeholder={this.props.placeholder ?? 'Select Event Date'}
                value={dateValue}
                selectedDay={
                    this.props.mealDate &&
                    this.props.mealDate !== false &&
                    this.props.mealDate !== undefined
                        ? new Date(this.props.mealDate)
                        : this.tomorrowsDate()
                }
                dayPickerProps={{
                    localeUtils: MomentLocaleUtils,
                    firstDayOfWeek: 1,
                    modifiers: {
                        disabled: disabledModifier,
                        invited: {
                            daysOfWeek: discountedDates,
                        },
                    },
                }}
            />
        );
    }
}

export default WhenEvent;
