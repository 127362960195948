import React, { Component } from 'react';

import Axios from 'axios';
import moment from 'moment';
import Segment from '../../utils/Segment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

class OcassionsForm extends Component {
    state = {
        loading: false,
        error: false,
        is_submitted: false,
        date: undefined,
        type: false,
        name: '',
        email: '',
        saved_email: false,
        is_guest: window?.isGuest,
        types: this.props.occasionTypes ? JSON.parse(this.props.occasionTypes) : false,
        step: 1,
        credit: false,
        score: window?.isGuest ? 3 : false,
        edit: this.props.edit ? this.props.edit : false,
        action: this.props.action ? this.props.action : 'create',
    }

    handleChange = (field, e) => {
        if (field == 'type') {
            this.setState({
                type: e,
                name: e == 'custom' ? '' : e,
                step: 2,
            })
            return;
        }
        if (!e.target && field != 'type') {
            this.setState({
                date: field
            })
            return;
        }
        this.setState({
            [field]: e.target.value
        })
    }

    handleSubmit = () => {
        let data = {
            'name': this.state.name,
            'date': this.state.date,
        }
        if (this.state.is_guest && this.state.action == 'create') {
            data['email'] = this.state.saved_email ? this.state.saved_email : this.state.email;
        }

        return this.state.action == 'update' ? this.update(data) : this.create(data);
    }

    create(data) {
        return Axios.post('/occasions', data).then(response => {
            if (response.data.success) {
                this.setState({
                    'is_submitted': true,
                    'credit': response.data.credit.credit,
                    'score': response.data.score,
                    'email': response.data.occasion.email,
                    'saved_email': response.data.occasion.email,
                }, function () {
                    $('.toast.toast-occasions-success').toast('show')
                })
                this.reset();
                if (data.hasOwnProperty('email')) {
                    Segment.identify({
                        email: data.email
                    })
                }
                Segment.track('reminder.create', data)
                this.props.on_success();
            } else if (response.data.errors) {
                _.map(response.data.errors, (error, i) => {
                    this.setState({
                        'error': error,
                        'is_submitted': false,
                    }, function () {
                        $('.toast.toast-occasions-error').toast('show')
                    })
                });
            }
        });
    }

    update(data) {
        return Axios.put('/occasions/' + this.props.edit?.id, data).then(response => {
            if (response.data.success) {
                $('.toast.toast-occasions-success').toast('show')
                this.setState({
                    'is_submitted': true,
                })
                if (this.props.is_modal) this.props.closeModal();
                this.reset();
                this.props.on_success();
            } else if (response.data.errors) {
                _.map(response.data.errors, (error, i) => {
                    this.setState({
                        'error': error,
                        'is_submitted': false,
                    }, function () {
                        $('.toast.toast-occasions-error').toast('show')
                    })
                });
            }
        });
    }

    reset() {
        this.setState({
            'name': '',
            'date': undefined,
            'step': 1,
            'edit': false,
            'action': 'create',
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.action != prevProps.action) {
            this.reset();
            this.setState({
                'action': this.props.action,
            })
        }
        if (this.props.edit != prevProps.edit) {
            if (this.props.edit) {
                this.setState({
                    'edit': this.props.edit,
                    'step': 2,
                    'type': 'custom',
                    'name': this.props.edit.name,
                    'date': moment(this.props.edit.date).set("hour", 12).toDate(),
                })
            } else {
                this.reset();
            }
        }
    }

    componentDidMount() { 
        if (!window?.isGuest) {
            return Axios.get('/occasions/score').then(response => {  // Causing View [app] not found error.
                this.setState({
                    'score': response.data,
                })
            });
        }
    }

    renderTitle() {
        if (this.state.edit) {
            return 'Edit Reminder';
        } else if (this.state.is_submitted) {
            return 'Add another reminder';
        } else {
            return 'Add a reminder';
        }
    }

    render() {
        return (
            <>
                <div className="occasions-form text-left">
                    <h6 className="lead text-center">{this.renderTitle()}</h6>

                    {this.state.step == 1 &&
                        <>
                        {this.state.credit &&
                            <div className="alert alert-success">
                                {this.state.is_guest &&
                                    <span>You will get £{this.state.credit} off your first booking when you sign up.</span>
                                }
                                {!this.state.is_guest &&
                                    <span>Your account has been credited with £{this.state.credit} to use on your next booking.</span>
                                }
                            </div>
                        }
                        {this.state.score &&
                            <div className="alert bg-secondary">
                                <h2>Save {this.state.score}{this.state.score < 3 ? ' more': ''} {this.state.score == '1' ? 'date' : 'dates'} and we'll add £25 credit to your account to use immediately!</h2>
                            </div>
                        }
                        <div className="form-group">
                            <div className="occasions-grid">
                                {this.state.types && this.state.types.map((type) => {
                                    return (
                                        <a className="grid-item p-1" key={type.name} onClick={() => this.handleChange('type', type.name)}>
                                            <div className="d-flex flex-col align-items-center w-100">
                                                <img src={type.icon} width="50" className="mb-2" all='Type icon'/>
                                                <p>{type.label ? type.label : type.name}</p>
                                            </div>
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                        </>
                    }

                    {this.state.step == 2 &&
                        <>
                        {this.state.type == 'custom' &&
                            <div className="form-group">
                                <input type="text" className="form-control border-secondary" placeholder="Occasion Name" value={this.state.name} onChange={(e) => this.handleChange('name', e)} required />
                            </div>
                        }
                        <div className="form-group">
                            <DayPickerInput
                                required
                                format='DD MMMM'
                                formatDate={formatDate}
                                parseDate={parseDate}
                                onDayChange={this.handleChange}
                                placeholder='Select Date'
                                value={this.state.date}
                                selectedDay={this.state.date}
                                dayPickerProps={{
                                    localeUtils: MomentLocaleUtils,
                                    firstDayOfWeek: 1,
                                    modifiers: {
                                        disabled: [{
                                            before: moment(new Date()).toDate()
                                        }],
                                    }
                                }}
                            />
                        </div>
                        {this.state.is_guest && !this.state.saved_email &&
                            <div className="form-group">
                                <input type="email" className="form-control border-secondary" placeholder="Email" value={this.state.email} onChange={(e) => this.handleChange('email', e)} required />
                            </div>
                        }
                        <div className="form-group d-flex flex-space-between">
                            {this.state.action == 'create' &&
                                <button className="btn btn-back pull-left" onClick={() => this.setState({step: 1})}>Back</button>
                            }
                            <button className="btn btn-secondary pull-right" onClick={() => this.handleSubmit()}>Save</button>
                        </div>
                        </>
                    }

                    <div className="toast bg-danger text-white border-0 toast-bottom-center text-center toast-occasions-error" role="alert" data-delay="2000" aria-live="assertive" aria-atomic="true">
                        <div className="toast-body">
                            {this.state.error}
                        </div>
                    </div>

                    <div className="toast bg-success text-white border-0 toast-bottom-center text-center toast-occasions-success" role="alert" data-delay="2000" aria-live="assertive" aria-atomic="true">
                        <div className="toast-body">
                            {this.state.action == 'update' &&
                                'Reminder Updated Successfully!'
                            }
                            {this.state.action != 'update' &&
                                'Reminder Added Successfully!'
                            }
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

export default OcassionsForm
