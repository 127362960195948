import React from 'react';

export default function Prompt({
    className = '',
    containerClass = '',
    children,
}) {
    return (
        <div className={`-bg-blue-100 rounded-xl p-3 ${containerClass}`}>
            <div
                className={`flex flex-col md:flex-row md:items-center justify-start md:justify-between ${className}`}>
                {children}
            </div>
        </div>
    );
}
