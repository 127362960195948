import {
    FETCH_ANALYTICS,
    FETCH_ANALYTICS_SUCCESS,
    FETCH_ANALYTICS_ERROR,
} from '../constants/ActionTypes';
  
const initialState = {
    error: false,
    loading: true,
    topMenus: false,
};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_ANALYTICS:
            return {
                ...state,
                loading: true,
            };
        case FETCH_ANALYTICS_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                topMenus: action.payload.data.menus,
            };
        case FETCH_ANALYTICS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
}
  