import React, { useEffect } from 'react';
import OccasionsForm from './OccasionsForm';

export default function OccasionsModal(props) {

    const openModel = () => {
        $('#occasionsModal').modal('show')
    }

    const closeModal = () => {
        $("#occasionsModal").modal("toggle");
    }

    useEffect(() => {
        if (props.openModal === true) openModel()
    }, [props.openModal])

    return (
        <div className="modal fade occasions-modal" data-backdrop="static" id="occasionsModal" tabIndex="-1" role="dialog" aria-labelledby="Occasions" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button className="modal-close-button"
                        data-dismiss="occasionsModal"
                        onClick={() => closeModal()}>×</button>
                    <div className="modal-body text-center">
                        <OccasionsForm
                            {...props}
                            is_modal={true}
                            closeModal={() => closeModal()}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}
