import React from 'react';
import TrustpilotStars from '@/elements/logos/TrustpilotStars';
import TrustpilotWidget from '@/components/Wordpress/TrustPilot/TrustpilotWidget';

export default function TrustpilotComboBox({
    className = 'bg-white rounded-lg p-2',
}) {
    return (
        <div className={`${className}`}>
            <div className="trustpilot flex items-center justify-center mb-1">
                <span className="fw-bold mr-2 text-black">Excellent</span>
                <TrustpilotStars height="24" width="100" />
            </div>
            <TrustpilotWidget height="24" theme="light" />
        </div>
    );
}
