import Input from '@/elements/Input';
import React, { Component } from 'react';

export default class ForgottenPassword extends Component {
    _renderForm() {
        const error = _.get(this.props.errors, 'email[0]');

        return (
            <form onSubmit={this.props.onSubmit} className="text-left">
                <Input
                    id="email"
                    type="email"
                    placeholder="Enter your email..."
                    name="email"
                    value={this.props.formData.email}
                    autoComplete="email"
                    handleChange={(val) => this.props.onChange(val, 'email')}
                    error={error}
                    required
                    autoFocus
                />

                <div className="form-group d-flex justify-content-between">
                    <button
                        className="btn btn-dark btn-outline"
                        onClick={this.props.reset}
                        type="button">
                        Back to login
                    </button>

                    <button
                        type="submit"
                        className={`btn btn-primary ${
                            this.props.loading ? 'btn-loading' : ''
                        }`}
                        disabled={this.props.loading}>
                        {this.props.loading ? (
                            <div className="loading-dots"></div>
                        ) : (
                            'Send password reset link'
                        )}
                    </button>
                </div>
            </form>
        );
    }

    render() {
        return (
            <div>
                {!this.props.success && this._renderForm()}
                {this.props.success && (
                    <>
                        <div className="alert alert-success" role="alert">
                            {this.props.message}
                        </div>
                        <div className="form-group">
                            <button
                                className="btn btn-dark btn-outline"
                                onClick={this.props.reset}
                                type="button">
                                Back to login
                            </button>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
