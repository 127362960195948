import React from 'react';

export default function What3words(props) {
    const handleInputChange = (ev) => {
        let value = ev.target.value;

        value = value
            .replace(' ', '.')
            .replace(/[^a-zA-Z.]/g, '')
            .split('.');

        return props.handleChange(value);
    };

    const handleKeyDown = (e) => {
        // if we have 3 words, prevent the user from typing more
        if (
            props.value.length >= 3 &&
            (e.key == ' ' ||
                e.code == 'Space' ||
                e.keyCode == 32 ||
                e.key == '.' ||
                e.keyCode == 190)
        ) {
            e.preventDefault();
        }
    };

    return (
        <div
            className="input__w3w text-danger tracking-wide fw-bold"
            data-prefix="///">
            <input
                {...props}
                value={props.value?.join('.')}
                onChange={(ev) => handleInputChange(ev)}
                onKeyDown={handleKeyDown}
                className={`fw-bold ${props.className}`}
            />
        </div>
    );
}
