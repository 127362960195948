import {
    FETCH_AVAILABILITY,
    FETCH_AVAILABILITY_SUCCESS,
    FETCH_AVAILABILITY_ERROR,
    UPDATE_AVILABILITY,
    SYNC_AVILABILITY,
} from '../constants/ActionTypes';
  
const initialState = {
    error: false,
    loading: false,
    hasLoaded: false,
    availability: false,
    restricted_days: [],
    availability_window: '',
    calendarUrl: '',
    calendarName: '',
    schedule: [],
    selectedDays: [],
    syncError: false,
    message: false,
};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case FETCH_AVAILABILITY:
            return {
                ...state,
                loading: true,
                syncMessage: false,
                syncError: false,
            };
        case FETCH_AVAILABILITY_SUCCESS:
            return {
                ...state,
                error: false,
                loading: false,
                hasLoaded: true,
                restricted_days: (action.payload.data.availability?.restricted_days) ? action.payload.data.availability?.restricted_days : [],
                availability: action.payload.data.availability,
                availability_window: action.payload.data.chef.availability_window,
                calendarUrl: action.payload.data.chef.calendar_url,
                calendarName: action.payload.data.chef.calendar_name,
                schedule: action.payload.data?.schedule,
                selectedDays: action.payload.data?.selectedDays,
                syncError: false,
                syncMessage: false
            };
        case FETCH_AVAILABILITY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                hasLoaded: true,
                syncError: action.payload?.data?.syncError,
            };
        case FETCH_AVAILABILITY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                hasLoaded: true,
            };
        case UPDATE_AVILABILITY:
            return {
                ...state,
                [action.payload.attribute]: action.payload.value,
            };
        case SYNC_AVILABILITY:
            console.log(action.payload.data)
            return {
                ...state,
                error: false,
                loading: false,
                hasLoaded: true,
                syncError: false,
                syncMessage: action.payload.data.message
            };
        default:
            return state;
    }
}
  