import { createAction, createReducer } from '@reduxjs/toolkit';

import {
    CHEF_REVIEW_SUBMIT,
    CHEF_REVIEW_SET_DEFAULT,
    CHEF_REVIEW_SUBMIT_ERROR,
    CHEF_REVIEW_SUBMIT_SUCCESS,
    CHEF_REVIEW_UPDATE_ATTRIBUTE,
} from '../constants/ActionTypes';
import { chefs } from '../utils/chefs';

const initialState = {
    review: {
        feedback: '',
        experince: '',
        yhangry_feedback: '',
        cleanliness_rating: 0,
        communication_rating: 0,
        overall_experince_rating: 0,
        storytelling_rating: 0,
        images: {
            files: [],
            uploadErrors: [],
            imageLoading: false,
        },
    },
    errors: [],
    loading: false,
    success: false,
    chef_refferal_code: '',
    customer_referral_code: '',
    chef_slug: '',
    chef_name: '',
    order_booking_reference: '',
};

export const chefReview = createReducer(initialState, (builder) => {
    builder
        .addCase('CHEF_REVIEW_SUBMIT', (state, action) => {
            state.loading = true;
            state.errors = initialState.errors;
            state.success = initialState.success;
        })
        .addCase('CHEF_REVIEW_SET_DEFAULT', (state, action) => {
            state[action.payload.attribute] = action.payload.value;
        })
        .addCase('CHEF_REVIEW_SUBMIT_ERROR', (state, action) => {
            state.loading = false;
            state.success = initialState.success;
            state.errors = action.payload?.errors
                ? Object.entries(action.payload?.errors)
                : [
                      'There was an error while updating a dish, please try again',
                  ];
        })
        .addCase('CHEF_REVIEW_SUBMIT_SUCCESS', (state, action) => {
            state.success = true;
            state.loading = false;
            state.errors = initialState.errors;
        })
        .addCase('CHEF_REVIEW_UPDATE_ATTRIBUTE', (state, action) => {
            state.review[action.payload.attribute] = action.payload.value;
        });
});
