import Input from '@/elements/Input';
import React, { Component } from 'react';

export default class CustomerLogin extends Component {
    _togglePassword() {
        if ($('#password').attr('type') == 'password') {
            $('#password').attr('type', 'text');
        } else {
            $('#password').attr('type', 'password');
        }
    }

    render() {
        const error = _.get(this.props.errors, 'email[0]');
        return (
            <div>
                <form onSubmit={this.props.onSubmit} className="text-left">
                    <Input
                        id="password"
                        type="password"
                        placeholder="Enter your password..."
                        name="password"
                        value={this.props.formData.password}
                        autoComplete="password"
                        handleChange={(val) =>
                            this.props.onChange(val, 'password')
                        }
                        error={error}
                        required
                        autoFocus
                    />

                    <div className="form-group mb-0 mt-0">
                        <button
                            type="button"
                            className="btn btn-tertiary"
                            onClick={this.props.resetPassword}>
                            I need a password reset
                        </button>
                    </div>

                    <div className="form-group d-flex justify-content-between">
                        <button
                            className="btn btn-dark btn-outline"
                            onClick={this.props.reset}
                            type="button">
                            Back to login
                        </button>

                        <button
                            type="submit"
                            className={`btn btn-primary ${
                                this.props.loading ? 'btn-loading' : ''
                            }`}
                            disabled={this.props.loading}>
                            {this.props.loading ? (
                                <div className="loading-dots"></div>
                            ) : (
                                'Login'
                            )}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}
