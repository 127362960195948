import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateOptions } from '@/actions/wizard/RequestWizardActions';
import { useStep } from './useStep';
import { usePrevious } from '@/utils/hooks';

const useValidation = () => {
    const dispatch = useDispatch();
    const { meta } = useSelector((state) => state.requestWizard);
    const { activeStep, steps: allSteps } = useStep();
    const prevStep = usePrevious(activeStep);
    const [steps, setSteps] = useState(allSteps);

    useEffect(() => {
        dispatch(updateOptions('steps', steps));
    }, [steps]);

    useEffect(() => {
        if (
            prevStep &&
            prevStep.skippable &&
            !prevStep.isComplete &&
            !prevStep.error &&
            prevStep.name !== activeStep.name
        ) {
            completeStep(true, prevStep.name);
        }
    }, [activeStep]);

    const completeStep = (completed = true, step = false) => {
        const stepToComplete = step ? step : meta.stepName;

        setSteps((currentSteps) =>
            currentSteps.map((step) =>
                step.name !== stepToComplete
                    ? step
                    : {
                          ...step,
                          isComplete: completed ? true : false,
                          error: false,
                      }
            )
        );
    };

    const stepError = (error = true, step = false) => {
        const stepWithError = step ? step : meta.stepName;

        setSteps((currentSteps) =>
            currentSteps.map((step) =>
                step.name !== stepWithError
                    ? step
                    : {
                          ...step,
                          isComplete: false,
                          error,
                      }
            )
        );
    };

    return { steps, completeStep, stepError };
};

export default useValidation;
