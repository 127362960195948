class GoogleAnalytics {
    quoteCheckout = (quoteId, price) => {
        console.log('GoogleAnalytics', { quoteId, price });
        let gajson = {
            event: 'checkout',
            ecommerce: {
                checkout: {
                    actionField: {
                        step: 1,
                        option: 'Quote checkout',
                    },
                    products: [],
                },
            },
        };

        gajson['ecommerce']['checkout']['products'].push({
            id: String(quoteId),
            name: 'Quote',
            price: String(price),
        });

        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push(gajson);
        this.quoteCheckoutStep(1, 'Quote checkout');
    };

    quoteCheckoutStep = (step, name) => {
        var gajson = {
            event: 'checkoutOption' + step,
            ecommerce: {
                checkout_option: {
                    actionField: { step: step, option: name },
                },
            },
        };
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push(gajson);
    };

    quoteBooked = (
        source = 'bespoke-quote',
        quoteId,
        price,
        orderId,
        total,
        voucher,
        email = '',
        guests,
        additionalInfoText = false
    ) => {
        // Google Analytics
        let gajson = {
            event: 'purchase',
            source: source.trim(),
            ecommerce: {
                purchase: {
                    actionField: {},
                    products: [],
                },
            },
            order_value: String(total), // Fetch total value dynamically
            additional_info_length: additionalInfoText
                ? String(additionalInfoText.length)
                : '0',
            order_id: String(orderId), // Fetch Order id dynamically
            enhanced_conversion_data: {
                email: email, // Fetch user email dynamically
            },
        };

        gajson['ecommerce']['purchase']['products'].push({
            id: String(quoteId),
            name: 'Quote',
            price: String(price),
        });

        gajson['ecommerce']['purchase']['actionField']['id'] = String(orderId);
        gajson['ecommerce']['purchase']['actionField']['revenue'] =
            String(total);

        if (guests && guests > 0) {
            gajson['order_value_pp'] = String(price / guests);
            gajson['ecommerce']['purchase']['actionField']['revenue_pp'] =
                String(price / guests);
        }

        gajson['ecommerce']['purchase']['actionField'][
            'additional_info_length'
        ] = additionalInfoText ? String(additionalInfoText.length) : '0';

        if (voucher !== false) {
            gajson['ecommerce']['purchase']['actionField']['coupon'] = voucher;
        }

        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push(gajson);
    };

    secureChef = (
        source = 'secure-chef',
        orderId,
        total,
        voucher,
        email = ''
    ) => {
        // Google Analytics
        let gajson = {
            event: 'purchase',
            source: source.trim(),
            ecommerce: {
                purchase: {
                    actionField: {},
                    products: [],
                },
            },
            order_value: String(total), // Fetch total value dynamically
            order_id: String(orderId), // Fetch Order id dynamically
            enhanced_conversion_data: {
                email: email, // Fetch user email dynamically
            },
        };

        gajson['ecommerce']['purchase']['products'].push({
            id: String(orderId),
            name: 'Secure Chef',
            price: String(total),
        });

        gajson['ecommerce']['purchase']['actionField']['id'] = String(orderId);
        gajson['ecommerce']['purchase']['actionField']['revenue'] =
            String(total);

        if (voucher !== false) {
            gajson['ecommerce']['purchase']['actionField']['coupon'] = voucher;
        }

        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push(gajson);
    };

    signup = async (data = {}) => {
        let gajson = {
            ...data,
            event: 'sign_up',
        };

        await dataLayer.push(gajson);
    };
}

export default new GoogleAnalytics();
