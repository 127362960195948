import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';
import {
    updateRequest,
    postcodeAutoComplete,
    validateLocation,
    getChefsCount,
    updateMeta,
    updateOptions,
} from '@/actions/wizard/RequestWizardActions';
import useValidation from '@/hooks/wizard/useValidation';
import { usePrevious } from '@/utils/hooks';

export default function useLocation() {
    const dispatch = useDispatch();
    const { request, options, meta } = useSelector(
        (state) => state.requestWizard
    );
    const { completeStep, stepError } = useValidation();
    const prevPostcode = usePrevious(request.postcode);
    const [filteredCities, setFilteredCities] = useState([]);
    const [page, setPage] = useState(1);

    const loadMore = () => setPage(page + 1);
    const resetPage = () => setPage(1);

    const priorityCities = [
        73, // Brighton
        7, // Wales
        119, // Sussex
        66, // Cotswold
        83, // Bath
        129, // Edinburgh
        3, // Manchester
        132, // Cornwall
    ];

    const autoComplete = useCallback(
        debounce((postcode) => dispatch(postcodeAutoComplete(postcode)), 300),
        []
    );
    const validate = useCallback(
        debounce((postcode) => dispatch(validateLocation(postcode)), 300),
        []
    );

    const delayError = useCallback(
        debounce((error) => stepError(error), 800),
        []
    );

    const toComparable = (v) => v?.toLowerCase()?.replace(/\s/g, '') ?? v;

    const getMatchedLocation = () =>
        options.cities?.find(
            (city) => toComparable(city.name) == toComparable(request.postcode)
        )?.name ??
        options.postcodes?.find(
            (postcode) =>
                toComparable(postcode) == toComparable(request.postcode)
        );

    useEffect(() => {
        const cities = options.cities
            ?.filter((city) => {
                if (request.postcode == '' || !request.postcode) return false;

                return toComparable(city.name)?.includes(
                    toComparable(request.postcode)
                );
            })
            ?.map((city) => ({ label: city.name, value: city.id }))
            ?.sort(
                (a, b) =>
                    priorityCities.indexOf(b.value) -
                    priorityCities.indexOf(a.value)
            )
            ?.slice(0, 10 * page); // Limit to 10 results

        setFilteredCities(cities);
    }, [request.postcode, options.cities, page]);

    useEffect(() => {
        const matchedLocation = getMatchedLocation();

        if (matchedLocation) {
            dispatch(updateRequest('postcode', matchedLocation));
            completeStep();
            dispatch(getChefsCount());
            // Always show the prompt even when the count is unchanged
            if (options.chefsCount > 0) {
                dispatch(updateMeta({ activePrompt: 'chefs-count' }));
            }
            dispatch(
                updateOptions({
                    isInternational: false,
                    isValidLocation: true,
                })
            );
            return;
        }

        // Location isn't recognized as a city or postcode
        if (
            request.postcode?.length > 2 &&
            filteredCities.length + options.postcodes.length === 0
        ) {
            if (request.postcode !== prevPostcode) {
                validate(request.postcode);
            }
            return;
        }

        // Location isn't valid but there are suggestions available (still typing)
        validate.cancel();
        // Throw no error, but can't proceed to next step yet
        stepError(false);
        // Reset prompt
        dispatch(updateMeta({ activePrompt: 'none' }));
        // Reset validation
        dispatch(
            updateOptions({
                isInternational: false,
                isValidLocation: false,
            })
        );
    }, [options.postcodes, request.postcode, filteredCities]);

    useEffect(() => {
        if (
            request.postcode?.length < 3 ||
            options.isInternational ||
            filteredCities.length + options.postcodes.length > 0
        )
            return;

        delayError.cancel();
        if (!options.isValidLocation) {
            delayError('Please enter a valid city or postcode.');
            return;
        }

        completeStep();
        dispatch(getChefsCount());
        // Always show the prompt even when the count is unchanged
        if (options.chefsCount > 0) {
            dispatch(updateMeta({ activePrompt: 'chefs-count' }));
        }
    }, [options.isValidLocation, request.postcode]);

    // Show international location prompt
    useEffect(() => {
        if (!options.isInternational) return;
        delayError.cancel();
        dispatch(updateMeta({ activePrompt: 'intl-location' }));
    }, [options.isInternational]);

    return {
        filteredCities,
        autoComplete,
        loadMore,
        resetPage,
        toComparable,
        getMatchedLocation,
    };
}
