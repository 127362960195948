import React from 'react';
import Pill from './Pill';

const Pills = ({
    items = [],
    className = false,
    containerClass = '',
    active = [],
    activeClass = '',
    dissmissable = false,
    ...props
}) => {
    if (items.length < 1) return;

    return (
        <div className={`yh-pill-container ${containerClass}`}>
            {items.map((item, idx) => {
                return (
                    <Pill
                        key={idx}
                        className={className}
                        dissmissable={dissmissable}
                        active={active.includes(item)}
                        activeClass={activeClass}
                        onClick={props.onClick}>
                        {item}
                    </Pill>
                );
            })}
        </div>
    );
};

export default Pills;
