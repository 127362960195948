import { createAction, createReducer } from '@reduxjs/toolkit';
import { initial } from 'lodash';

import {
    FETCH_QUOTES,
    FETCH_QUOTES_SUCCESS,
    FETCH_QUOTES_ERROR,
    FETCH_QUOTES_PAGINATE_SUCCESS,
    FILTER_QUOTES,
    FILTER_QUOTES_CLEAR,
    ORDER_QUOTES,
    SELECT_QUOTE,
    DEEPLINK_FILTERS,
    UPDATE_QUOTE_COORDS,
} from '../constants/ActionTypes';

const emptyFilters = {
    order: [],
    price: [],
    guests: [],
    type: [],
    cuisine: [],
    rating: [],
    when: [],
    spotlight: [],
    search: '',
    radius: '',
    dateFrom: false,
    dateTo: false,
    sort: 'most_recent',
};

const initialState = {
    loading: false,
    hasLoaded: false,
    errors: [],
    filters: emptyFilters,
    selectedFilters: emptyFilters,
    selectedOrder: false,
    quotes: {
        list: [],
        pagination: {
            links: false,
            meta: false,
        },
    },
    selectedQuote: false,
    initialCoords: false,
};

const individualFilters = [
    'type',
    'date',
    'when',
    'search',
    'radius',
    'sort',
    'dateFrom',
    'dateTo',
];

export const quotes = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_QUOTES', (state) => {
            state.loading = true;
            state.hasLoaded = false;
        })
        .addCase('FETCH_QUOTES_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = initialState.errors;
            state.quotes.list = action.payload?.quotes?.data ?? [];
            state.quotes.pagination = {
                ...state.pagination,
                links: action.payload.quotes?.links,
                meta: action.payload.quotes?.meta,
            };
            state.filters = action.payload?.filters
                ? action.payload.filters
                : state.filters;

            // set price filter length based on chef metrics
            if (state.selectedFilters.price.length == 0) {
                state.selectedFilters.price = [
                    action.payload?.filters.price.initial ?? 0,
                    action.payload?.filters.price.max,
                ];
            }
            // set radius if not already based on travel distance
            if (state.selectedFilters.radius == '') {
                state.selectedFilters.radius =
                    action.payload?.filters.radius ?? 10;
            }
            // state.selectedFilters.price = action.payload?.filters.price;

            state.initialCoords = action.payload?.coords
                ? action.payload.coords
                : state.coords;
        })
        .addCase('FETCH_QUOTES_PAGINATE_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = initialState.errors;
            state.quotes.list = [
                ...state.quotes.list,
                ...action.payload?.quotes?.data,
            ];
            state.quotes.pagination = {
                ...state.pagination,
                links: action.payload.quotes?.links,
                meta: action.payload.quotes?.meta,
            };
        })
        .addCase('FETCH_QUOTES_ERROR', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.errors = action.payload?.errors;
        })
        .addCase('ORDER_QUOTES', (state, action) => {
            state.selectedOrder = action.payload;
        })
        .addCase('FILTER_QUOTES', (state, action) => {
            if (individualFilters.includes(action.payload.filter)) {
                state.selectedFilters[action.payload.filter] =
                    action.payload.value;
                return;
            }

            const index = state.selectedFilters[action.payload.filter].indexOf(
                action.payload.value
            );

            if (index > -1) {
                state.selectedFilters = {
                    ...state.selectedFilters,
                    [action.payload.filter]:
                        action.payload.filter != 'price' &&
                        action.payload.filter != 'guests' &&
                        action.payload.filter != 'rating'
                            ? [
                                  ...state.selectedFilters[
                                      action.payload.filter
                                  ].filter(
                                      (item) => item != action.payload.value
                                  ),
                              ]
                            : action.payload.value,
                };
                return;
            }

            state.selectedFilters = {
                ...state.selectedFilters,
                [action.payload.filter]:
                    action.payload.filter != 'price' &&
                    action.payload.filter != 'guests' &&
                    action.payload.filter != 'rating'
                        ? [
                              ...state.selectedFilters[action.payload.filter],
                              action.payload.value,
                          ]
                        : action.payload.value,
            };
        })
        .addCase('FILTER_QUOTES_CLEAR', (state, action) => {
            state.selectedFilters = emptyFilters;
        })

        .addCase('SELECT_QUOTE', (state, action) => {
            state.selectedQuote = action.payload;
        })
        .addCase('UPDATE_QUOTE_COORDS', (state, action) => {
            state.initialCoords = action.payload;
        })
        .addCase('DEEPLINK_FILTERS', (state, action) => {
            const filters = action.payload;

            // fallback to active quotes if none set
            state.selectedFilters.type = filters.get('type') ?? 'all';
            if (filters.get('price'))
                state.selectedFilters.price = filters.get('price').split('-');
            if (filters.get('guests'))
                state.selectedFilters.guests = filters.get('guests').split('-');
            if (filters.get('when'))
                state.selectedFilters.when = filters.get('when');
            if (filters.get('spotlight'))
                state.selectedFilters.spotlight = filters
                    .get('spotlight')
                    .split(',');
            if (filters.get('cuisine'))
                state.selectedFilters.cuisine = filters
                    .get('cuisine')
                    .split(',')
                    .map((cuisineId) => parseInt(cuisineId));
            if (filters.get('search'))
                state.selectedFilters.search = filters.get('search');

            if (filters.get('radius'))
                state.selectedFilters.radius = filters.get('radius');

            if (filters.get('sort'))
                state.selectedFilters.sort = filters.get('sort');
        });
});
