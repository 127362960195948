import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import Cookies from 'js-cookie';
import {
    getInitData,
    updateMeta,
    updateStatus,
    updateRequest,
    submitRequest,
    updateOptions,
} from '@/actions/wizard/RequestWizardActions';
import { useStep } from './useStep';

const useWizard = () => {
    const dispatch = useDispatch();
    const { status, meta, options } = useSelector(
        (state) => state.requestWizard
    );
    const { activeStep, availableSteps, prev } = useStep();
    const pendingRequest = is_node ? false : sessionStorage.getItem('yh-pending-request');

    /**
     * MARK: Initialization
     */

    const init = ({
        modalId = false,
        embed = false,
        autoShow = false,
        isMobile = false,
    }) => {
        // Prevent re-initialization
        if (status.init) return;

        dispatch(updateStatus('init', true));
        dispatch(getInitData());

        // If there's a pending request (after social auth), submit it
        if (pendingRequest) {
            submitPendingRequest();
            return;
        }

        let newMeta = {
            embed,
            modalId,
            modal: !embed,
            isMobile,
        };

        if (embed) {
            newMeta = {
                ...newMeta,
                startedAt: new Date(),
                source: 'wordpress',
            };
        }

        dispatch(updateMeta(newMeta));

        // When in modal
        if (!embed) {
            // Auto show modal
            modalAutoShow(modalId, autoShow);
        }
    };

    /**
     * MARK: Methods
     */
    const showModal = (modalId = meta.modalId) =>
        $('#' + modalId).modal('show');

    const hideModal = (modalId = meta.modalId) =>
        $('#' + modalId).modal('hide');

    const modalAutoShow = (modalId, autoShow = false) => {
        const cookieStatus = Cookies.get('yh-bespoke-quote');

        // Don't show if cookie is set or autoShow is disabled
        if (!autoShow || cookieStatus) return;

        setTimeout(() => {
            // Don't show when login modal is visible
            if ($('#customerLoginModal').hasClass('show')) return;

            showModal(modalId);

            // Update meta
            dispatch(
                updateMeta({
                    autoShow: true,
                    autoShowDelay: autoShow,
                    source: 'popup',
                    ctaCopy: false,
                })
            );

            // Set cookie to prevent showing again
            Cookies.set('yh-bespoke-quote', true);
        }, autoShow);
    };

    const exit = (source, copy) => {
        dispatch(updateMeta({ exitSource: source, exitCopy: copy }));
        // tracking will be handled by event listener
        hideModal();
    };

    const delaySubmit = useCallback(
        debounce(() => dispatch(submitRequest()), 300),
        []
    );

    const submit = () => {
        if (!canSubmit()) return;

        dispatch(updateStatus('submitting', true));
        delaySubmit();
    };

    const submitPendingRequest = () => {
        const {
            meta: pendingMeta,
            request: pendingRequestData,
            steps: pendingSteps,
        } = JSON.parse(pendingRequest);

        dispatch(updateStatus('pending', true));
        dispatch(updateMeta(pendingMeta));
        dispatch(updateRequest(pendingRequestData));
        dispatch(
            // Retain steps progress
            updateOptions(
                'steps',
                options.steps.map((step) => {
                    const pendingStep = pendingSteps.find(
                        (ps) => ps.name === step.name
                    );
                    return {
                        ...step,
                        isComplete: pendingStep.isComplete,
                        error: pendingStep.error,
                    };
                })
            )
        );

        // Wait for rendering
        setTimeout(() => {
            if (!pendingMeta.embed) {
                showModal(pendingMeta.modalId);
            }
            dispatch(submitRequest());
            prev(); // Go back to the step before auth
        }, 1000);
    };

    /**
     * MARK: Validation
     */

    const canContinue = () => {
        if (!activeStep || activeStep?.error || status.submitting) return false;

        if (activeStep.skippable) return true;
        if (activeStep.isComplete) return true;

        return false;
    };

    const canSubmit = () => {
        if (status.submitting || status.submitted) return false;

        const inCompleteSteps =
            availableSteps.filter((step) => !step.isComplete || step.error) ||
            [];

        if (!inCompleteSteps.length) return true;

        return false;
    };

    return { init, exit, submit, showModal, hideModal, canContinue, canSubmit };
};

export default useWizard;
