class Feature {
    isInitialised = () => {
        if (window?.globalFeatures) return true;

        return false;
    };

    check = (feature) => {
        if (this.isInitialised() && window?.globalFeatures[feature])
            return true;

        return false;
    };

    get = (feature) => {
        if (this.isInitialised())
            return window?.globalFeatures[feature] ?? false;

        return false;
    };
}

export default Feature;
