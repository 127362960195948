import React from 'react';
import useCountryCodes from '@/hooks/auth/useCountryCodes';
import PhoneNumber from '@/elements/inputs/PhoneNumber';

export default function PhoneAuth({
    onSubmit,
    onChange,
    formData,
    errors,
    loading,
    open,
    toggleDefault,
}) {
    if (!open) {
        return (
            <button
                className="btn btn-social rounded-lg align-items-center w-100 mb-0"
                onClick={toggleDefault}>
                <span className="icon">
                    <i className="yh-new-icon-Phone"></i>
                </span>
                <span className="text">Continue with Phone</span>
            </button>
        );
    }

    return (
        <>
            <form onSubmit={onSubmit} className="text-left">
                <PhoneNumber
                    errors={errors}
                    onChange={onChange}
                    formData={formData}
                />

                <div className="form-group d-flex justify-content-center">
                    <button
                        type="submit"
                        className={`btn btn-primary w-100 ${
                            loading ? 'btn-loading' : ''
                        }`}
                        disabled={loading}>
                        {loading ? (
                            <div className="loading-dots"></div>
                        ) : (
                            'Continue'
                        )}
                    </button>
                </div>
            </form>
            <p className="mb-0">
                <small>Confirm your mobile number via SMS</small>
            </p>
        </>
    );
}
