import { createAction, createReducer } from '@reduxjs/toolkit'

import {
    DELETE_DISH,
    FETCH_DISHES,
    CLEAR_DISHES,
    CLEAR_ERRORS,
    FILTER_DISHES,
    UPDATING_DISH,
    UPDATE_DISH_ERROR,
    UPDATE_DISH_VALUE,
    DELETE_DISH_ERROR,
    FETCH_DISH_FILTERS,
    FETCH_DISHES_ERROR,
    CREATING_DISH_ERROR,
    UPDATE_DISH_SUCCESS,
    DELETE_DISH_SUCCESS,
    FETCH_DISHES_SUCCESS,
    CREATING_DISH_SUCCESS,
    FETCH_DISH_FILTERS_SUCCESS,
} from '../constants/ActionTypes';

const emptyFilters = {
    order: [],
    course: []
};

const initialState = {
    error: false,
    allDishes: [],
    loading: false,
    chefDishes: [],
    fetchAll: false,
    addDishError: [],
    hasLoaded: false,
    dishLoading: false,
    viewableDishes: [],
    deleteDishError: '',
    updateDishValue: '',
    updatingDishError: '',
    filtersLoading: false,
    addDishSuccess: false,
    filters: emptyFilters,
    filtersHasLoaded: false,
    updateDishSuccess: false,
    deleteDishSuccess: false,
    selectedCourse: 'Canape',
    selectedOrder: 'alphabetical'
};

export const dishes = createReducer(initialState, (builder) => {
    builder
        .addCase('FETCH_DISHES', (state) => {
            state.loading = true;
            state.hasLoaded = false;
            state.addDishError = [];
            state.updatingDishError = '';
            state.addDishSuccess = false;
            state.updateDishSuccess = false;
            state.deleteDishSuccess = false;
        })
        .addCase('FETCH_DISH_FILTERS', (state) => {
            state.addDishError = [];
            state.filtersLoading = true;
            state.updatingDishError = '';
            state.addDishSuccess = false;
            state.filtersHasLoaded = false;
            state.updateDishSuccess = false;
            state.deleteDishSuccess = false;
        })
        .addCase('FETCH_DISHES_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.dishLoading = false;
            state.filtersLoading = false;
            state.filtersHasLoaded = true;
            state.deleteDishSuccess = false;
            state.fetchAll = (action.payload?.fetchAll) ? action.payload?.fetchAll : initialState.fetchAll;
            state.allDishes = (action.payload?.dishes?.data) ? action.payload?.dishes?.data : initialState.allDishes;
            state.viewableDishes = (action.payload?.dishes?.data) ? action.payload?.dishes?.data : initialState.viewableDishes;            
            state.chefDishes = (action.payload?.chefDishes) ? action.payload?.chefDishes : initialState.chefDishes;
        })
        .addCase('FETCH_DISH_FILTERS_SUCCESS', (state, action) => {
            state.loading = false;
            state.hasLoaded = true;
            state.dishLoading = false;
            state.filtersLoading = false;
            state.filtersHasLoaded = true;
            state.deleteDishSuccess = false;
            state.deleteDishError = initialState.deleteDishError;
            state.filters = (action.payload?.filters) ? action.payload?.filters : initialState.filters;
            state.chefDishes = (action.payload?.chefDishes) ? action.payload?.chefDishes : initialState.chefDishes;
        })
        .addCase('FETCH_DISHES_ERROR', (state, action) => {
            state.error = true;
            state.loading = false;
            state.hasLoaded = true;
            state.deleteDishSuccess = false;
        })
        .addCase('FILTER_DISHES', (state, action) => {
            state.deleteDishSuccess = false;
            state.viewableDishes = action.payload?.data;

            if (action.payload.filter == 'course') {
                state.selectedCourse = action.payload.value;
                return;
            };

            if (action.payload.filter == 'order') {
                state.selectedOrder = action.payload.value;
                return;
            };
        })
        .addCase('CLEAR_DISHES', (state, action) => {
            state.error = initialState.error;
            state.filters = initialState.filters;
            state.loading = initialState.loading;
            state.fetchAll = initialState.fetchAll;
            state.allDishes = initialState.allDishes;
            state.hasLoaded = initialState.hasLoaded;
            state.chefDishes = initialState.chefDishes;
            state.selectedOrder = initialState.selectedOrder;
            state.viewableDishes = initialState.viewableDishes;
            state.selectedCourse = initialState.selectedCourse;
        })
        .addCase('CLEAR_ERRORS', (state, action) => {
            state.addDishError = [];
            state.dishLoading = false;
            state.addDishSuccess = false;
        })
        .addCase('CREATING_DISH', (state, action) => {
            state.addDishError = [];
            state.dishLoading = true;
            state.updatingDishError = '';
            state.addDishSuccess = false;
            state.updateDishSuccess = false;
            state.deleteDishSuccess = false;
        })
        .addCase('CREATING_DISH_SUCCESS', (state, action) => {
            state.dishLoading = false;
            state.addDishSuccess = true;
            state.deleteDishSuccess = false;
            state.chefDishes = (action.payload?.chefDishes) ? action.payload?.chefDishes : initialState.chefDishes;
        })
        .addCase('CREATING_DISH_ERROR', (state, action) => {
            state.dishLoading = false;
            state.deleteDishSuccess = false;
            state.addDishError = (action.payload?.errors) ? Object.entries(action.payload?.errors) : [];
        })
        .addCase('DELETE_DISH', (state, action) => {
            state.dishLoading = true;
            state.deleteDishSuccess = false;
            state.deleteDishError = initialState.deleteDishError;
        })
        .addCase('DELETE_DISH_SUCCESS', (state, action) => {
            state.dishLoading = false;
            state.deleteDishSuccess = true;
            state.deleteDishError = initialState.deleteDishError;
            state.chefDishes = (action.payload?.chefDishes) ? action.payload?.chefDishes : initialState.chefDishes;
        })
        .addCase('DELETE_DISH_ERROR', (state, action) => {
            state.dishLoading = false;
            state.deleteDishSuccess = false;
            state.deleteDishError = (action.payload?.error) ? action.payload?.error : 'There was an error while deleting a dish, please try again.';
        })
        .addCase('UPDATE_DISH_VALUE', (state, action) => {
            state.updateDishValue = (action.payload?.value) ? action.payload?.value : '';
        })
        .addCase('UPDATING_DISH', (state, action) => {
            state.dishLoading = true;
            state.updatingDishError = '';
            state.addDishSuccess = false;
            state.deleteDishSuccess = false;
            state.updateDishSuccess = false;
        })
        .addCase('UPDATE_DISH_ERROR', (state, action) => {
            state.dishLoading = false;
            state.updateDishSuccess = false;
            state.updatingDishError = (action.payload?.errors?.name) ? action.payload?.errors?.name : 'There was an error while updating a dish, please try again.';
        })
        .addCase('UPDATE_DISH_SUCCESS', (state, action) => {
            state.dishLoading = false;
            state.updateDishSuccess = true;
            state.updatingDishError = initialState.deleteDishError;
            state.chefDishes = (action.payload?.chefDishes) ? action.payload?.chefDishes : initialState.chefDishes;
        })
});